export default {
    cmoClient: {
        dropdownItems: [],
        dataToSend: [],
        clientData: {},
        dataFromBE: [],
        clientDealId: '',
        dealType: '',
        claimMethod: '',
        territories: [],
        territoryPeriodStart: '',
        territoryPeriodEnd: '',
        dealTerritories: [],
        dealData: {},
        countriesWithSelections: [],
        rightsHolderId: '',
        errorBuffer: '',
        updateFlag: false,
        tableIsLoading: false,
        errorRows: [],
    },
    cmoConnection: {
        dataFromBE: {
            content: [],
        },
        tableIsLoading: false,
        downloadAllCmoAssets: { requestFlag: false, localSelectAll: false },
        isFirstToSubmit: true,
        registrationHistory: [],
        alertMsgs: [],
    },
    rhData: {
        detailsData: {},
        mainDetailsInptValues: {},
        selectedCmoId: '',
        modalValidations: {},
        cmoIssuedIds: [],
        hasIssuedId: false,
        selectedRowDataItem: {},
        deals: { content: [] },
        aliasesError: '',
        selectedTab: '',
    },
    clientPage: {
        data: {},
        territories: [],
        claimMethod: '',
        territoryPeriodStart: '',
        territoryPeriodEnd: '',
        activeTab: 'details',
        selectedIndex: -1,
        mainDetailsInptValues: {},
        isEditBtnVisible: true,
        deals: { content: [] },
        isDeletable: false,
        tableIsLoading: false,
        isInEditMode: false,
        isInSaveNewMode: false,
        areFieldsDisabled: true,
    },
    clientRegistration: {
        isModalVisible: false,
    },
    makeClaim: {
        isModalVisible: false,
        csvContent: [],
    },
    repertoires: {
        dataFromBE: {},
        tableIsLoading: false,
        uploadErrors: [],
        reportId: null,
        errorDialogIsOpen: false,
        sortValue: {
            value: '',
            direction: '',
        },
    },
    cmoPage: {
        dataFromBE: [],
        filteredData: [],
        tableIsLoading: false,
        cmoInfo: {},
        cmoName: '',
        claimMethod: 'agent',
        assetsToClaim: [],
        searchTerm: '',
        registrationHistory: [],
        downloadAllCmoAssets: { requestFlag: false, localSelectAll: false },
        cmoValidationDialogContent: '',
        selectedCmoId: '',
        selectedRowDataItem: {},
        alertMsgs: [],
    },
    performerCmoPage: {
        dataFromBE: {
            content: [],
        },
        cmoInfo: {},
        downloadAllCmoAssets: { requestFlag: false, localSelectAll: false },
        tableIsLoading: true,
        registrationHistory: [],
        searchTerm: '',
        cmoValidationDialogContent: '',
        selectedRowDataItem: {},
        selectedCmoId: '',
        alertMsgs: [],
    },
    eventsPage: {
        dataFromBE: {
            content: [],
        },
        tableIsLoading: false,
    },
    queryParams: {
        page: 0,
        size: 10,
        sort: {},
    },
    cmos: {
        dataFromBE: [],
        tableIsLoading: false,
    },
    periods: {
        dataFromBE: [],
    },
    clients: {
        dataFromBE: { content: [] },
        tableIsLoading: false,
        isNewClientModalOpen: false,
        suggestedClients: [],
    },
    loadScreen: {
        isLoading: false,
    },
    infoMessage: {
        isVisible: false,
        message: '',
        transitionDirection: 'right',
        duration: 3,
        severity: 'info',
    },
    territories: {
        groupedTerritories: [],
        checkedTerritories: [],
        editableDealInfo: {},
    },
    reports: {
        dataFromBE: [],
    },
    performers: {
        dataFromBE: {},
        tableIsLoading: false,
        byClietnId: {
            firstName: '',
            lastName: '',
            clientDeals: [{ client_deal_id: '' }],
        },
        clientDeals: { content: [] },
        selectedCmoId: '',
        mainDetailsInptValues: {},
        modalValidations: {},
        selectedRowDataItem: {},
        isEditBtnVisible: true,
        isInEditMode: false,
        isInSaveNewMode: false,
        isNewClientModalOpen: false,
        selectedIndex: -1,
        suggestedClients: [],
        allPerformers: [],
        isDeletable: false,
        cmoIssuedIds: [],
        hasIssuedId: false,
        spotifyIdErrorText: '',
        aliasesPerformerErrorText: '',
        registrationHistory: [],
        selectedTab: '',
    },
    performersRepertoire: {
        dataFromBE: {},
        tableIsLoading: false,
        sortValue: {
            value: '',
            direction: '',
        },
    },
    filters: {},
    loginErrorMsg: '',
    loginSuccessMsg: '',
    updatedPassword: false,
    submitTermsAndConditions: false,
    statements: {
        dataFromBE: {
            content: [],
        },
        tableIsLoading: false,
        downloadItems: { requestFlag: false, localSelectAll: false },
    },
    users: {
        dataFromBE: {
            content: [],
        },
        tableIsLoading: false,
        selectedRowDataItem: {},
        downloadItems: { requestFlag: false, localSelectAll: false },
        sortValue: {
            value: '',
        },
    },
    assetMetadata: {
        dataObjFromBE: {},
        // rh ownership
        artistsDataFromBE: {
            content: [],
        },
        rhOwnershipDataFromBE: {
            content: [],
        },
        validations: {},
        validatedWithWarning: false,
        rhOwnershipValidations: {},
        allRhNamesFromBE: [],
        rhOwnershipDettailsObjFromBE: {},
        rhOwnershipSaveResult: { isSuccessful: false, hasWarning: false, isDeleted: false },
        //contributions
        contributorsDataFromBE: {
            content: [],
        },
        allRolesAndInstrumentsFromBE: [],
        contributionValidations: {},
        contributionDetailsObjFromBE: {},
        contributionSaveResult: { isSuccessful: false, hasWarning: false, isDeleted: false },
        allPerformerNamesFromBE: [],
        contributorDetailsObjFromBE: {},
        //products
        productsDataFromBE: {
            content: [],
        },
    },
    notifications: {
        hasUnreadNotifications: false,
    },
    allClientsList: [],
    contactForEdit: {},
    isFullScreenLoaderActive: 0,
    cmoComments: '',
};
