import React from 'react';

import PerformersSvg from '@images/svgComponents/PerformersSvg';
import { Business } from '@mui/icons-material';
import { useHistory, useLocation } from 'react-router-dom';

import CommonDropDownButton from './CommonDropDownButton';

export default function ClientsHeaderBtnDropDownButton() {
    const history = useHistory();
    const location = useLocation();

    const navigateToPerformers = () => {
        history.push('/performers');
    };

    const navigateToRightsHolders = () => {
        history.push('/clients');
    };

    const menuItemsList = [
        {
            title: 'Performers',
            icon: <PerformersSvg sx={{ marginRight: 1, height: 20, width: 20 }} />,
            onClickClb: navigateToPerformers,
        },
        {
            title: 'Rights Holders',
            icon: <Business sx={{ marginRight: 1, height: 20, width: 20 }} />,
            onClickClb: navigateToRightsHolders,
        },
    ];

    return (
        <CommonDropDownButton
            btnTitle="Clients"
            menuItemsList={menuItemsList}
            customStyles={{
                height: '100%',
                backgroundColor: 'transparent',
                borderRadius: 0,
                borderBottom:
                    ['/performers', '/clients'].includes(location.pathname) && '2px solid #fff',
            }}
        />
    );
}
