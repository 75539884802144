import React, { useState } from 'react';

import {
    raiseBulkAlertForPerformer,
    raiseBulkAlertForRightsHolder,
} from '@api/assetsRegistrationBulkActions.jsx';
import CommonDropDownButtonWithFixedLabel from '@common/CommonDropDownButtonWithFixedLabel.jsx';
import { isSymbolsCountValid } from '@common/modals/common';
import CrossIconSvg from '@images/svgComponents/CrossIconSvg';
import SaveIconSvg from '@images/svgComponents/SaveIconSvg';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormHelperText,
    Tooltip,
    Typography,
    styled,
} from '@mui/material';
import TextFieldWrapper from '@MuiWrappers/TextFieldWrapper.jsx';
import { getFiltersToSend, manualAlertsItemsList } from '@pages/AssetsCmoPage/common/common';
import { useSelector } from 'react-redux';

const Title = styled(DialogTitle)({
    padding: '19px 16px',
});

const Content = styled(DialogContent)({
    padding: '10px 16px',
    minWidth: '568px',
});

const TextFieldStyled = styled(TextFieldWrapper)({
    width: '488px',
    padding: 0,
});

const TextCounter = styled(FormHelperText)({
    display: 'flex',
    justifyContent: 'flex-end',
    width: '488px',
});

const AddAlertButton = styled(Button)({
    fontSize: '13px',
    height: '30px',
    padding: '4px 10px',
    display: 'flex',
    alignItems: 'center',
});

const AddIconStyled = styled(AddIcon)({
    margin: '0 10px 0 0',
    width: '18px',
    height: '18px',
});

const OperationsContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    minWidth: theme.spacing(9),
    justifyContent: 'space-between',
}));

const OperationIcon = styled('div')({
    marginLeft: '8px',
    display: 'flex',
    alignItems: 'center',
    '&:hover': { cursor: 'pointer' },
});

const AlertRow = styled('div')({
    marginBottom: '26px',
});

export default function AssetsCmoManualAlertsBulkActionsModal({
    isOpen,
    setOpen,
    getAssetData,
    queryParams,
    isPerformer,
}) {
    const [alertMsgInput, setAlertMsgInput] = useState('');
    const [alertMsgSymCount, setAlertMsgSymCount] = useState(0);
    const [manualAlerts, setManualAlerts] = useState([]);
    const [isTypesDropdwnVisible, setIsTypesDropdwnVisible] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const filtersModel = useSelector((state) => state.fugaReducers.filters);

    const handleTooltipOpen = () => setTooltipOpen(true);
    const handleTooltipClose = () => setTooltipOpen(false);

    const handleClose = async (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
            if (manualAlerts.length > 0) {
                await saveRequest();
                getAssetData();
            }
        }
    };

    const handleInputChange = (e) => {
        const value = e.currentTarget.value;
        if (isSymbolsCountValid(value, handleTooltipOpen, setTooltipOpen, setAlertMsgSymCount)) {
            setAlertMsgInput(value);
        }
    };

    const saveRequest = async () => {
        const commonQueryParams = {
            cmoId: queryParams.cmoId,
            claimMethod: queryParams.claimMethod,
        };
        const commonBodyParams = {
            ...getFiltersToSend(filtersModel),
            includeAssetIds: queryParams.assetsList.map(({ assetId, clientId }) => ({
                assetId,
                clientId,
            })),
            excludeAssetIds: queryParams.excludeList.map(({ assetId, clientId }) => ({
                assetId,
                clientId,
            })),
            assetAlerts: manualAlerts,
            searchString: filtersModel.searchString,
        };
        const actionFunction = isPerformer
            ? raiseBulkAlertForPerformer
            : raiseBulkAlertForRightsHolder;
        await actionFunction(commonQueryParams, commonBodyParams);
    };

    const handleAlertInputSave = () => {
        manualAlerts.push({
            alertType: selectedValue.beValue,
            alertMessage: alertMsgInput,
        });
        setAlertMsgInput('');
        setIsTypesDropdwnVisible(false);
        setSelectedValue(null);
        setAlertMsgSymCount(0);
    };

    const handleAddNewAlertBtn = () => setIsTypesDropdwnVisible(true);
    const handleAlertInputCancel = () => {
        setSelectedValue(null);
        setAlertMsgInput('');
        setAlertMsgSymCount(0);
    };

    const handleAlertDelete = (index) => {
        const updatedManualAlerts = manualAlerts.filter((_, alertIndex) => alertIndex !== index);
        setManualAlerts(updatedManualAlerts);
    };

    const getMetaDataByAlertCategory = (category) =>
        manualAlertsItemsList.find((el) => el.beValue === category) || {};

    return (
        <Dialog onClose={handleClose} open={isOpen} maxWidth="md" disableEscapeKeyDown>
            <Title>
                <Typography variant="h6">
                    Add alerts to {queryParams.assetsList.length} assets
                </Typography>
            </Title>
            <Content>
                {manualAlerts.map((item, index) => {
                    const alertMetaData = getMetaDataByAlertCategory(item.alertType);
                    return (
                        item.alertType !== 'RESOLVED' && (
                            <AlertRow key={index + item.alertType}>
                                <div>
                                    <div>
                                        <Typography
                                            variant="subtitle2"
                                            style={{ color: alertMetaData.color }}
                                        >
                                            {alertMetaData.title}
                                        </Typography>
                                    </div>
                                </div>
                                <OperationsContainer>
                                    <Tooltip
                                        title="Text should be less than 5000 characters"
                                        open={tooltipOpen}
                                    >
                                        <TextFieldStyled
                                            name="alertMsg"
                                            type="text"
                                            multiline
                                            maxRows={10}
                                            value={item.alertMessage || ''}
                                            onBlur={handleTooltipClose}
                                        />
                                    </Tooltip>
                                    <TextCounter>{item.alertMessage.length || 0}/5000</TextCounter>
                                    <Tooltip title="Delete alert">
                                        <OperationIcon onClick={() => handleAlertDelete(index)}>
                                            <DeleteOutlineOutlinedIcon />
                                        </OperationIcon>
                                    </Tooltip>
                                </OperationsContainer>
                            </AlertRow>
                        )
                    );
                })}
                {isTypesDropdwnVisible && (
                    <CommonDropDownButtonWithFixedLabel
                        btnTitle="Alert Type"
                        manualAlertsItemsList={manualAlertsItemsList.slice(
                            0,
                            manualAlertsItemsList.length - 1,
                        )}
                        selectedValue={selectedValue}
                        setSelectedValue={setSelectedValue}
                    />
                )}
                {!isTypesDropdwnVisible && (
                    <AddAlertButton variant="outlined" onClick={handleAddNewAlertBtn}>
                        <AddIconStyled />
                        Add new alert
                    </AddAlertButton>
                )}
                {isTypesDropdwnVisible && (
                    <OperationsContainer>
                        <Tooltip
                            title="Text should be less than 5000 characters"
                            open={tooltipOpen}
                        >
                            <TextFieldWrapper
                                onChange={handleInputChange}
                                name="alertMsg"
                                type="text"
                                multiline
                                maxRows={10}
                                value={alertMsgInput || ''}
                                disabled={!selectedValue}
                                onBlur={handleTooltipClose}
                            />
                        </Tooltip>
                        <TextCounter>{alertMsgSymCount}/5000</TextCounter>
                        <Tooltip title="Save this alert">
                            <OperationIcon onClick={handleAlertInputSave}>
                                <SaveIconSvg />
                            </OperationIcon>
                        </Tooltip>
                        <Tooltip title="Clear text">
                            <OperationIcon onClick={handleAlertInputCancel}>
                                <CrossIconSvg />
                            </OperationIcon>
                        </Tooltip>
                    </OperationsContainer>
                )}
            </Content>
            <DialogActions>
                <Tooltip title="Select 'Save' or 'Clear text' before closing">
                    <Button onClick={handleClose} variant="outlined" color="primary">
                        close
                    </Button>
                </Tooltip>
            </DialogActions>
        </Dialog>
    );
}
