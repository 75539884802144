import React from 'react';

import { renderExportChip } from '@common/customChips';
import ArrowCircleDownOutlinedIcon from '@mui/icons-material/ArrowCircleDownOutlined';
import {CircularProgress, IconButton, Typography} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {NOTIFICATION_STATUS} from "@utils/notificationUtils.js";

const ExportNotificationRow = ({ notification }) => {
    return (
        <>
            <Grid size={1}>
                <ArrowCircleDownOutlinedIcon />
            </Grid>
            <Grid size={11} mb={1}>
                <Typography variant="body2" color="textSecondary">
                    {new Date(notification.date).toLocaleString()}
                </Typography>
                <Typography variant="body2">
                    <span style={{ fontWeight: 'bold' }}>{notification.user}</span> exported{' '}
                    <span style={{ fontWeight: 'bold' }}>
                        {notification.processedAssetsNumber || 0}
                    </span>{' '}
                    tracks to{' '}
                    <span style={{ fontWeight: 'bold' }}>
                        {notification.exportType.split('_').join(' ')}
                    </span>{' '}
                    for <span style={{ fontWeight: 'bold' }}>{notification.cmoName}</span>
                </Typography>
            </Grid>
            <Grid size={1}></Grid>
            <Grid
                size={8}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {renderExportChip(notification.status)}
            </Grid>
            {notification.status === NOTIFICATION_STATUS.IN_PROGRESS && (
                <Grid
                    size={3}
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <IconButton>
                        <CircularProgress
                            size="20px"
                            sx={{
                                color: '#666',
                            }}
                        />
                    </IconButton>
                </Grid>
            )}
        </>
    );
};

export default ExportNotificationRow;
