import React, { useEffect, useState } from 'react';

import {
    getAllRhNamesForMetadata,
    getRhOwnershipByRhOwnId,
    validateRhOwnershipByRhAndAssetId,
} from '@actions/apiActions';
import { changeStateByNestedKey } from '@actions/fugaActions';
import { formatTerritoryCodes } from '@actions/territories';
import { RegionItem } from '@common/AssetMetaDataEdit/RegionItem';
import CommonLookupPopupWithSuggestionList from '@common/CommonLookupPopupWithSuggestionList';
import { yupResolver } from '@hookform/resolvers/yup';
import { ArrowDropDown } from '@mui/icons-material';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    IconButton,
    InputAdornment,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/material';
import { Alert } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { makeStyles } from '@mui/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextFieldWrapper from '@MuiWrappers/TextFieldWrapper';
import { format } from 'date-fns';
import { useForm, Controller } from 'react-hook-form';
import { FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import * as Yup from 'yup';

const useStyles = makeStyles((theme) => ({
    errorBanner: {
        backgroundColor: '#FFEBEE',
        marginBottom: '32px',
        '& .MuiAlert-message': {
            textAlign: 'left',
        },
    },
    submodalSection: {
        marginTop: '32px',
    },
    section: {
        marginBottom: theme.spacing(3),
    },
    iconBtn: {
        padding: '0',
        margin: '0',
        '&:hover': {
            background: 'none',
        },
    },
    rhNameField: {
        width: '100%',
    },
}));

const validationSchema = Yup.object().shape({
    rhName: Yup.string().required('Rights holder name is required'),
    percent: Yup.number()
        .typeError('Percentage must be a number')
        .required('Percentage is required')
        .min(0, 'Percentage cannot be less than 0')
        .max(100, 'Percentage cannot be more than 100'),
    startDate: Yup.date().required('Start date is required').typeError('Start date is required'),
    endDate: Yup.date()
        .required('End date is required')
        .min(Yup.ref('startDate'), 'End date cannot be before start date')
        .typeError('End date is required'),
    category: Yup.string().required('Category is required'),
    territories: Yup.array().of(Yup.string()).min(1, 'At least one territory must be selected'),
});

const defaultValues = {
    rhName: '',
    percent: '',
    startDate: null,
    endDate: null,
    category: 'original owner',
    territories: [],
};

const NewRhOwnershipModal = ({ showModal, setShowModal, isEditing, setIsEditing, customData }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const params = useParams();

    const [rhLookupAnchorEl, setRhLookupAnchorEl] = useState(null);
    const [isRhLookupOpen, setIsRhLookupOpen] = useState(false);
    const [rhCreationError, setRhCreationError] = useState('');

    const territoriesModel = useSelector((state) => state.fugaReducers.territories);
    const assetMetadataModel = useSelector((state) => state.fugaReducers.assetMetadata);
    const {
        rhOwnershipValidations: rhOwnershipValidationsModel,
        allRhNamesFromBE,
        rhOwnershipDettailsObjFromBE,
    } = assetMetadataModel;
    const { groupedTerritories } = territoriesModel;

    const mappedRhNamesList = allRhNamesFromBE.map((el) => ({
        nameToBeSent: el.fullname,
        displayName: `${el.fullname} (${el.isClient ? 'Client' : 'Non-client'})`,
        subName: `${el.isClient ? 'Client' : 'Non-client'}`,
    }));

    const methods = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        control,
        setValue,
        reset,
        watch,
        formState: { errors, isValid },
    } = methods;

    const selectedTerritories = watch('territories') || [];

    useEffect(() => {
        if (isEditing) {
            dispatch(getRhOwnershipByRhOwnId(customData.selectedRowDataItem.rhOwnId));
        }
    }, [isEditing, dispatch, customData.selectedRowDataItem.rhOwnId]);

    useEffect(() => {
        if (rhOwnershipDettailsObjFromBE?.territoryPeriod?.territoryDTO?.territoryCodes) {
            dispatch(
                formatTerritoryCodes(
                    rhOwnershipDettailsObjFromBE.territoryPeriod.territoryDTO.territoryCodes,
                ),
            );
        }
    }, [rhOwnershipDettailsObjFromBE, dispatch]);

    useEffect(() => {
        if (isEditing && rhOwnershipDettailsObjFromBE) {
            setValue('rhName', rhOwnershipDettailsObjFromBE.rhName || '');
            setValue('percent', rhOwnershipDettailsObjFromBE.percent || '');
            setValue(
                'startDate',
                new Date(rhOwnershipDettailsObjFromBE.territoryPeriod?.startDate) || null,
            );
            setValue(
                'endDate',
                new Date(rhOwnershipDettailsObjFromBE.territoryPeriod?.endDate) || null,
            );
            setValue('category', rhOwnershipDettailsObjFromBE.category || 'original owner');

            if (rhOwnershipDettailsObjFromBE?.territoryPeriod?.territoryDTO?.territoryCodes) {
                const selectedTerritories =
                    rhOwnershipDettailsObjFromBE.territoryPeriod.territoryDTO.territoryCodes;
                setValue('territories', selectedTerritories);
            }
        }
    }, [isEditing, rhOwnershipDettailsObjFromBE, setValue]);

    useEffect(() => {
        dispatch(changeStateByNestedKey('assetMetadata', 'rhOwnershipValidations', {}));
    }, [dispatch]);

    const handleClose = () => {
        if (isEditing) setIsEditing(false);
        setShowModal(false);
        reset();
    };

    const closeAndReloadTable = () => {
        handleClose();
        if (customData.onTableReload) {
            dispatch(customData.onTableReload.reloadCb(customData.onTableReload.params));
        }
    };

    const onCreationFailCb = (errMsg) => {
        setRhCreationError(errMsg);
    };

    const onSubmit = (data) => {
        const formattedData = {
            ...data,
            startDate: format(data.startDate, 'yyyy-MM-dd'),
            endDate: format(data.endDate, 'yyyy-MM-dd'),
            territories: data.territories.join(','),
        };

        if (isEditing) {
            dispatch(
                validateRhOwnershipByRhAndAssetId(
                    customData.selectedRowDataItem.rhOwnId,
                    customData.selectedRowDataItem.assetId,
                    formattedData,
                    closeAndReloadTable,
                    onCreationFailCb,
                    true,
                ),
            );
        } else {
            dispatch(
                validateRhOwnershipByRhAndAssetId(
                    params.id,
                    customData.selectedRowDataItem.assetId,
                    formattedData,
                    closeAndReloadTable,
                    onCreationFailCb,
                ),
            );
        }
    };

    const allTerritories = groupedTerritories.flatMap((gt) =>
        gt.territories.map((territory) => territory.id),
    );

    const allTerritoriesSelected = allTerritories.every((id) => selectedTerritories.includes(id));

    const someTerritoriesSelected =
        allTerritories.some((id) => selectedTerritories.includes(id)) && !allTerritoriesSelected;

    const toggleWorld = () => {
        if (allTerritoriesSelected) {
            // Deselect all territories
            setValue('territories', []);
        } else {
            // Select all territories
            setValue('territories', allTerritories);
        }
    };

    const handleRhLookupOpen = ({ currentTarget }) => {
        setRhLookupAnchorEl(currentTarget);
        setIsRhLookupOpen(true);
        dispatch(getAllRhNamesForMetadata());
    };

    const onRhNameLookupConfirm = (selectedValue) => {
        setValue('rhName', selectedValue.nameToBeSent);
    };

    const isAlertVisible = () => {
        const validationErrors =
            'valid' in rhOwnershipValidationsModel && !rhOwnershipValidationsModel.valid;
        return validationErrors || rhCreationError;
    };

    const fieldsMapping = {
        rhName: 'Rights holder name',
        ownType: 'Category (contract)',
        percent: 'Percent',
        endDate: 'End date',
        startDate: 'Start date',
        territories: 'Territories',
        category: 'Category',
    };

    return (
        <div>
            <Dialog onClose={handleClose} open={showModal} fullWidth maxWidth="sm">
                <DialogTitle onClose={handleClose}>
                    <Typography variant="subtitle1">Rights holder details</Typography>
                </DialogTitle>
                <DialogContent>
                    {(Object.keys(errors).length > 0 || isAlertVisible()) && (
                        <Alert className={classes.errorBanner} variant="outlined" severity="error">
                            {Object.entries(errors).map(([field, error]) => (
                                <div key={field}>
                                    {`${fieldsMapping[field] || field}: "${error.message}"`}
                                    <br />
                                </div>
                            ))}
                            {Object.entries(rhOwnershipValidationsModel.errors || {}).map(
                                ([errKey, errMsg]) => (
                                    <div key={errKey}>
                                        {`${fieldsMapping[errKey] || errKey}: "${errMsg}"`}
                                        <br />
                                    </div>
                                ),
                            )}
                            <div>{rhCreationError}</div>
                        </Alert>
                    )}
                    <Grid spacing={2} className={classes.section} container>
                        <Grid
                            size={{
                                xs: 12,
                                sm: 10,
                            }}
                        >
                            <Controller
                                name="rhName"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldWrapper
                                        {...field}
                                        label="Rights holder name"
                                        required
                                        error={!!errors.rhName}
                                        fullWidth
                                        className={classes.rhNameField}
                                        helperText={errors.rhName?.message}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        className={classes.iconBtn}
                                                        disableRipple
                                                        onClick={(e) => handleRhLookupOpen(e)}
                                                        size="large"
                                                    >
                                                        <ArrowDropDown
                                                            style={{ marginRight: '0' }}
                                                        />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid
                            className={classes.percentageField}
                            size={{
                                xs: 12,
                                sm: 2,
                            }}
                        >
                            <Controller
                                name="percent"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldWrapper
                                        {...field}
                                        label="%"
                                        required
                                        error={!!errors.percent}
                                        helperText={errors.percent?.message}
                                        onChange={(e) => {
                                            const value = e.target.value.replace('%', '');
                                            field.onChange(value);
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">%</InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid spacing={2} className={classes.section} container>
                        <Grid
                            size={{
                                xs: 12,
                                sm: 4,
                            }}
                        >
                            <Controller
                                name="startDate"
                                control={control}
                                render={({ field }) => (
                                    <DatePicker
                                        {...field}
                                        label="Start date"
                                        format="dd/MM/yyyy"
                                        value={field.value || null}
                                        onChange={(date) => {
                                            field.onChange(date);
                                        }}
                                        renderInput={(params) => (
                                            <TextFieldWrapper
                                                {...params}
                                                required
                                                error={!!errors.startDate}
                                                helperText={errors.startDate?.message}
                                            />
                                        )}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid
                            size={{
                                xs: 12,
                                sm: 4,
                            }}
                        >
                            <Controller
                                name="endDate"
                                control={control}
                                render={() => (
                                    <Controller
                                        name="endDate"
                                        control={control}
                                        render={({ field }) => (
                                            <DatePicker
                                                {...field}
                                                label="End date"
                                                format="dd/MM/yyyy"
                                                value={field.value || null}
                                                onChange={(date) => field.onChange(date)}
                                                renderInput={(params) => (
                                                    <TextFieldWrapper
                                                        {...params}
                                                        required
                                                        error={!!errors.endDate}
                                                        helperText={errors.endDate?.message}
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid spacing={2} className={classes.section} container>
                        <Grid size={12}>
                            <Typography variant="caption">Category (contract)</Typography>
                            <Controller
                                name="category"
                                control={control}
                                defaultValue="original owner"
                                render={({ field }) => (
                                    <RadioGroup {...field} row style={{ marginTop: '4px' }}>
                                        <FormControlLabel
                                            value="original owner"
                                            control={<Radio size="small" color="primary" />}
                                            label={
                                                <Typography variant="body2">
                                                    Original owner
                                                </Typography>
                                            }
                                        />
                                        <FormControlLabel
                                            value="successor in title"
                                            control={<Radio size="small" color="primary" />}
                                            label={
                                                <Typography variant="body2">
                                                    Successor in title
                                                </Typography>
                                            }
                                        />
                                        <FormControlLabel
                                            value="exclusive licensee"
                                            control={<Radio size="small" color="primary" />}
                                            label={
                                                <Typography variant="body2">
                                                    Exclusive Licensee
                                                </Typography>
                                            }
                                        />
                                    </RadioGroup>
                                )}
                            />
                        </Grid>
                    </Grid>
                    <div className={classes.submodalSection}>
                        <Typography variant="subtitle1" style={{ marginBottom: '27.17px' }}>
                            Territories
                        </Typography>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={allTerritoriesSelected}
                                    indeterminate={someTerritoriesSelected}
                                    onChange={toggleWorld}
                                    name="World"
                                    color="primary"
                                />
                            }
                            label="World"
                        />
                        <FormProvider {...methods}>
                            {groupedTerritories.map((territoryGroup) => (
                                <RegionItem
                                    key={territoryGroup.region.id}
                                    territorySelection={territoryGroup.territories}
                                    region={territoryGroup.region}
                                />
                            ))}
                        </FormProvider>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant="light" onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button
                        disabled={!isValid}
                        variant="contained"
                        onClick={handleSubmit(onSubmit)}
                        color="primary"
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            <CommonLookupPopupWithSuggestionList
                isOpen={isRhLookupOpen}
                anchorEl={rhLookupAnchorEl}
                closeCb={setIsRhLookupOpen}
                setAnchorCb={setRhLookupAnchorEl}
                itemsList={mappedRhNamesList}
                onConfirmCb={onRhNameLookupConfirm}
            />
        </div>
    );
};

export default NewRhOwnershipModal;
