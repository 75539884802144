import React, { useEffect, useState } from 'react';

import { changeStateByNestedKey } from '@actions/fugaActions';
import { getNotificationsAPI, markAllAsReadAPI } from '@api/notificationsController';
import NotificationRow from '@common/modals/Notifications/NotificationsRow';
import WarningDialog from '@common/WarningDialog';
import { Notifications as NotificationIcon, CloseOutlined as CloseIcon } from '@mui/icons-material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
    Popover,
    List,
    Typography,
    Divider,
    IconButton,
    Link,
    CircularProgress,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { NOTIFICATION_BACKEND_TYPE } from '@utils/notificationUtils';
import { useDispatch, useSelector } from 'react-redux';

const Notification = ({ open, onClose }) => {
    const dispatch = useDispatch();
    const errorDialogIsOpen = useSelector(
        (state) => state.fugaReducers.repertoires.errorDialogIsOpen,
    );
    const [notificationsData, setNotificationsData] = useState([]);
    const [loading, setLoading] = useState(true);

    const getNotifications = async () => {
        try {
            setLoading(true);
            const { data } = await getNotificationsAPI();
            setNotificationsData(data.notifications);
        } catch (error) {
            console.error('Failed to get notifications', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getNotifications();
        markAllAsRead();
    }, []);

    const markAllAsRead = async () => {
        try {
            await markAllAsReadAPI(NOTIFICATION_BACKEND_TYPE.BASIC);
            dispatch(changeStateByNestedKey('notifications', 'hasUnreadNotifications', false));
        } catch (error) {
            console.error('Failed to mark all as read', error);
        }
    };

    return (
        <>
            <Popover
                open={open}
                anchorReference="anchorPosition"
                onClose={onClose}
                anchorPosition={{ top: 60, left: window.innerWidth - 200 }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <Grid px={2} py={1}>
                    <Grid container>
                        <Grid size={1} sx={{ display: 'flex', alignItems: 'center' }}>
                            <NotificationIcon sx={{
                                height: 24,
                                width: 24,
                                fontSize: 24
                            }} />
                        </Grid>
                        <Grid size={8} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="h6">
                                Notifications
                            </Typography>
                        </Grid>
                        <Grid size={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <IconButton onClick={onClose} size="large">
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid container mb={1}>
                        <Grid size={6}>
                            <Typography variant="p">Showing last 3 days</Typography>
                        </Grid>
                        <Grid size={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Link
                                underline="hover"
                                sx={{
                                    fontSize: '0.875rem',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    textDecoration: 'underline',
                                    fontWeight: '700',
                                }}
                                href={'/events'}
                            >
                                See all events
                                <ArrowForwardIcon />
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider />
                <List sx={{ width: '440px', maxHeight: '400px', overflow: 'auto' }}>
                    {loading ? (
                        <Grid container justifyContent="center" sx={{ padding: 2 }}>
                            <CircularProgress size={24} key="notificationsmodalloading" />
                        </Grid>
                    ) : notificationsData.length === 0 ? (
                        <Grid
                            container
                            justifyContent="center"
                            sx={{ padding: 2 }}
                            key="notificationsmodalempty"
                        >
                            <Typography variant="body2">No notifications</Typography>
                        </Grid>
                    ) : (
                        notificationsData.map((notification) => (
                            <NotificationRow
                                key={
                                    notification.asyncEventId ||
                                    notification.fileId + notification.date
                                }
                                notification={notification}
                            />
                        ))
                    )}
                </List>
            </Popover>
            {errorDialogIsOpen && (
                <WarningDialog style={{ zIndex: '-1' }} isOpen={errorDialogIsOpen} />
            )}
        </>
    );
};

export default Notification;
