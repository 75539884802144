import { useCallback, useEffect, useRef } from 'react';

import { changeStateByKey, changeStateByNestedKey } from '@actions/fugaActions';
import { getFiltersToSend } from '@pages/AssetsCmoPage/common/common';
import { useDispatch, useSelector } from 'react-redux';

/**
 * A reusable hook to handle data fetching and Common Table pagination logic for various pages.
 *
 * @param {Object} params - Configuration parameters for the hook.
 * @param {Function} params.fetchFunction - The function to fetch data.
 * @param {string} params.pageModelName - The name of the model in the redux store.
 * @param {string} [params.pageId] - Optional ID for the page (e.g., for assets or events tied to a specific entity).
 */
const useTableData = ({ fetchFunction, pageModelName, pageId = null }) => {
    const dispatch = useDispatch();
    const filtersModel = useSelector((state) => state.fugaReducers.filters);
    const queryParams = useSelector((state) => state.fugaReducers.queryParams);
    const pageModel = useSelector((state) => state.fugaReducers[pageModelName]);

    const controllerRef = useRef(new AbortController());

    const fetchTableData = useCallback(
        async (clearFilters = false, defaultSort = null) => {
            controllerRef.current.abort();
            controllerRef.current = new AbortController();
            const signal = controllerRef.current.signal;
            const pageIdExists = pageId !== null && pageId !== undefined;

            const bodyParams = clearFilters ? {} : getFiltersToSend(filtersModel);

            const updatedQueryParams = { ...queryParams };

            if (queryParams.sort && queryParams.sort.value) {
                const sortFields = queryParams.sort.value.split(',');
                updatedQueryParams.sort = `${sortFields[0]},${queryParams.sort.direction}`;
            } else if (defaultSort) {
                const sortFields = defaultSort.value.split(',');
                updatedQueryParams.sort = `${sortFields[0]},${defaultSort.direction}`;
            } else {
                delete updatedQueryParams.sort;
            }
            delete updatedQueryParams.sortValue;

            if (pageIdExists && bodyParams?.isrcs?.length > 0) {
                bodyParams.isrcs = bodyParams.isrcs.split(',').map((item) => item.trim());
            }

            // Fetch data using the provided function
            const fetchExecution = pageIdExists
                ? fetchFunction(pageId, updatedQueryParams, bodyParams, signal)
                : fetchFunction(updatedQueryParams, bodyParams, signal);

            await dispatch(fetchExecution);
        },
        [
            dispatch,
            fetchFunction,
            pageModel,
            filtersModel,
            filtersModel?.asyncEventType,
            pageId,
            queryParams,
        ],
    );

    const handlePaginationAndSearch = useCallback(
        (_, updatedParams) => {
            if ('size' in updatedParams) {
                dispatch(changeStateByNestedKey('queryParams', 'size', updatedParams.size));
            }
            if ('page' in updatedParams) {
                dispatch(changeStateByNestedKey('queryParams', 'page', updatedParams.page));
            }
            if ('sort' in updatedParams && 'direction' in updatedParams) {
                dispatch(
                    changeStateByNestedKey('queryParams', 'sort', {
                        value: updatedParams.sort,
                        direction: updatedParams.direction,
                    }),
                );
            }

            fetchTableData();
        },
        [dispatch, fetchTableData],
    );

    const handleSearch = useCallback(
        (term) => {
            dispatch(changeStateByNestedKey('filters', 'searchString', term));
            dispatch(changeStateByNestedKey('queryParams', 'page', 0));
            fetchTableData();
        },
        [dispatch, fetchTableData],
    );

    const clearSearch = useCallback(() => {
        dispatch(changeStateByNestedKey('filters', 'searchString', ''));
        fetchTableData();
    }, [dispatch, fetchTableData]);

    // Cleanup logic on unmount
    useEffect(() => {
        return () => {
            // Reset filters and query params when the component unmounts
            dispatch(changeStateByKey('filters', {}));
            dispatch(changeStateByKey('queryParams', {}));
            dispatch(changeStateByNestedKey(pageModelName, 'dataFromBE', []));
        };
    }, [dispatch]);

    return {
        fetchTableData,
        handlePaginationAndSearch,
        handleSearch,
        clearSearch,
        pageModel,
    };
};

export default useTableData;
