import userData from '@data/userData';
import Fetch from '@utils/Fetch';

const CONTROLLER_URL = userData.api.baseNotificationUrl();

/**
 * POST /api/v1/notifications
 * Get a list of all notifications (with optional request body).
 */
const getNotificationsAPI = (payload = {}) => {
    const url = CONTROLLER_URL;

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    };

    return Fetch.fetchWithErrorToast({
        url,
        options,
        errorMessage: 'Failed to get notifications',
    });
};

/**
 * GET /api/v1/notifications/unread-status
 * Check if the user has unread popup notifications.
 */
const getUnreadStatusAPI = () => {
    const url = `${CONTROLLER_URL}/unread-status`;
    const options = {
        method: 'GET',
    };

    return Fetch.fetchWithErrorToast({
        url,
        options,
        errorMessage: 'Failed to check unread status',
    });
};

/**
 * GET
 * /api/v1/notifications/unread-pop-up-notifications
 * Check if the user has unread notifications.
 */
const getUnreadPopupNotifications = () => {
    const url = `${CONTROLLER_URL}/unread-pop-up-notifications`;

    const options = {
        method: 'GET',
    };

    return Fetch.fetchWithErrorToast({
        url,
        options,
        errorMessage: 'Failed to check unread popup notifications',
    });
};

/**
 * POST /api/v1/notifications/mark-read?userId={userId}
 * Mark all notifications as read for a user.
 */
const markAllAsReadAPI = (type) => {
    const url = `${CONTROLLER_URL}/mark-read`;

    const options = {
        method: 'POST',
        body: JSON.stringify({ type }),
    };

    return Fetch.fetchWithErrorToast({
        url,
        options,
        errorMessage: 'Failed to mark notifications as read',
    });
};

function getReportByIdAPI(id) {
    const url = `${CONTROLLER_URL}/report/${id}`;

    return Fetch.fetchWithErrorToast({
        url,
        errorMessage: 'Failed to get report',
    });
}

export {
    getNotificationsAPI,
    getUnreadStatusAPI,
    getUnreadPopupNotifications,
    markAllAsReadAPI,
    getReportByIdAPI,
};
