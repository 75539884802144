import userData from '@data/userData';
import cerberusUtils from '@utils/cerberusUtils';
import { SSENotificationTypes } from '@utils/constants/notifications';

const CONTROLLER_URL = userData.api.baseUrl + '/sse';

let eventSource = null;

function initSSEConnection({
    handleNotificationUpdate = () => {},
    handlePopupNotification = () => {},
}) {
    console.log('Initializing SSE connection...');

    // Constants for configuration
    const CONNECTION_TIMEOUT = 10000; // 10 seconds
    const MAX_RECONNECT_DELAY = 30000; // 30 seconds
    const INITIAL_RECONNECT_DELAY = 1000; // 1 second
    let reconnectAttempt = 0;

    // Cleanup any existing connection
    if (eventSource) {
        console.warn('Closing existing SSE connection...');
        eventSource.close();
    }

    let connectionTimeout;

    // Function to calculate exponential backoff delay
    function getReconnectDelay() {
        return Math.min(
            INITIAL_RECONNECT_DELAY * Math.pow(2, reconnectAttempt++),
            MAX_RECONNECT_DELAY,
        );
    }

    // Reconnection logic
    function reconnect() {
        const delay = getReconnectDelay();
        console.log(`Reconnecting in ${delay}ms (attempt ${reconnectAttempt})...`);
        setTimeout(() => initSSEConnection(handleNotificationUpdate), delay);
    }

    // Generate the SSE URL with token
    const token = cerberusUtils.getCerberusToken();
    if (!token) {
        console.error('No token available. SSE connection not established.');
        return;
    }
    const url = new URL(`${CONTROLLER_URL}/subscribe`);
    url.searchParams.append('token', token);

    // Create a new EventSource instance
    eventSource = new EventSource(url.toString(), { withCredentials: true });

    // Handle connection timeout
    connectionTimeout = setTimeout(() => {
        if (eventSource && eventSource.readyState === 0) {
            console.error('Connection timeout. Closing EventSource...');
            eventSource.close();
            reconnect();
        }
    }, CONNECTION_TIMEOUT);

    // Event handlers
    eventSource.onopen = () => {
        clearTimeout(connectionTimeout);
        reconnectAttempt = 0; // Reset reconnect attempts
        console.info('SSE connection successfully established.');
    };

    eventSource.onmessage = (event) => {
        console.debug('Received unnamed message:', event.data);
    };

    eventSource.addEventListener('eventUpdate', (event) => {
        try {
            const data = JSON.parse(event.data);
            if (data.type === SSENotificationTypes.BASIC) {
                handleNotificationUpdate();
            } else if (data.type === SSENotificationTypes.POPUP) {
                handlePopupNotification(data);
            } else {
                console.warn('Unhandled event type:', data.type);
            }
        } catch (error) {
            console.error('Error parsing SSE data:', error);
            console.debug('Raw event data:', event.data);
        }
    });

    eventSource.onerror = (error) => {
        console.error('SSE connection error:', error);

        // Clean up the connection
        if (eventSource) {
            eventSource.close();
            eventSource = null;
        }

        reconnect();
    };

    // Return a cleanup function
    return () => {
        if (eventSource) {
            console.log('Cleaning up SSE connection...');
            eventSource.close();
            eventSource = null;
        }
    };
}

const cleanupSSE = async () => {
    if (eventSource) {
        const token = cerberusUtils.getCerberusToken();
        const url = new URL(`${CONTROLLER_URL}/unsubscribe`);
        url.searchParams.append('token', token);

        try {
            await fetch(url.toString(), {
                method: 'DELETE',
                credentials: 'include',
            });
        } catch (error) {
            console.warn('Error during SSE cleanup:', error);
        } finally {
            console.log('Closing SSE connection during cleanup');
            eventSource.close();
            eventSource = null;
        }
    }
};

export { initSSEConnection, cleanupSSE };
