import React from 'react';

import ExportRow from '@pages/EventsPage/Events/ExportRow';
import IngestionRow from '@pages/EventsPage/Events/IngestionRow';
import SpotifyExtractRow from '@pages/EventsPage/Events/SpotifyExtractRow';
import { NotificationTypes } from '@utils/constants/notifications';

const EventsTableRow = ({ headerItem, dataItem }) => {
    if (dataItem.eventType === NotificationTypes.EXPORT) {
        return <ExportRow headerItem={headerItem} dataItem={dataItem} />;
    } else if (dataItem.eventType === NotificationTypes.INGESTION) {
        return <IngestionRow headerItem={headerItem} dataItem={dataItem} />;
    } else {
        return <SpotifyExtractRow headerItem={headerItem} dataItem={dataItem} />;
    }
};

export default EventsTableRow;
