import React, { useEffect, useState } from 'react';

import { getGenres, getLanguages } from '@api/assetController';
import { getAllTerritoriesAPI } from '@api/territoriesController';
import {
    FormControl,
    Typography,
    Checkbox,
    FormControlLabel,
    RadioGroup,
    FormLabel,
    Radio,
    MenuItem,
    Select,
    InputLabel,
    FormHelperText,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { DatePicker } from '@mui/x-date-pickers';
import TextFieldWrapper from '@MuiWrappers/TextFieldWrapper';
import { ASSET_METADATA_FIELDS } from '@utils/constants/AssetMetadataEditModal';
import { format, parse } from 'date-fns';
import { Controller, useFormContext } from 'react-hook-form';

const radioOptions = [
    { value: 'track', label: 'Track' },
    { value: 'classical_track', label: 'Classical track' },
    { value: 'ringtone', label: 'Ringtone' },
    { value: 'music_video', label: 'Music video' },
];

const SelectInput = ({ name, label, options, control, errors, required }) => (
    <Controller
        name={name}
        control={control}
        render={({ field }) => (
            <FormControl fullWidth required={required} error={!!errors[name]} variant="standard">
                <InputLabel shrink>{label}</InputLabel>
                <Select {...field} displayEmpty>
                    <MenuItem value="">
                        <em>Select {label}</em>
                    </MenuItem>
                    {options.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
                {errors[name] && (
                    <FormHelperText error variant="caption">
                        {errors[name].message}
                    </FormHelperText>
                )}
            </FormControl>
        )}
    />
);

const CheckboxGroup = ({ fields, control }) => (
    <FormControl component="fieldset" sx={{ display: 'flex', flexDirection: 'row' }}>
        {fields.map(({ name, label }) => (
            <FormControlLabel
                key={name}
                control={
                    <Controller
                        name={name}
                        control={control}
                        render={({ field }) => (
                            <Checkbox
                                {...field}
                                checked={!!field.value}
                                onChange={(e) => field.onChange(e.target.checked)}
                            />
                        )}
                    />
                }
                label={label}
            />
        ))}
    </FormControl>
);

export function AssetMetaDataRecordingDetailsSection() {
    const {
        control,
        formState: { errors },
        trigger,
    } = useFormContext();

    const [genres, setGenres] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [countries, setCountries] = useState([]);

    useEffect(() => {
        const getSelectData = async () => {
            const [genresData, languagesData, territories] = await Promise.all([
                getGenres(),
                getLanguages(),
                getAllTerritoriesAPI(),
            ]);

            const countriesList = territories.data
                .flatMap((region) => region.territories.map((territory) => territory.name))
                .sort((a, b) => a.localeCompare(b));

            setCountries(countriesList);
            setGenres(genresData.data.genres);
            setLanguages(languagesData.data.languages.sort((a, b) => a.localeCompare(b)));
        };

        getSelectData();
    }, []);

    return (
        <>
            <Grid
                container
                spacing={3}
                sx={{
                    mb: 1.5,
                }}
            >
                {/* Duration Field */}
                <Grid size={{ xs: 12, sm: 2 }}>
                    <Controller
                        name={ASSET_METADATA_FIELDS.duration}
                        control={control}
                        render={({ field }) => (
                            <TextFieldWrapper
                                {...field}
                                label="Duration"
                                fullWidth
                                required
                                error={!!errors.duration}
                                helperText={errors.duration ? errors.duration.message : ''}
                            />
                        )}
                    />
                </Grid>

                {/* Type Field */}
                <Grid size={12}>
                    <FormControl
                        component="fieldset"
                        fullWidth
                        required
                        error={!!errors.contentType}
                    >
                        <FormLabel component="legend">Type</FormLabel>
                        <Controller
                            name={ASSET_METADATA_FIELDS.contentType}
                            control={control}
                            render={({ field }) => (
                                <RadioGroup
                                    {...field}
                                    row
                                    onChange={(e) => {
                                        field.onChange(e.target.value);
                                        trigger(ASSET_METADATA_FIELDS.contentType);
                                    }}
                                >
                                    {radioOptions.map((option) => (
                                        <FormControlLabel
                                            key={option.value}
                                            value={option.value}
                                            control={<Radio size="small" color="primary" />}
                                            label={option.label}
                                        />
                                    ))}
                                </RadioGroup>
                            )}
                        />
                        {errors.contentType && (
                            <Typography color="error" variant="caption">
                                {errors.contentType.message}
                            </Typography>
                        )}
                    </FormControl>
                </Grid>

                {/* Remastered, Medley, Contains Sample (Checkboxes) */}
                <Grid size={12}>
                    <CheckboxGroup
                        fields={[
                            { name: ASSET_METADATA_FIELDS.isRemastered, label: 'Remastered' },
                            { name: ASSET_METADATA_FIELDS.medley, label: 'Medley' },
                            {
                                name: ASSET_METADATA_FIELDS.containsSample,
                                label: 'Contains Sample',
                            },
                        ]}
                    />
                </Grid>
            </Grid>
            <Grid
                container
                spacing={3}
                sx={{
                    mb: 1.5,
                }}
            >
                {/* Country of Recording and Commissioning Country */}
                <Grid size={{ xs: 12, sm: 4 }}>
                    <SelectInput
                        name={ASSET_METADATA_FIELDS.recordingCountry}
                        label="Country of Recording"
                        options={countries}
                        control={control}
                        errors={errors}
                        required
                    />
                </Grid>
                <Grid size={{ xs: 12, sm: 4 }}>
                    <SelectInput
                        name={ASSET_METADATA_FIELDS.commissioningCountry}
                        label="Commissioning Country"
                        options={countries}
                        control={control}
                        errors={errors}
                        required
                    />
                </Grid>
            </Grid>
            <Grid
                container
                spacing={3}
                sx={{
                    mb: 1.5,
                }}
            >
                {/* Recording Date and Recording Year */}
                <Grid size={{ xs: 12, sm: 4 }}>
                    <Controller
                        name={ASSET_METADATA_FIELDS.recordingDate}
                        control={control}
                        render={({ field }) => (
                            <DatePicker
                                {...field}
                                label="Recording Date"
                                slotProps={{
                                    textField: {
                                        variant: 'standard',
                                        fullWidth: true,
                                        error: !!errors.recordingDate,
                                        helperText: errors.recordingDate
                                            ? errors.recordingDate.message
                                            : '',
                                    },
                                }}
                                value={
                                    field.value
                                        ? parse(field.value, 'yyyy-MM-dd', new Date())
                                        : null
                                }
                                onChange={(date) => {
                                    field.onChange(date ? format(date, 'yyyy-MM-dd') : '');
                                    trigger([
                                        ASSET_METADATA_FIELDS.recordingYear,
                                        ASSET_METADATA_FIELDS.pyear,
                                        ASSET_METADATA_FIELDS.recordingDate,
                                    ]);
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid size={{ xs: 12, sm: 4 }}>
                    <Controller
                        name={ASSET_METADATA_FIELDS.recordingYear}
                        control={control}
                        render={({ field }) => (
                            <DatePicker
                                {...field}
                                views={['year']}
                                label="Recording Year"
                                slotProps={{
                                    textField: {
                                        variant: 'standard',
                                        fullWidth: true,
                                        required: true,
                                        error: !!errors.recordingYear,
                                        helperText: errors.recordingYear
                                            ? errors.recordingYear.message
                                            : '',
                                        sx: { cursor: 'pointer' },
                                    },
                                }}
                                value={field.value ? parse(field.value, 'yyyy', new Date()) : null}
                                onChange={(date) => {
                                    field.onChange(date ? format(date, 'yyyy') : '');
                                    trigger([
                                        ASSET_METADATA_FIELDS.recordingYear,
                                        ASSET_METADATA_FIELDS.pyear,
                                        ASSET_METADATA_FIELDS.recordingDate,
                                    ]);
                                }}
                            />
                        )}
                    />
                </Grid>
            </Grid>
            {/* Genre and Language of Performers */}
            <Grid
                container
                spacing={3}
                sx={{
                    mb: 1.5,
                }}
            >
                <Grid size={{ xs: 12, sm: 4 }}>
                    <SelectInput
                        name={ASSET_METADATA_FIELDS.genre}
                        label="Genre"
                        options={genres.map((genre) => genre.toLowerCase())}
                        control={control}
                        errors={errors}
                        required
                    />
                </Grid>
                <Grid size={{ xs: 12, sm: 4 }}>
                    <SelectInput
                        name={ASSET_METADATA_FIELDS.language}
                        label="Language of Performance"
                        options={languages}
                        control={control}
                        errors={errors}
                        required
                    />
                </Grid>
            </Grid>
        </>
    );
}
