import React, { useEffect } from 'react';

import { changeStateByKey } from '@actions/fugaActions';
import { getAssetClientNameLookup, getAssetTrackTitleLookup } from '@api/assetController';
import CommonFiltersContainer from '@common/CommonFiltersContainer';
import { TableFilters } from '@common/TableFilters/TableFilters';
import { FilterMenuTypes } from '@utils/enum';
import { useDispatch, useSelector } from 'react-redux';

const ISRCS_SIZE_LIMIT = 5000;

const chackboxItemsListStatuses = [
    { id: 1, title: 'To be registered', name: 'TO_BE_REGISTERED' },
    { id: 2, title: 'Exported', name: 'EXPORTED' },
    { id: 3, title: 'Registered', name: 'REGISTERED' },
    { id: 4, title: 'Unavailable', name: 'UNAVAILABLE' },
    { id: 5, title: 'Not applicable', name: 'NOT_APPLICABLE' },
    { id: 6, title: 'Relinquished', name: 'RELINQUISHED' },
    { id: 7, title: 'Submitted', name: 'SUBMITTED' },
];

const chackboxItemsListManualAlerts = [
    {
        title: 'Dispute',
        name: 'DISPUTE',
    },
    {
        title: 'Evidence needed',
        name: 'EVIDENCE_REQUIRED',
    },
    {
        title: 'Action required',
        name: 'ACTION_REQUIRED',
    },
];

function AssetsCmoTableFiltersWrapper({ tableReloadCb, clearAllFilters, isPerformer }) {
    const dispatch = useDispatch();
    const filtersModel = useSelector((state) => state.fugaReducers.filters);

    useEffect(() => {
        dispatch(changeStateByKey('filters', {}));
    }, [dispatch]);

    const isrcValidation = (value) => {
        if (value === '') return '';
        const isrcs = value.split(',');
        if (isrcs.length > ISRCS_SIZE_LIMIT) {
            return `Exceeds the limit of ${ISRCS_SIZE_LIMIT} ISRCs with ${isrcs.length} ISRCs`;
        }
        const invalidSize = isrcs.filter((isrc) => !isrc.trim()).length;
        return invalidSize === 0 ? '' : `${invalidSize} ISRC(s) with invalid format`;
    };

    const menuItems = [
        {
            label: 'ISRC',
            type: FilterMenuTypes.INPUT,
            inputLabel: 'ISRCs',
            filterField: 'isrcs',
            filterNameInRequest: 'isrcs',
            placeholder: 'Enter or paste ISRCs separated by commas',
            value: filtersModel?.isrcs?.valuesToShow || [],
            validation: isrcValidation,
        },
        {
            label: 'Statuses',
            filterField: 'Status',
            type: FilterMenuTypes.DEFAULT,
            value: filtersModel?.statuses?.valuesToShow || [],
            chackboxItemsList: chackboxItemsListStatuses,
            filterNameInRequest: 'statuses',
        },
        {
            label: 'Alerts',
            filterField: 'Alerts',
            type: FilterMenuTypes.DEFAULT,
            filterNameInRequest: 'alertTypes',
            value: filtersModel?.alertTypes?.valuesToShow || [],
            chackboxItemsList: chackboxItemsListManualAlerts,
        },
        {
            label: 'Track Titles',
            filterField: 'TrackTitles',
            type: FilterMenuTypes.AUTOCOMPLETE,
            filterNameInRequest: 'trackTitles',
            value: filtersModel?.trackTitles?.valuesToShow || [],
            getSuggestions: getAssetTrackTitleLookup,
            placeholder: 'Search by track titles',
            lookupField: 'trackTitles',
        },
        {
            label: 'Client',
            filterField: 'clientName',
            type: FilterMenuTypes.AUTOCOMPLETE,
            filterNameInRequest: 'clientNames',
            value: filtersModel?.clientNames?.valuesToShow || [],
            getSuggestions: (text, signal) => {
                return getAssetClientNameLookup({ clientName: text, signal, isPerformer });
            },
            placeholder: 'Search by client',
            lookupField: 'clientNames',
        },
        {
            label: 'Modified Date',
            filterField: 'lastModified',
            type: FilterMenuTypes.DATEPICKER,
            filterNameInRequest: 'lastModified',
            value: filtersModel?.lastModified?.valuesToShow || [],
        },
    ];

    return (
        <CommonFiltersContainer
            innerFiltersComponent={
                <TableFilters
                    tableReloadCb={tableReloadCb}
                    menuItems={menuItems}
                    clearAllFilters={clearAllFilters}
                />
            }
        />
    );
}

export default AssetsCmoTableFiltersWrapper;
