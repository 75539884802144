import React from 'react';

import MuiSvgIcon from '@mui/material/SvgIcon';

export const SpotifyExtractIcon = (props) => (
    <MuiSvgIcon {...props} data-testid="spotify-icon">
        <svg
            width="25"
            height="26"
            viewBox="0 0 25 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask
                id="path-1-outside-1_8647_74665"
                maskUnits="userSpaceOnUse"
                x="2"
                y="2.5"
                width="20"
                height="20"
                fill="black"
            >
                <rect fill="white" x="2" y="2.5" width="20" height="20" />
                <path d="M11.75 20C16.028 20 19.5 16.528 19.5 12.25C19.5 7.972 16.028 4.5 11.75 4.5C7.472 4.5 4 7.972 4 12.25C4 16.528 7.472 20 11.75 20ZM14.8965 15.9028V15.9035C14.3753 15.9035 12.7291 14.0771 8.10233 15.0251C7.98027 15.0568 7.82075 15.1065 7.73033 15.1065C7.1394 15.1065 7.02637 14.2224 7.66187 14.0874C10.2213 13.5223 12.8369 13.572 15.0676 14.9063C15.5998 15.246 15.3738 15.9028 14.8965 15.9028V15.9028ZM15.738 13.8536C15.665 13.8342 15.6864 13.8982 15.3525 13.7225C13.3988 12.5665 10.4874 12.1002 7.89631 12.8035C7.74648 12.8442 7.6651 12.8849 7.52431 12.8849C6.83262 12.8849 6.64921 11.8444 7.4029 11.6319C10.4486 10.7762 13.7159 11.2754 15.9905 12.6285C16.2437 12.7783 16.3438 12.9727 16.3438 13.2439C16.3406 13.5811 16.0784 13.8536 15.738 13.8536V13.8536ZM6.93725 9.01954C9.85835 8.16446 14.2791 8.43442 17.0278 10.0387C17.7324 10.4449 17.4553 11.4724 16.7061 11.4724L16.7055 11.4718C16.5427 11.4718 16.4426 11.4311 16.3018 11.3497C14.0782 10.0219 10.0992 9.70348 7.52496 10.4223C7.41194 10.4533 7.27115 10.503 7.12131 10.503C6.70863 10.503 6.39346 10.1807 6.39346 9.76548C6.39346 9.34117 6.65631 9.10092 6.93725 9.01954V9.01954Z" />
            </mask>
            <path
                d="M11.75 20C16.028 20 19.5 16.528 19.5 12.25C19.5 7.972 16.028 4.5 11.75 4.5C7.472 4.5 4 7.972 4 12.25C4 16.528 7.472 20 11.75 20ZM14.8965 15.9028V15.9035C14.3753 15.9035 12.7291 14.0771 8.10233 15.0251C7.98027 15.0568 7.82075 15.1065 7.73033 15.1065C7.1394 15.1065 7.02637 14.2224 7.66187 14.0874C10.2213 13.5223 12.8369 13.572 15.0676 14.9063C15.5998 15.246 15.3738 15.9028 14.8965 15.9028V15.9028ZM15.738 13.8536C15.665 13.8342 15.6864 13.8982 15.3525 13.7225C13.3988 12.5665 10.4874 12.1002 7.89631 12.8035C7.74648 12.8442 7.6651 12.8849 7.52431 12.8849C6.83262 12.8849 6.64921 11.8444 7.4029 11.6319C10.4486 10.7762 13.7159 11.2754 15.9905 12.6285C16.2437 12.7783 16.3438 12.9727 16.3438 13.2439C16.3406 13.5811 16.0784 13.8536 15.738 13.8536V13.8536ZM6.93725 9.01954C9.85835 8.16446 14.2791 8.43442 17.0278 10.0387C17.7324 10.4449 17.4553 11.4724 16.7061 11.4724L16.7055 11.4718C16.5427 11.4718 16.4426 11.4311 16.3018 11.3497C14.0782 10.0219 10.0992 9.70348 7.52496 10.4223C7.41194 10.4533 7.27115 10.503 7.12131 10.503C6.70863 10.503 6.39346 10.1807 6.39346 9.76548C6.39346 9.34117 6.65631 9.10092 6.93725 9.01954V9.01954Z"
                fill="white"
            />
            <path
                d="M11.75 20C16.028 20 19.5 16.528 19.5 12.25C19.5 7.972 16.028 4.5 11.75 4.5C7.472 4.5 4 7.972 4 12.25C4 16.528 7.472 20 11.75 20ZM14.8965 15.9028V15.9035C14.3753 15.9035 12.7291 14.0771 8.10233 15.0251C7.98027 15.0568 7.82075 15.1065 7.73033 15.1065C7.1394 15.1065 7.02637 14.2224 7.66187 14.0874C10.2213 13.5223 12.8369 13.572 15.0676 14.9063C15.5998 15.246 15.3738 15.9028 14.8965 15.9028V15.9028ZM15.738 13.8536C15.665 13.8342 15.6864 13.8982 15.3525 13.7225C13.3988 12.5665 10.4874 12.1002 7.89631 12.8035C7.74648 12.8442 7.6651 12.8849 7.52431 12.8849C6.83262 12.8849 6.64921 11.8444 7.4029 11.6319C10.4486 10.7762 13.7159 11.2754 15.9905 12.6285C16.2437 12.7783 16.3438 12.9727 16.3438 13.2439C16.3406 13.5811 16.0784 13.8536 15.738 13.8536V13.8536ZM6.93725 9.01954C9.85835 8.16446 14.2791 8.43442 17.0278 10.0387C17.7324 10.4449 17.4553 11.4724 16.7061 11.4724L16.7055 11.4718C16.5427 11.4718 16.4426 11.4311 16.3018 11.3497C14.0782 10.0219 10.0992 9.70348 7.52496 10.4223C7.41194 10.4533 7.27115 10.503 7.12131 10.503C6.70863 10.503 6.39346 10.1807 6.39346 9.76548C6.39346 9.34117 6.65631 9.10092 6.93725 9.01954V9.01954Z"
                stroke="black"
                strokeOpacity="0.6"
                strokeWidth="3.6"
                mask="url(#path-1-outside-1_8647_74665)"
            />
            <mask
                id="mask0_8647_74665"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="8"
                y="8"
                width="19"
                height="20"
            >
                <rect x="8.5" y="9" width="18" height="18" fill="#D9D9D9" stroke="black" />
            </mask>
            <g mask="url(#mask0_8647_74665)">
                <circle cx="17.5" cy="18" r="7.125" fill="white" />
                <path
                    d="M17.5152 20.6794L20.1186 18.0762L19.5736 17.5311L17.9111 19.1936V15.3054H17.1042V19.1936L15.4417 17.5311L14.8966 18.0762L17.5152 20.6794ZM17.5026 25.125C16.5173 25.125 15.591 24.938 14.7236 24.5641C13.8564 24.1902 13.1019 23.6827 12.4603 23.0417C11.8186 22.4007 11.3107 21.6469 10.9365 20.7803C10.5622 19.9139 10.375 18.9879 10.375 18.0026C10.375 17.0173 10.562 16.091 10.9359 15.2236C11.3098 14.3564 11.8173 13.6019 12.4583 12.9602C13.0993 12.3186 13.8531 11.8107 14.7197 11.4365C15.5861 11.0622 16.5121 10.875 17.4974 10.875C18.4827 10.875 19.409 11.062 20.2764 11.4359C21.1436 11.8098 21.8981 12.3173 22.5398 12.9583C23.1814 13.5993 23.6893 14.3531 24.0635 15.2197C24.4378 16.0861 24.625 17.0121 24.625 17.9974C24.625 18.9827 24.438 19.909 24.0641 20.7764C23.6902 21.6436 23.1827 22.3981 22.5417 23.0398C21.9007 23.6814 21.1469 24.1893 20.2803 24.5635C19.4139 24.9378 18.4879 25.125 17.5026 25.125Z"
                    fill="black"
                    fillOpacity="0.6"
                />
                <circle cx="17.5" cy="18" r="6.125" stroke="white" strokeWidth="2" />
            </g>
        </svg>
    </MuiSvgIcon>
);
