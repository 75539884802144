import React, { useCallback, useEffect, useState } from 'react';

import {
    getAlertMsgByCmoRegId,
    getCmoConnectionsByClientId,
    updateNestedField,
} from '@actions/apiActions';
import { changeStateByNestedKey } from '@actions/fugaActions';
import { formatTerritoryCodes, getAllTerritories } from '@actions/territories';
import { changeRegistrationStatusAPI, deleteCmoRegistrationAPI } from '@api/cmoController';
import CommonConfirmationDialog from '@common/CommonConfirmationDialog';
import CommonDropDownChips, { STATUSES_LIST, chipThemes } from '@common/CommonDropDownChips';
import CommonTableGrid from '@common/CommonTable/CommonTableGrid';
import { ChipLabel } from '@common/customChips';
import CmoRegAlertsModal from '@common/modals/CmoRegAlertsModal';
import CmoRegCommentsModal from '@common/modals/CmoRegCommentsModal';
import NewCmoConnectionModal from '@common/modals/NewCmoConnectionModal/NewCmoConnectionModal';
import StatusHistoryModal from '@common/modals/StatusHistoryModal';
import MoreDropDownButton from '@common/MoreDropDownButton';
import OverflowTip from '@common/OverflowTip';
import ChatBubbleUnread from '@images/svgComponents/ChatBubbleUnreadSvg';
import PendingIconSvg from '@images/svgComponents/PendingIconSvg';
import {
    ChatBubbleOutline,
    Close,
    Done,
    ErrorOutline,
    ScheduleOutlined,
} from '@mui/icons-material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box, Tooltip, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

const FlexAlignCenterBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
}));

const CustomIconButton = styled(IconButton)(() => ({
    padding: '8px',
    '&:hover': {
        backgroundColor: 'unset',
    },
}));

const headers = [
    {
        title: 'CMO',
        dataIndex: 'cmoName',
        fixedWidth: {
            isFixed: true,
            value: '100px',
        },
    },
    {
        title: 'Territories',
        dataIndex: 'territoriesToShow',
        fixedWidth: {
            isFixed: true,
            value: '100px',
        },
    },
    {
        title: 'Start Date',
        dataIndex: 'startDate',
    },
    {
        title: 'End Date',
        dataIndex: 'endDate',
    },
    {
        title: 'Registration Status',
        dataIndex: 'registrationStatus',
    },
    {
        title: 'Last Modified',
        dataIndex: 'statusDate',
        fixedWidth: {
            isFixed: true,
            value: '100px',
        },
    },
    {
        title: '',
        dataIndex: 'commentPresented',
        hasIcon: true,
    },
];

function ClientCmoConnectionTable() {
    const dispatch = useDispatch();
    const params = useParams();

    const cmoConnectionModel = useSelector((state) => state.fugaReducers.cmoConnection);
    const rhDataModel = useSelector((state) => state.fugaReducers.rhData);
    const territoriesModel = useSelector((state) => state.fugaReducers.territories);

    const [anchorEl, setAnchorEl] = useState(null);
    const [showTerritoryModal, setShowTerritoryModal] = useState(false);
    const [territories, setTerritories] = useState([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [cmoRegId, setCmoRegId] = useState(null);
    const [statusChangeOptions, setStatusChangeOptions] = useState([]);
    const [isStatusDialogOpen, setIsStatusDialogOpen] = useState(false);
    const [openCommentsModal, setOpenCommentsModal] = useState(false);
    const [clickedDataItem, setClickedDataItem] = useState({});
    const [openAlertsModal, setOpenAlertsModal] = useState(false);

    useEffect(() => {
        dispatch(getCmoConnectionsByClientId(params.id));
    }, [dispatch, params.id]);

    useEffect(() => {
        dispatch(getAllTerritories());
        setTerritories(territoriesModel.checkedTerritories);
    }, [dispatch, territoriesModel.checkedTerritories]);

    const openModal = () => {
        setShowTerritoryModal(true);
    };

    const handleAlertClick = async (dataItem) => {
        setClickedDataItem(dataItem);
        await dispatch(getAlertMsgByCmoRegId(dataItem.cmoRegId));
        setOpenAlertsModal(true);
    };

    const handleHistoryClick = (evt, dataItem) => {
        dispatch(changeStateByNestedKey('cmoConnection', 'selectedRowDataItem', dataItem));
        setAnchorEl(evt.currentTarget);
    };

    const openHistoryInfo = Boolean(anchorEl);

    const handleHistoryClose = () => {
        setAnchorEl(null);
    };

    const deleteConnection = async () => {
        await deleteCmoRegistrationAPI(cmoRegId);
        await dispatch(getCmoConnectionsByClientId(params.id));
    };

    const updateStatusInRow = (response) => {
        const dataItemFromResp = response.data[0].id;

        // Update statusDate
        dispatch(
            updateNestedField('cmoConnection', 'dataFromBE', dataItemFromResp.statusDate, {
                arrayName: 'content',
                idFieldName: 'cmoRegId',
                id: dataItemFromResp.clientCmoRegistrationId,
                fieldNameToChange: 'statusDate',
            }),
        );

        // Update registrationStatus
        dispatch(
            updateNestedField('cmoConnection', 'dataFromBE', dataItemFromResp.newStatus, {
                arrayName: 'content',
                idFieldName: 'cmoRegId',
                id: dataItemFromResp.clientCmoRegistrationId,
                fieldNameToChange: 'registrationStatus',
            }),
        );

        // Refresh acceptableRegistrationStatuses
        dispatch(
            getCmoConnectionsByClientId(params.id, {
                isUpdateble: true,
                arrayName: 'content',
                idFieldName: 'cmoRegId',
                id: dataItemFromResp.clientCmoRegistrationId,
                fieldNameToChange: 'acceptableRegistrationStatuses',
            }),
        );
    };

    const statusChangeCb = async (status, dataItem, fromModal = false) => {
        // If "Submitted" is first-time, show a confirmation
        if (cmoConnectionModel.isFirstToSubmit && !fromModal) {
            setStatusChangeOptions([status, dataItem]);
            setIsStatusDialogOpen(true);
        } else {
            const response = await changeRegistrationStatusAPI({
                clientCmoRegistrationId: dataItem.cmoRegId,
                newStatus: status,
            });
            updateStatusInRow(response);
        }
    };

    const handleCommentsClick = (e, dataItem) => {
        setClickedDataItem(dataItem);
        setOpenCommentsModal(true);
    };

    const moreOptionsSpecificData = useCallback(
        (headerItem, dataItem) => {
            const columnTitle = headerItem.title.toLowerCase();

            if (columnTitle === '') {
                const onEditCB = () => {
                    dispatch(formatTerritoryCodes(dataItem.territoriesCodes));
                    dispatch(changeStateByNestedKey('rhData', 'selectedRowDataItem', dataItem));
                    dispatch(changeStateByNestedKey('rhData', 'selectedCmoId', dataItem.cmoId));
                    openModal();
                };
                const onDeleteCB = () => {
                    setIsDialogOpen(true);
                    setCmoRegId(dataItem.cmoRegId);
                };
                const onAlertCB = () => {
                    handleAlertClick(dataItem);
                };

                return (
                    <>
                        <CustomIconButton
                            onClick={(e) => handleHistoryClick(e, dataItem)}
                            disableRipple
                            size="large"
                        >
                            <ScheduleOutlined />
                        </CustomIconButton>
                        <CustomIconButton
                            onClick={(e) => handleCommentsClick(e, dataItem)}
                            disableRipple
                            size="large"
                        >
                            {dataItem.commentPresented ? (
                                <ChatBubbleUnread />
                            ) : (
                                <ChatBubbleOutline />
                            )}
                        </CustomIconButton>
                        <MoreDropDownButton
                            callbacks={{ onEditCB, onDeleteCB, onAlertCB }}
                            dataItem={dataItem}
                        />
                    </>
                );
            }

            if (columnTitle.includes('start date')) {
                const status = dataItem.status?.toLowerCase();
                const iconStyles = { marginRight: 1 };

                const StatusIcon = () => {
                    if (status === 'active') {
                        return <Done sx={{ ...iconStyles, color: '#00A542' }} />;
                    }
                    if (status === 'inactive') {
                        return <Close sx={{ ...iconStyles, color: '#5F5F60' }} />;
                    }
                    return <PendingIconSvg sx={{ ...iconStyles, color: '#FF8800' }} />;
                };

                return (
                    <OverflowTip>
                        <FlexAlignCenterBox>
                            <StatusIcon />
                            {dataItem[headerItem.dataIndex]}
                        </FlexAlignCenterBox>
                    </OverflowTip>
                );
            }

            if (columnTitle.includes('registration status') && dataItem.registrationStatus) {
                const menuItemsList = (dataItem.acceptableRegistrationStatuses || [])
                    .filter((status) => Object.keys(STATUSES_LIST).includes(status))
                    .map((status) => ({
                        title: status.split('_').join(' '),
                        onClickClb: statusChangeCb,
                        CbArgs: [status, dataItem],
                        color: STATUSES_LIST[status].color,
                    }));

                return (
                    <FlexAlignCenterBox>
                        {dataItem[headerItem.dataIndex] === 'EXCLUSIVE_LICENSE_DEAL' ? (
                            <ChipLabel label="EXCLUSIVE LICENSE" style={chipThemes.greenChip} />
                        ) : (
                            <CommonDropDownChips
                                chipTitle={dataItem[headerItem.dataIndex].split('_').join(' ')}
                                menuItemsList={menuItemsList}
                                customStyles={{ maxWidth: 200 }}
                                hasAlerts={dataItem?.alertCategories}
                            />
                        )}
                        {/* Alerts Icons */}
                        <FlexAlignCenterBox
                            sx={{ marginLeft: 1 }}
                            onClick={() => handleAlertClick(dataItem)}
                        >
                            {(dataItem.alertCategories || []).includes('INTERNAL') && (
                                <Tooltip title="Internal action required">
                                    <ErrorOutline
                                        sx={{
                                            fill: '#F44139',
                                        }}
                                    />
                                </Tooltip>
                            )}
                            {(dataItem.alertCategories || []).includes('CLIENT') && (
                                <Tooltip title="Client action required">
                                    <WarningAmberIcon
                                        sx={{
                                            fill: '#FF8800',
                                        }}
                                    />
                                </Tooltip>
                            )}
                            {(dataItem.alertCategories || []).includes('CMO') && (
                                <Tooltip title="Awaiting CMO action">
                                    <InfoOutlinedIcon
                                        sx={{
                                            fill: '#45A2DD',
                                        }}
                                    />
                                </Tooltip>
                            )}
                        </FlexAlignCenterBox>
                    </FlexAlignCenterBox>
                );
            }

            if (columnTitle.includes('last modified')) {
                return (
                    <OverflowTip>
                        {moment(dataItem[headerItem.dataIndex]).format('YYYY-MM-DD')}
                    </OverflowTip>
                );
            }

            return <OverflowTip>{dataItem[headerItem.dataIndex]}</OverflowTip>;
        },
        [dispatch, handleAlertClick, statusChangeCb],
    );

    return (
        <Box sx={{ marginTop: '16px' }}>
            <CommonTableGrid
                dataList={cmoConnectionModel.dataFromBE}
                headerList={headers}
                cellDataCb={moreOptionsSpecificData}
                loading={cmoConnectionModel.tableIsLoading}
                preferences={{
                    loadDataByPageCb: {
                        dispatchFn: dispatch,
                        callbackFn: getCmoConnectionsByClientId,
                        argumentsList: [params.id],
                    },
                }}
                initialRowsPerPage={50}
                selection={{
                    isSelectable: false,
                }}
            />
            {showTerritoryModal && (
                <NewCmoConnectionModal
                    showTerritoryModal={showTerritoryModal}
                    setShowTerritoryModal={setShowTerritoryModal}
                    territories={territories}
                    setTerritories={setTerritories}
                    groupedTerritories={territoriesModel.groupedTerritories}
                    clientId={params.id}
                    isEditing
                    customData={{
                        // This is edit mode
                        dealsContainerName: 'clientDealDTO',
                        selectedCmoStoreName: 'rhData',
                        dataModel: rhDataModel.detailsData,
                        selectedCmoId: rhDataModel.selectedCmoId,
                        modalValidations: rhDataModel.modalValidations,
                        selectedRowDataItem: rhDataModel.selectedRowDataItem,
                        isPerformer: { isRH: true },
                        onTableReload: {
                            reloadCb: getCmoConnectionsByClientId,
                            params: [params.id],
                        },
                        cmoIssuedIds: rhDataModel.cmoIssuedIds,
                        hasIssuedId: rhDataModel.hasIssuedId,
                    }}
                />
            )}
            <CommonConfirmationDialog
                isOpen={isDialogOpen}
                closeCb={setIsDialogOpen}
                title="Delete CMO for this client?"
                description="Are you sure you want to delete this CMO row for this client?"
                onConfirmCb={deleteConnection}
            />
            {isStatusDialogOpen && (
                <CommonConfirmationDialog
                    isOpen={isStatusDialogOpen}
                    closeCb={setIsStatusDialogOpen}
                    title="Begin Client CMO Registration?"
                    description="Once this registration is 'Submitted' then you will not be able to edit the Client Deal, do you want to proceed?"
                    onConfirmCb={statusChangeCb}
                    confirmCbArgs={[...statusChangeOptions, true]}
                />
            )}
            <StatusHistoryModal
                anchorEl={anchorEl}
                onClose={handleHistoryClose}
                isOpen={openHistoryInfo}
                selectedRowDataItem={cmoConnectionModel.selectedRowDataItem}
                storeName="cmoConnection"
                contentData={cmoConnectionModel.registrationHistory}
            />
            <CmoRegCommentsModal
                isOpen={openCommentsModal}
                setOpen={setOpenCommentsModal}
                dataItem={clickedDataItem}
                onSuccessCb={() => {
                    dispatch(
                        getCmoConnectionsByClientId(params.id, {
                            isUpdateble: true,
                            arrayName: 'content',
                            idFieldName: 'cmoRegId',
                            id: clickedDataItem.cmoRegId,
                            fieldNameToChange: 'commentPresented',
                        }),
                    );
                }}
            />
            <CmoRegAlertsModal
                isOpen={openAlertsModal}
                setOpen={setOpenAlertsModal}
                dataItem={clickedDataItem}
            />
        </Box>
    );
}

export default ClientCmoConnectionTable;
