import userData from '@data/userData';
import Fetch from '@utils/Fetch';

const CONTROLLER_URL = userData.api.baseClientDealUrl();

function createClientDealPerfOrRHAPI(dealOptions, isPerformer = true) {
    const type = isPerformer ? '?type=PERFORMER' : '?type=RIGHTS_HOLDER';

    const url = `${CONTROLLER_URL}${type}`;

    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            ...(isPerformer && { claimMethod: dealOptions.claimMethod }),
            clientId: dealOptions.clientId,
            dealTerritories: dealOptions.dealTerritories,
            startDate: dealOptions.startDate,
            endDate: dealOptions.endDate,
        }),
    };

    return Fetch.fetchWithErrorToast({
        url,
        options,
        successMessage: 'Deal was successfully added',
        errorMessage: 'Failed to add deal',
        pendingMessage: 'Adding deal...',
    });
}

function createClientDealAPI(dealOptions) {
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            claimMethod: dealOptions.claimMethod,
            clientId: dealOptions.clientId,
            dealTerritories: dealOptions.dealTerritories,
            startDate: dealOptions.startDate,
            endDate: dealOptions.endDate,
        }),
    };

    return Fetch.fetchWithErrorToast({
        url: `${CONTROLLER_URL}?type=RIGHTS_HOLDER`,
        options,
        successMessage: 'Deal was successfully added',
        errorMessage: 'Failed to add deal',
        pendingMessage: 'Adding deal...',
    });
}

function editClientDealPerfOrRHAPI(dealOptions, isPerformer = true) {
    const type = isPerformer ? '?type=PERFORMER' : '?type=RIGHTS_HOLDER';

    const url = `${CONTROLLER_URL}${type}`;

    const options = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            ...(isPerformer && { claimMethod: dealOptions.claimMethod }),
            clientDealId: dealOptions.clientDealId,
            territories: dealOptions.dealTerritories || dealOptions.territories,
            endDate: dealOptions.endDate,
        }),
    };

    return Fetch.fetchWithErrorToast({
        url,
        options,
        successMessage: 'Deal was successfully edited',
        errorMessage: 'Failed to edit deal',
        pendingMessage: 'Editing deal...',
    });
}

function deleteClientDealPerfOrRHAPI(dealOptions, isPerformer = true) {
    const type = isPerformer ? '?type=PERFORMER' : '?type=RIGHTS_HOLDER';
    const url = `${CONTROLLER_URL}/${dealOptions.clientDealId}${type}`;
    const options = {
        method: 'DELETE',
    };

    return Fetch.fetchWithToasts({
        url,
        options,
        successMessage: 'Deal was successfully deleted',
        pendingMessage: 'Deleting deal...',
        errorMessage: 'Failed to delete deal',
    });
}

export {
    createClientDealPerfOrRHAPI,
    createClientDealAPI,
    editClientDealPerfOrRHAPI,
    deleteClientDealPerfOrRHAPI,
};
