import React from 'react';

import { ArrowDropDown, Close } from '@mui/icons-material';
import { Chip, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledChip = styled(Chip, {
    shouldForwardProp: (prop) => prop !== 'isSelected',
})(({ isSelected }) => ({
    '&.Mui-disabled': {
        background: '#ececee',
        pointerEvents: 'none',
    },
    color: '#1F1F21',
    borderRadius: '30px',
    border: isSelected ? '1px solid #C3CCDC' : '1px solid #9b9b9d',
    backgroundColor: isSelected ? '#C3CCDC' : '#ffffff',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '19px',
    textAlign: 'center',
    letterSpacing: '0.25px',
    textTransform: 'capitalize',
    marginRight: '8px',
    justifyContent: 'space-between',
    minHeight: 'auto',
    maxWidth: 'auto',
    boxSizing: 'border-box',
    '&:hover': {
        background: 'rgba(0,0,0,0.07) !important',
    },
    '&:active': {
        boxShadow: 'none',
        background: 'rgba(0,0,0,0.12)',
        border: '1px solid #C3CCDC',
    },
    position: 'relative',
    '.MuiChip-label': {
        display: 'flex',
        alignItems: 'center',
        gap: '6px',
        width: '100%',
        justifyContent: 'center',
        padding: 0,
    },
}));

const getLabelValue = (valuesList, filterField) => {
    if (valuesList.length < 1) return valuesList[0];
    switch (filterField) {
        case 'lastModified':
            return `${valuesList[0]} - ${valuesList[1]}`;
        default:
            return `${valuesList[0]} +${valuesList.length - 1} more`;
    }
};

const FilterChip = ({
    onClick,
    dataItem: { label, value = '', filterField, disabled, leftIcon = null, rightIcon = null },
    handleFilterCancelCb,
}) => {
    const handleFilterSelected = (evt) => {
        if (onClick) onClick(evt);
    };

    const handleCancelClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (handleFilterCancelCb) handleFilterCancelCb();
    };

    const valueRemaked = value?.includes(', ') ? value.split(', ') : value;
    const valueToShow = Array.isArray(valueRemaked)
        ? getLabelValue(valueRemaked, filterField)
        : valueRemaked;

    // Updated icons with marginLeft on the right icon
    const defaultRightIcon =
        value.length > 0 ? (
            <Close
                onClick={handleCancelClick}
                sx={{
                    marginLeft: '6px',
                }}
            />
        ) : (
            <ArrowDropDown
                sx={{
                    marginLeft: '6px',
                }}
            />
        );

    return (
        <StyledChip
            isSelected={value.length > 0}
            label={
                <Stack direction="row" alignItems="center">
                    {leftIcon && <span className="chip-icon-left">{leftIcon}</span>}
                    <span>{valueToShow || label}</span>
                    {(value.length > 0 || rightIcon) && defaultRightIcon}
                </Stack>
            }
            onClick={handleFilterSelected}
            disableRipple
            disabled={disabled}
            sx={{
                padding: '6px 12px',
                '.MuiChip-icon': {
                    height: '18px',
                    width: '18px',
                },
                '.chip-icon-left': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: '6px',
                },
            }}
        />
    );
};

export default FilterChip;
