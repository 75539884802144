import React, { useEffect } from 'react';

import { getPerformerById } from '@actions/performers';
import { BreadcrumbNavigation } from '@common/BreadcrumbNavigation';
import { items } from '@data/constants';
import { APP_ROUTES, BREADCRUMB_TEXTS } from '@utils/navigation';
import utils from '@utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import PerformerEditButtonGroup from './PerformerEditButtonGroup';
import PerformerInfoNavigation from './PerformerInfoNavigation';
import '@/App';

function PerformerPage() {
    const match = useRouteMatch();
    const dispatch = useDispatch();

    const isEditBtnVisible = useSelector((state) => state.fugaReducers.performers.isEditBtnVisible);

    const firstName = useSelector((state) => state.fugaReducers.performers.byClietnId.firstName);
    const lastName = useSelector((state) => state.fugaReducers.performers.byClietnId.lastName);

    useEffect(() => {
        if (match.params.id !== '-1') {
            dispatch(getPerformerById(match.params.id));
        }
        window.scrollTo(0, 0);
    }, []);

    const EditBtnSet = () => {
        return isEditBtnVisible && <PerformerEditButtonGroup />;
    };

    return (
        <>
            <div className="mainComponent">
                <BreadcrumbNavigation
                    items={[
                        {
                            href: APP_ROUTES.CLIENTS,
                            text: BREADCRUMB_TEXTS.CLIENTS,
                            disabled: true,
                        },
                        { href: APP_ROUTES.PERFORMERS, text: BREADCRUMB_TEXTS.PERFORMERS },
                        { href: '', text: `${firstName} ${lastName}` },
                    ]}
                    rightElement={
                        utils.checkItemRolesAndPermissions(items.btns.editMainDetailsBtnGroup) && (
                            <EditBtnSet />
                        )
                    }
                    pageName={`${firstName} ${lastName}`}
                />
                <PerformerInfoNavigation />
            </div>
        </>
    );
}

export default PerformerPage;
