import React from 'react';

import { renderSpotifyExtractChip } from '@common/customChips';
import { SpotifyExtractIcon } from '@images/svgComponents/SpotifyExtractIcon';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';

const SpotifyNotificationRow = ({ notification }) => {
    return (
        <>
            <Grid size={1}>
                <SpotifyExtractIcon
                    sx={{
                        fontSize: 24,
                    }}
                />
            </Grid>
            <Grid size={11} mb={1}>
                <Typography variant="body2" color="textSecondary">
                    {new Date(notification.date).toLocaleString()}
                </Typography>
                <Typography variant="body2">
                    <span style={{ fontWeight: 'bold' }}>{notification.user}</span> extracted{' '}
                    <span style={{ fontWeight: 'bold' }}>
                        {notification.processedAssetsNumber || 0}
                    </span>{' '}
                    tracks for{' '}
                    <span style={{ fontWeight: 'bold' }}>{notification?.clientName}</span>
                </Typography>
            </Grid>
            <Grid size={1}></Grid>
            <Grid
                size={10}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {renderSpotifyExtractChip(notification.status)}
            </Grid>
        </>
    );
};

export default SpotifyNotificationRow;
