import { initSSEConnection } from '@api/SSENotificationController';

import { changeStateByNestedKey } from './fugaActions';

export function initializeSSE({ handlePopupNotification = () => {} }) {
    return function (dispatch) {
        const handleNotificationUpdate = () => {
            dispatch(changeStateByNestedKey('notifications', 'hasUnreadNotifications', true));
        };

        return initSSEConnection({
            handleNotificationUpdate,
            handlePopupNotification,
        });
    };
}
