export const DEFAULT_TAB_STATEMENTS = 'statements';
export const DEFAULT_TAB_REPERTORIRES = 'repertoire';
export const DEFAULT_TAB_MAIN_DETAILS = 'details';
export const DEFAULT_TAB_CMOS = 'territoriesCmo';
export const EVENTS = 'events';
export const MAIN_DETAILS_EDIT_BTN_GROUP = 'editMainDetailsBtnGroup';
export const HEADER_CMO_LIST_BTN = 'cmosList';
export const HEADER_CLIENTS_LIST_BTN_GROUP = 'clietsListDropdown';
export const HEADER_USER_MGMT_BTN = 'userManagementBtn';
export const HEADER_NOTIFICATIONS_BTN = 'notificationsBtn';
export const HEADER_APPLICATIONS_BTN = 'applicationsIconBtn';
export const CLIENTS_LIST_PAGE = 'clientsList';
export const CLIENT_PAGE = 'clientPage';
export const MAINTENANCE_PAGE = 'maintenancePage';
export const USERS_PAGE = 'usersPage';
export const DEAL_START_DATE_COLUMN = 'dealStartDateColumn';
export const REPERTOIRE_END_DATE_COLUMN = 'repertoireEndtDateColumn';
export const INGESTED = 'ingested';
export const LAST_MODIFIED = 'lastModified';
export const OPTIONS = 'lastModified';
export const CLIENT_REGISTRATIONS_COLUMN = 'clientRegistrationsColumn';
export const CREATE_RH_BTN = 'createRhBtn';
export const TIER_TABLE_ITEM = 'tierTableItem';
export const FULL_LIST_COLLAPSIBLE_TABLE = 'fullStListPresent';
export const PERFORMER_REPERTOIRE_KEBAB_MENU = 'performerRepertoireKebabMenu';
export const PERFORMER_REPERTOIRE_ADD_FROM_SOURCE_BTN = 'performerRepertoireAddFromSourceBtn';
export const RH_REPERTOIRE_ADD_FROM_SOURCE_BTN = 'RhRepertoireAddFromSourceBtn';
export const RH_EXPORT_ALL_REPERTOIRE_BTN = 'rhExportAllRepertoireBtn';
export const PERFORMER_EXPORT_ALL_REPERTOIRE_BTN = 'performerExportAllRepertoireBtn';
export const PERMISSIONS_REPERTOIRE = 'CLIENT_REPERTOIRE_ACCESS'; // equivalent to BE value
export const PERMISSIONS_STATEMENTS = 'CLIENT_STATEMENTS_ACCESS'; // equivalent to BE value
export const MAINTENANCE_LOCAL_STORAGE_NAME = '@nr_maintenance';
export const STATUS_SUMMARY_CMO_FILTER = 'statusSummaryCmoFilter';
