import { getReportByIdAPI } from '@api/notificationsController';

import { changeStateByNestedKey } from './fugaActions';

function getReportById(id) {
    return async function (dispatch) {
        const resp = await getReportByIdAPI(id);

        dispatch(changeStateByNestedKey('repertoires', 'uploadErrors', resp.data || []));
    };
}

export { getReportById };
