import React, { useState } from 'react';

import { MoreVert } from '@mui/icons-material';
import { Menu, MenuItem, IconButton, Tooltip, styled, Box, Typography } from '@mui/material';

const MoreIconButton = styled(IconButton)({
    padding: '8px',
    '&:hover': {
        backgroundColor: 'unset',
    },
});

const MoreVertIcon = styled(MoreVert)({
    width: '18px',
    height: '18px',
    marginRight: 0,
    color: '#5F5F60',
});

const IconWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'center',
});

export default function CommonMoreDropDownButton({ menuItems }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpen = ({ currentTarget }) => {
        setAnchorEl(currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <MoreIconButton onClick={(e) => handleOpen(e)} disableRipple size="large">
                <MoreVertIcon />
            </MoreIconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
                {menuItems.map((el, ind) => (
                    <Tooltip
                        key={`${el.title}-${ind}`}
                        title={el?.tooltip?.text}
                        disableHoverListener={!el?.tooltip?.visible}
                    >
                        <MenuItem
                            onClick={() => {
                                el.onClickClb();
                                handleClose();
                            }}
                            disabled={el.isDisabled}
                            sx={{
                                display: 'flex',
                            }}
                        >
                            <IconWrapper>
                                {el.icon && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            marginRight: 2,
                                        }}
                                    >
                                        {el.icon}
                                    </Box>
                                )}
                                <Typography variant="body1">{el.title}</Typography>
                            </IconWrapper>
                        </MenuItem>
                    </Tooltip>
                ))}
            </Menu>
        </>
    );
}
