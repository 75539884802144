import React, { useState, useEffect } from 'react';

import { Button, DialogTitle, Popover, ClickAwayListener, Box } from '@mui/material';
import TextFieldWrapper from '@MuiWrappers/TextFieldWrapper';
import { useSelector } from 'react-redux';

function FilterMenuDialogWithInput({ isOpen, closeCb, title, dataItem, onConfirm, anchorEl }) {
    const [open, setOpen] = useState(isOpen);
    const [inputValue, setInputValue] = useState('');
    const [inputError, setInputError] = useState(false);
    const [inputErrorMessage, setInputErrorMessage] = useState('');
    const filtersModel = useSelector((state) => state.fugaReducers.filters);

    useEffect(() => {
        const preSetValue = filtersModel[dataItem.filterField];
        if (preSetValue) {
            setInputValue(
                typeof preSetValue === 'string' ? preSetValue : preSetValue.valuesToShow || '',
            );
        }
    }, [open]);

    useEffect(() => {
        if (filtersModel[dataItem.filterField] === '') {
            setInputValue('');
        }
    }, [filtersModel[dataItem.filterField]]);

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    const handleClose = () => {
        setOpen(false);
        closeCb(false);
        setInputValue('');
        setInputError(false);
        setInputErrorMessage('');
    };

    const handleConfirm = () => {
        const validationReason =
            typeof dataItem.validation === 'function' ? dataItem.validation(inputValue) : null;

        if (validationReason) {
            setInputError(true);
            if (typeof validationReason === 'string') {
                setInputErrorMessage(validationReason);
            }
        } else {
            onConfirm(inputValue);
            handleClose();
        }
    };

    const handleChange = (evt) => {
        setInputValue(evt.currentTarget.value);
    };

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <ClickAwayListener onClickAway={handleClose}>
                <Box
                    sx={{
                        padding: '24px 24px 8px',
                        width: '335px',
                        minHeight: '147px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                    }}
                >
                    {title && (
                        <DialogTitle onClose={handleClose} sx={{ padding: '19px 16px' }}>
                            {title}
                        </DialogTitle>
                    )}
                    <Box sx={{ width: '100%' }}>
                        <TextFieldWrapper
                            focused={true}
                            label={dataItem.inputLabel || ''}
                            placeholder={dataItem.placeholder || ''}
                            value={inputValue}
                            onChange={handleChange}
                            fullWidth
                            multiline
                            maxRows={4}
                            InputProps={{
                                sx: {
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    fontSize: '14px',
                                    lineHeight: '18px',
                                    letterSpacing: '0.25px',
                                    height: '100% !important',
                                },
                            }}
                            helperText={
                                inputError
                                    ? inputErrorMessage !== ''
                                        ? inputErrorMessage
                                        : 'Invalid data'
                                    : ''
                            }
                            error={inputError}
                        />
                    </Box>
                    <Box
                        sx={{
                            marginTop: '32px',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            '& > *': {
                                marginLeft: '24px',
                            },
                        }}
                    >
                        <Button
                            onClick={handleClose}
                            variant="light"
                            color="primary"
                            disableElevation
                        >
                            cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            disableElevation
                            onClick={handleConfirm}
                            disabled={!inputValue}
                        >
                            apply
                        </Button>
                    </Box>
                </Box>
            </ClickAwayListener>
        </Popover>
    );
}

export default FilterMenuDialogWithInput;
