import React, { useCallback, useEffect, useRef, useState } from 'react';

import { changeStateByNestedKey } from '@actions/fugaActions';
import { getStatementsByClientId } from '@actions/statements';
import { useInfoPopover } from '@hooks/useInfoPopover';
import { FiberManualRecord } from '@mui/icons-material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Popover, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import CommonCollapsibleTableGrid from './CommonCollapsibleTableGrid';
import OverflowTip from './OverflowTip';
import StatementsBtnGroup from './StatementsBtnGroup';

const HelpIcon = styled(InfoOutlinedIcon)(({ theme }) => ({
    width: '20px',
    height: '20px',
    display: 'flex',
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
}));

const useClasses = makeStyles(() => ({
    statusIcons: {
        width: '18px',
        height: '18px',
        marginRight: '21.25px',
    },
    flexHorizontal_f_s: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    activeIcon: {
        color: '#00A542',
    },
    inactiveIcon: {
        color: '#5F5F60',
    },
    pendingIcon: {
        color: '#FF8800',
    },
    titleMainColumn: {
        marginLeft: '8px',
    },
    topContainer: {
        padding: '0px 0 32px 0',
    },
    subtotalContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    amberDot: {
        color: '#FF8800',
        marginLeft: '8px',
        width: '8px',
        heigth: '8px',
    },
    subtotalPopover: {
        '& .MuiPopover-paper': {
            width: '444px',
            padding: '16px 16px',
            boxSizing: 'content-box',
            marginTop: '7px',
        },
    },
    hide: {
        visibility: 'hidden',
    },
    subtotalPopoverHeadings: {
        paddingTop: '8px',
        marginBottom: '6px',
    },
    subtotalPopoverRows: {
        paddingBottom: '8px',
        paddingTop: '2px',
    },
}));

const headers = [
    {
        title: 'Statement run',
        dataIndex: 'yearQuarterKey',
    },
    {
        title: 'Subtotal',
        dataIndex: 'invoiceSummary',
        conditionIndex: 'invoiceStatus',
        hasNumericData: true,
    },
    {
        title: 'Type',
        dataIndex: 'type',
    },
    {
        title: 'File name',
        dataIndex: 'filename',
    },
];

function StatementsTable() {
    const classes = useClasses();
    const dispatch = useDispatch();
    const params = useParams();
    const [tableData, setTableData] = useState({ content: [] });
    const statementsModel = useSelector((state) => state.fugaReducers.statements);

    const selectedFiles = useRef([]);

    const getPopoverContent = () => ({
        title: 'Subtotal',
        text: (
            <>
                <Typography variant="body2" className={classes.subtotalPopoverRows}>
                    Figure here is indicated earning for each period minus any charges such as
                    service and VAT, see files for full details.
                </Typography>
                <Typography variant="subtitle1" className={classes.subtotalPopoverHeadings}>
                    {'Icon ('}
                    <FiberManualRecord
                        className={classes.amberDot}
                        style={{ position: 'relative', top: '7px', margin: 0 }}
                    />
                    {') indicates the account is in recoupment'}
                </Typography>
                <Typography variant="body2" className={classes.subtotalPopoverRows}>
                    The account is still in recoupment for this period, see files for full details.
                </Typography>
            </>
        ),
    });
    const { anchorEl, popoverContent, handleInfoClick, handleInfoClose, isOpen } =
        useInfoPopover(getPopoverContent);

    const setSelectedFiles = (newValue) => {
        selectedFiles.current = newValue;
    };
    const excludedFiles = useRef([]);
    const setExcludedFiles = (newValue) => {
        excludedFiles.current = newValue;
    };

    useEffect(() => {
        dispatch(getStatementsByClientId(params.id || -1));
    }, []);

    // This update should be done in order to make nested rows sorting work
    useEffect(() => {
        const updatedContent = statementsModel.dataFromBE.content.map((el) => {
            el.isRowCollapsed = false;
            return el;
        });
        setTableData({ ...statementsModel.dataFromBE, content: updatedContent });
    }, [statementsModel.dataFromBE]);

    const getSpecificData = useCallback((headerItem, dataItem) => {
        const coulumnTitle = headerItem.title.toLowerCase();
        if (coulumnTitle.includes('subtotal')) {
            const content = dataItem[headerItem.dataIndex];
            const isAmberVisible = dataItem[headerItem.conditionIndex] !== 'post-recoupment';
            return (
                <div className={classes.subtotalContent}>
                    <Typography variant="body2">{content}</Typography>
                    {content && (
                        <Tooltip
                            title="Account unrecouped"
                            className={isAmberVisible ? '' : classes.hide}
                        >
                            <FiberManualRecord className={classes.amberDot} />
                        </Tooltip>
                    )}
                </div>
            );
        } else {
            const contentValue =
                dataItem[headerItem.dataIndex] === 'none' ? '' : dataItem[headerItem.dataIndex];
            return <OverflowTip>{contentValue}</OverflowTip>;
        }
    });

    const addHeaderSpecificAddition = useCallback((headerItem) => {
        const columnTitle = headerItem.title.toLowerCase();
        if (columnTitle.includes('subtotal')) {
            return (
                <div onClick={handleInfoClick}>
                    <HelpIcon />
                </div>
            );
        }
    });

    return (
        <div>
            <StatementsBtnGroup selectedFiles={selectedFiles.current} clientId={params.id} />
            <div className={classes.topContainer}>
                <CommonCollapsibleTableGrid
                    dataList={tableData}
                    headerList={headers}
                    loading={statementsModel.tableIsLoading}
                    cellDataCb={getSpecificData}
                    additionalHeaderContent={{
                        callBack: addHeaderSpecificAddition,
                        titleWithAddition: 'Subtotal',
                    }}
                    subRowsContainerFieldName="files"
                    titleWithExpandIcon="statement run"
                    sortPrefs={{ fullSort: true }}
                    preferences={{
                        loadDataByPageCb: {
                            dispatchFn: dispatch,
                            callbackFn: getStatementsByClientId,
                            argumentsList: [params.id || -1],
                        },
                    }}
                    selection={{
                        isSelectable: true,
                        dispatchFn: dispatch,
                        callbackFn: changeStateByNestedKey,
                        selectedRows: selectedFiles.current,
                        setSelectedRows: setSelectedFiles,
                        downloadItems: statementsModel.downloadItems,
                        storeName: 'statements',
                        excludedRows: excludedFiles.current,
                        setExcludedAssets: setExcludedFiles,
                        withCheckboxColumnNames: ['statement run', 'type'],
                    }}
                />
                <Popover
                    open={isOpen}
                    anchorEl={anchorEl}
                    onClose={handleInfoClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                    className={classes.subtotalPopover}
                >
                    <Typography variant="subtitle1" className={classes.subtotalPopoverHeadings}>
                        {popoverContent.title}
                    </Typography>
                    {popoverContent.text}
                </Popover>
            </div>
        </div>
    );
}

export default StatementsTable;
