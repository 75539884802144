import React, { useRef, useState } from 'react';

import { DeleteOutlineOutlined, EditOutlined, MoreVert as MoreVertIcon } from '@mui/icons-material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Menu, MenuItem, IconButton, Tooltip, Fade, Typography, styled } from '@mui/material';

const MainIconButton = styled(IconButton)({
    padding: '8px',
    '&:hover': {
        backgroundColor: 'unset',
    },
});

const StyledMoreVertIcon = styled(MoreVertIcon)({
    width: '18px',
    height: '18px',
    color: '#5F5F60',
    marginRight: 0,
});

const IconWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

export default function MoreDropDownButton({ callbacks, dataItem }) {
    const anchorRef = useRef(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const { onEditCB, onDeleteCB, onAlertCB } = callbacks;

    const handleOpen = () => {
        setMenuOpen(true);
    };

    const handleClose = () => {
        setMenuOpen(false);
    };

    return (
        <>
            <MainIconButton ref={anchorRef} onClick={handleOpen} disableRipple size="large">
                <StyledMoreVertIcon />
            </MainIconButton>
            <Menu
                anchorEl={anchorRef.current}
                open={menuOpen}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                TransitionComponent={Fade}
            >
                {onAlertCB && (
                    <MenuItem
                        key="alert"
                        onClick={() => {
                            onAlertCB(dataItem);
                            handleClose();
                        }}
                    >
                        <IconWrapper>
                            <WarningAmberIcon sx={{ marginRight: 1 }} />
                            <Typography variant="body1">Alerts</Typography>
                        </IconWrapper>
                    </MenuItem>
                )}
                {onEditCB && (
                    <MenuItem
                        key="edit"
                        onClick={() => {
                            onEditCB(dataItem);
                            handleClose();
                        }}
                    >
                        <IconWrapper>
                            <EditOutlined sx={{ marginRight: 1 }} />
                            <Typography variant="body1">
                                {dataItem.claimStatus === 'EXCLUSIVE_LICENSE_DEAL'
                                    ? 'View'
                                    : 'Edit'}
                            </Typography>
                        </IconWrapper>
                    </MenuItem>
                )}
                {onDeleteCB && (
                    <Tooltip
                        title={
                            dataItem.claimStatus !== 'EXCLUSIVE_LICENSE_DEAL'
                                ? 'This CMO registration cannot be deleted as registration action(s) already taken'
                                : 'This CMO Registration cannot be edited or deleted as it is under an exclusive license claim party'
                        }
                        disableHoverListener={!dataItem.lockedForDelete}
                    >
                        <div>
                            <MenuItem
                                key="delete"
                                onClick={() => {
                                    onDeleteCB();
                                    handleClose();
                                }}
                                disabled={dataItem.lockedForDelete}
                            >
                                <IconWrapper>
                                    <DeleteOutlineOutlined sx={{ marginRight: 1 }} />
                                    <Typography variant="body1">Delete</Typography>
                                </IconWrapper>
                            </MenuItem>
                        </div>
                    </Tooltip>
                )}
            </Menu>
        </>
    );
}
