import {
    DEFAULT_TAB_STATEMENTS,
    DEFAULT_TAB_REPERTORIRES,
    DEFAULT_TAB_MAIN_DETAILS,
    DEFAULT_TAB_CMOS,
    MAIN_DETAILS_EDIT_BTN_GROUP,
    HEADER_CMO_LIST_BTN,
    HEADER_CLIENTS_LIST_BTN_GROUP,
    HEADER_APPLICATIONS_BTN,
    HEADER_NOTIFICATIONS_BTN,
    CLIENTS_LIST_PAGE,
    CLIENT_PAGE,
    DEAL_START_DATE_COLUMN,
    CREATE_RH_BTN,
    TIER_TABLE_ITEM,
    HEADER_USER_MGMT_BTN,
    USERS_PAGE,
    FULL_LIST_COLLAPSIBLE_TABLE,
    PERFORMER_REPERTOIRE_KEBAB_MENU,
    PERFORMER_REPERTOIRE_ADD_FROM_SOURCE_BTN,
    RH_REPERTOIRE_ADD_FROM_SOURCE_BTN,
    REPERTOIRE_END_DATE_COLUMN,
    RH_EXPORT_ALL_REPERTOIRE_BTN,
    PERFORMER_EXPORT_ALL_REPERTOIRE_BTN,
    MAINTENANCE_PAGE,
    CLIENT_REGISTRATIONS_COLUMN,
    STATUS_SUMMARY_CMO_FILTER,
    INGESTED,
    LAST_MODIFIED,
    EVENTS,
    OPTIONS,
} from './globalConstants';

export const CREDS_AUTH_ERR_MSG = 'Username and/or password not recognised';
export const TOKEN_AUTH_ERR_MSG = 'Authorisation issue - please contact support';
export const RESET_PASSWORD_ERR_MSG = 'Reset password issue - please contact support';
export const USERNAME_ERR_MSG = 'Username not found';
export const EMAIL_SUCCESS_MSG =
    'Your request has been received, you will receive email with link shortly';
export const UPDATE_PASSWORD_ERR_MSG = 'Cannot reset password - please contact support';

export const rolesEnum = {
    admin: 'admin',
    internal: 'internal',
    rh_st: 'rh_st',
    per_st: 'per_st',
    rh_per_st: 'rh_per_st',
};

export const roles = [
    {
        name: rolesEnum.admin,
        permissions: [],
        allPermissions: true,
        landingPage: {
            url: '/cmo-list',
            toBeGenerated: false,
        },
    },
    {
        name: rolesEnum.internal,
        permissions: ['performersList', 'performerPage', CLIENTS_LIST_PAGE, CLIENT_PAGE],
        allPermissions: false,
        excludedItems: [
            'createPerformer',
            CREATE_RH_BTN,
            HEADER_USER_MGMT_BTN,
            PERFORMER_REPERTOIRE_ADD_FROM_SOURCE_BTN,
            RH_REPERTOIRE_ADD_FROM_SOURCE_BTN,
        ],
        landingPage: {
            url: '/cmo-list',
            toBeGenerated: false,
        },
    },
    {
        name: rolesEnum.rh_st,
        permissions: [CLIENT_PAGE, CLIENTS_LIST_PAGE],
        allPermissions: false,
        excludedItems: [
            HEADER_CMO_LIST_BTN,
            HEADER_CLIENTS_LIST_BTN_GROUP,
            HEADER_NOTIFICATIONS_BTN,
            HEADER_APPLICATIONS_BTN,
            DEFAULT_TAB_MAIN_DETAILS,
            DEFAULT_TAB_REPERTORIRES,
            DEFAULT_TAB_STATEMENTS,
            DEFAULT_TAB_CMOS,
            MAIN_DETAILS_EDIT_BTN_GROUP,
            TIER_TABLE_ITEM,
            DEAL_START_DATE_COLUMN,
            CREATE_RH_BTN,
            HEADER_USER_MGMT_BTN,
            FULL_LIST_COLLAPSIBLE_TABLE,
            PERFORMER_REPERTOIRE_KEBAB_MENU,
            RH_REPERTOIRE_ADD_FROM_SOURCE_BTN,
            REPERTOIRE_END_DATE_COLUMN,
            INGESTED,
            LAST_MODIFIED,
            OPTIONS,
            RH_EXPORT_ALL_REPERTOIRE_BTN,
            CLIENT_REGISTRATIONS_COLUMN,
            STATUS_SUMMARY_CMO_FILTER,
        ],
        landingPage: {
            url: '/clients',
            toBeGenerated: true,
        },
        defaultTab: DEFAULT_TAB_STATEMENTS,
    },
    {
        name: rolesEnum.per_st,
        permissions: ['performersList', 'performerPage'],
        allPermissions: false,
        excludedItems: [
            TIER_TABLE_ITEM,
            'createPerformer',
            DEFAULT_TAB_MAIN_DETAILS,
            DEFAULT_TAB_REPERTORIRES,
            DEFAULT_TAB_CMOS,
            DEFAULT_TAB_STATEMENTS,
            MAIN_DETAILS_EDIT_BTN_GROUP,
            HEADER_CMO_LIST_BTN,
            HEADER_CLIENTS_LIST_BTN_GROUP,
            HEADER_NOTIFICATIONS_BTN,
            HEADER_APPLICATIONS_BTN,
            DEAL_START_DATE_COLUMN,
            HEADER_USER_MGMT_BTN,
            FULL_LIST_COLLAPSIBLE_TABLE,
            PERFORMER_REPERTOIRE_KEBAB_MENU,
            PERFORMER_REPERTOIRE_ADD_FROM_SOURCE_BTN,
            PERFORMER_EXPORT_ALL_REPERTOIRE_BTN,
            INGESTED,
            OPTIONS,
            LAST_MODIFIED,
            CLIENT_REGISTRATIONS_COLUMN,
            STATUS_SUMMARY_CMO_FILTER,
        ],
        landingPage: {
            url: '/performers',
            toBeGenerated: false,
        },
        defaultTab: DEFAULT_TAB_STATEMENTS,
    },
    {
        name: rolesEnum.rh_per_st,
        permissions: ['performersList', 'performerPage', CLIENT_PAGE, CLIENTS_LIST_PAGE],
        allPermissions: false,
        excludedItems: [
            TIER_TABLE_ITEM,
            'createPerformer',
            DEFAULT_TAB_MAIN_DETAILS,
            DEFAULT_TAB_REPERTORIRES,
            DEFAULT_TAB_CMOS,
            DEFAULT_TAB_STATEMENTS,
            MAIN_DETAILS_EDIT_BTN_GROUP,
            HEADER_CMO_LIST_BTN,
            HEADER_NOTIFICATIONS_BTN,
            HEADER_APPLICATIONS_BTN,
            DEAL_START_DATE_COLUMN,
            CREATE_RH_BTN,
            HEADER_USER_MGMT_BTN,
            FULL_LIST_COLLAPSIBLE_TABLE,
            PERFORMER_REPERTOIRE_KEBAB_MENU,
            PERFORMER_REPERTOIRE_ADD_FROM_SOURCE_BTN,
            RH_REPERTOIRE_ADD_FROM_SOURCE_BTN,
            REPERTOIRE_END_DATE_COLUMN,
            INGESTED,
            OPTIONS,
            LAST_MODIFIED,
            RH_EXPORT_ALL_REPERTOIRE_BTN,
            PERFORMER_EXPORT_ALL_REPERTOIRE_BTN,
            CLIENT_REGISTRATIONS_COLUMN,
            STATUS_SUMMARY_CMO_FILTER,
        ],
        landingPage: {
            url: '/performers',
            toBeGenerated: false,
        },
        defaultTab: DEFAULT_TAB_STATEMENTS,
    },
];

export const pages = {
    cmoList: 'cmoList',
    clientPage: CLIENT_PAGE,
    performerPage: 'performerPage',
    cmoPage: 'cmoPage',
    clientsList: CLIENTS_LIST_PAGE,
    performersList: 'performersList',
    login: 'login',
    users: USERS_PAGE,
    maintenance: MAINTENANCE_PAGE,
    events: EVENTS,
};

export const items = {
    btns: {
        createPerformer: 'createPerformer',
        editMainDetailsBtnGroup: MAIN_DETAILS_EDIT_BTN_GROUP,
        cmoListHeader: HEADER_CMO_LIST_BTN,
        clientsListHeader: HEADER_CLIENTS_LIST_BTN_GROUP,
        notificationsHerader: HEADER_NOTIFICATIONS_BTN,
        applicationsBtnHeader: HEADER_APPLICATIONS_BTN,
        createRh: CREATE_RH_BTN,
        userManagementButton: HEADER_USER_MGMT_BTN,
        performerRepertoire: PERFORMER_REPERTOIRE_KEBAB_MENU,
        performerRepertoireAddFromSource: PERFORMER_REPERTOIRE_ADD_FROM_SOURCE_BTN,
        rhRepertoireAddFromSource: RH_REPERTOIRE_ADD_FROM_SOURCE_BTN,
        rhExportAllRepertoire: RH_EXPORT_ALL_REPERTOIRE_BTN,
        performerExportAllRepertoire: PERFORMER_EXPORT_ALL_REPERTOIRE_BTN,
        statusSummaryCmoFilter: STATUS_SUMMARY_CMO_FILTER,
    },
    tableColumns: {
        tier: TIER_TABLE_ITEM,
        dealStartDate: DEAL_START_DATE_COLUMN,
        fullListInCollapsibleTable: FULL_LIST_COLLAPSIBLE_TABLE,
        repertoireEndDate: REPERTOIRE_END_DATE_COLUMN,
        ingested: INGESTED,
        lastModified: LAST_MODIFIED,
        clientRegistrations: CLIENT_REGISTRATIONS_COLUMN,
        options: OPTIONS,
    },
    // Tab names currently aligned with names in PerformerInfoNavigation file panes array
    tabs: {
        mainDetails: DEFAULT_TAB_MAIN_DETAILS,
        repertories: DEFAULT_TAB_REPERTORIRES,
        statements: DEFAULT_TAB_STATEMENTS,
        cmos: DEFAULT_TAB_CMOS,
    },
};
