import React from 'react';

import StatusIconsWithTooltip from '@common/AlertsRow';
import { renderIngestionChip } from '@common/customChips';
import { Typography, Icon, IconButton, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { NOTIFICATION_STATUS } from '@utils/notificationUtils';

const IngestionNotificationRow = ({ notification }) => {
    return (
        <>
            <Grid size={1}>
                <Icon
                    sx={{
                        fontSize: 18,
                    }}
                >
                    database_upload
                </Icon>
            </Grid>
            <Grid size={11} mb={1}>
                <Typography variant="body2" color="textSecondary">
                    {new Date(notification.date).toLocaleString()}
                </Typography>
                <Typography variant="body2">
                    <span style={{ fontWeight: 'bold' }}>{notification.user}</span> ingested{' '}
                    <span style={{ fontWeight: 'bold' }}>{notification.filename}</span>
                </Typography>
            </Grid>
            <Grid size={1}></Grid>
            <Grid
                size={8}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {renderIngestionChip(notification.status)}
                <StatusIconsWithTooltip eventSummary={notification.eventSummary} />
            </Grid>
            {notification.status === NOTIFICATION_STATUS.IN_PROGRESS && (
                <Grid
                    size={3}
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <IconButton>
                        <CircularProgress
                            size="20px"
                            sx={{
                                color: '#666',
                            }}
                        />
                    </IconButton>
                </Grid>
            )}
        </>
    );
};

export default IngestionNotificationRow;
