import { useCallback, useRef, useEffect } from 'react';

import { getUnreadPopupNotifications, markAllAsReadAPI } from '@api/notificationsController';
import cerberusUtils from '@utils/cerberusUtils';
import { NOTIFICATION_BACKEND_TYPE } from '@utils/notificationUtils';
import { toast } from 'react-toastify';

const CMORegistrationStatus = {
    STARTED_PROCESSING:
        'Started processing client cmo registrations default set and Curve contract sync',
    STARTED_REGISTRATIONS: 'Started asset claim events registrations for client CMO connection',
    FINISHED: 'Finished processing client cmo registrations default set and Curve contract sync',
    FAILED: 'Failed processing client cmo registrations default set and Curve contract sync',
};

export const usePopupNotifications = () => {
    const toastIdsMap = useRef(new Map());

    const handlePopupNotification = useCallback((data) => {
        const { id, notificationMessage } = data;
        const message = notificationMessage.replace(/"/g, '');
        const existingToastId = toastIdsMap.current.get(id);

        switch (message) {
            case CMORegistrationStatus.STARTED_PROCESSING: {
                const newToastId = toast.loading('Started processing client cmo registrations...', {
                    closeButton: true,
                });
                toastIdsMap.current.set(id, newToastId);
                break;
            }
            case CMORegistrationStatus.STARTED_REGISTRATIONS:
            case CMORegistrationStatus.FINISHED:
            case CMORegistrationStatus.FAILED: {
                const toastConfig = {
                    render:
                        message === CMORegistrationStatus.FINISHED
                            ? 'Finished processing. Done!'
                            : message === CMORegistrationStatus.FAILED
                              ? 'Failed to process client cmo registrations. Please try again.'
                              : message,
                    type:
                        message === CMORegistrationStatus.FINISHED
                            ? 'success'
                            : message === CMORegistrationStatus.FAILED
                              ? 'error'
                              : 'info',
                    isLoading: false,
                    autoClose: 3000,
                    closeButton: true,
                };

                if (existingToastId) {
                    toast.update(existingToastId, toastConfig);
                    if (
                        message === CMORegistrationStatus.FINISHED ||
                        message === CMORegistrationStatus.FAILED
                    ) {
                        toastIdsMap.current.delete(id);
                    }
                } else {
                    const newToastId = toast(toastConfig.render, toastConfig);
                    toastIdsMap.current.set(id, newToastId);
                }
                break;
            }
            default:
                console.log('Unknown status:', message);
        }
    }, []);

    const checkUnreadPopupNotifications = useCallback(async () => {
        const { data } = await getUnreadPopupNotifications();

        await markAllAsReadAPI(NOTIFICATION_BACKEND_TYPE.POPUP);

        Object.entries(data.unreadNotifications).forEach(([key, value]) => {
            handlePopupNotification({
                id: parseInt(key),
                notificationMessage: value[value.length - 1],
            });
        });
    }, [handlePopupNotification]);

    useEffect(() => {
        const token = cerberusUtils.getCerberusToken();
        if (token) {
            checkUnreadPopupNotifications();
        } else {
            console.warn('No token available. Skipping unread notifications status check.');
        }
    }, []);

    return {
        handlePopupNotification,
    };
};
