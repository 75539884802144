import { useEffect } from 'react';

import { changeStateByNestedKey } from '@actions/fugaActions';
import { initializeSSE } from '@actions/notificationActions';
import { getUnreadStatusAPI } from '@api/notificationsController';
import cerberusUtils from '@utils/cerberusUtils';
import { useDispatch } from 'react-redux';

import { usePopupNotifications } from './usePopupNotifications';

export const useNotifications = () => {
    const dispatch = useDispatch();

    const { handlePopupNotification } = usePopupNotifications();

    const getUnreadNotificationsStatus = async () => {
        try {
            const { data } = await getUnreadStatusAPI();
            dispatch(
                changeStateByNestedKey(
                    'notifications',
                    'hasUnreadNotifications',
                    data.hasUnreadNotifications,
                ),
            );
        } catch (error) {
            console.error('Failed to get notifications', error);
        }
    };

    useEffect(() => {
        const cleanup = dispatch(
            initializeSSE({
                handlePopupNotification,
            }),
        );
        return () => {
            if (typeof cleanup === 'function') {
                cleanup();
            }
        };
    }, [dispatch]);

    useEffect(() => {
        const token = cerberusUtils.getCerberusToken();
        if (token) {
            getUnreadNotificationsStatus();
        } else {
            console.warn('No token available. Skipping unread notifications status check.');
        }
    }, []);
};
