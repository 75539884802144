import React from 'react';

import { ErrorOutline, WarningAmber, CheckCircleOutlined } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';

const StatusIconsWithTooltip = ({ eventSummary }) => {
    const tooltipText = `${eventSummary?.errorList?.length || 0} errors, ${eventSummary?.warningList?.length || 0} general data warnings, ${eventSummary?.cmoWarningList?.length || 0} CMO validation warning, ${eventSummary?.successList?.length || 0} valid`;

    return (
        <Tooltip title={tooltipText}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: 1,
                }}
            >
                {eventSummary?.errorList?.length > 0 && <ErrorOutline sx={{ color: '#F44139' }} />}
                {(eventSummary?.warningList?.length > 0 ||
                    eventSummary?.cmoWarningList?.length > 0) && (
                    <WarningAmber sx={{ color: '#FF8800' }} />
                )}
                {eventSummary?.successList?.length > 0 && (
                    <CheckCircleOutlined sx={{ color: '#00A542' }} />
                )}
            </Box>
        </Tooltip>
    );
};

export default StatusIconsWithTooltip;
