import React, { useState, useEffect } from 'react';

import { changeStateByNestedKey } from '@actions/fugaActions';
import TextFieldWrapper from '@common/MuiWrappers/TextFieldWrapper';
import Grid from '@mui/material/Grid2';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const ClientBillingContent = () => {
    const dispatch = useDispatch();
    const rhDataModel = useSelector((state) => state.fugaReducers.rhData);
    const { detailsData } = rhDataModel;
    const params = useParams();
    const areFieldsDisabled = useSelector(
        (state) => state.fugaReducers.clientPage.areFieldsDisabled,
    );

    useEffect(() => {
        if (!areFieldsDisabled) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [areFieldsDisabled, params.id]);

    useEffect(() => {
        dispatch(
            changeStateByNestedKey('rhData', 'mainDetailsInptValues', {
                ...rhDataModel.mainDetailsInptValues,
                bankAccNum: detailsData.bankAccountNumber,
                bankCity: detailsData.bankCity,
                iban: detailsData.bankIbanNumber,
                bankName: detailsData.bankName,
                bankAccPayee: detailsData.bankAccountPayee,
                bankCountry: detailsData.bankCountry,
                bankSwiftNumber: detailsData.bankSwiftNumber,
            }),
        );
    }, [rhDataModel.detailsData]);

    const [isDisabled, setIsDisabled] = useState(true);

    const handleInputChange = (event) => {
        dispatch(
            changeStateByNestedKey('rhData', 'mainDetailsInptValues', {
                ...rhDataModel.mainDetailsInptValues,
                [event.target.name]: event.currentTarget.value,
            }),
        );
    };

    return (
        <Grid columnSpacing={4} rowSpacing={1} container>
            <Grid size={6}>
                <TextFieldWrapper
                    label="Bank account number"
                    onChange={(evt) => handleInputChange(evt)}
                    name="bankAccNum"
                    type="text"
                    fullWidth
                    InputProps={{
                        readOnly: isDisabled,
                    }}
                    value={rhDataModel.mainDetailsInptValues.bankAccNum || ''}
                    inputProps={{ tabIndex: '20' }}
                />
            </Grid>
            <Grid size={6}>
                <TextFieldWrapper
                    inputProps={{ tabIndex: '21' }}
                    label="Bank account payee"
                    onChange={(evt) => handleInputChange(evt)}
                    name="bankAccPayee"
                    type="text"
                    fullWidth
                    InputProps={{
                        readOnly: isDisabled,
                    }}
                    value={rhDataModel.mainDetailsInptValues.bankAccPayee || ''}
                />
            </Grid>
            <Grid size={6}>
                <TextFieldWrapper
                    inputProps={{ tabIndex: '22' }}
                    label="Bank city"
                    onChange={(evt) => handleInputChange(evt)}
                    name="bankCity"
                    type="text"
                    fullWidth
                    InputProps={{
                        readOnly: isDisabled,
                    }}
                    value={rhDataModel.mainDetailsInptValues.bankCity || ''}
                />
            </Grid>
            <Grid size={6}>
                <TextFieldWrapper
                    inputProps={{ tabIndex: '23' }}
                    label="Bank country"
                    onChange={(evt) => handleInputChange(evt)}
                    name="bankCountry"
                    type="text"
                    value={rhDataModel.mainDetailsInptValues.bankCountry || ''}
                    fullWidth
                    InputProps={{
                        readOnly: isDisabled,
                    }}
                />
            </Grid>
            <Grid size={6}>
                <TextFieldWrapper
                    inputProps={{ tabIndex: '26' }}
                    label="Bank name"
                    onChange={(evt) => handleInputChange(evt)}
                    name="bankName"
                    type="text"
                    value={rhDataModel.mainDetailsInptValues.bankName || ''}
                    fullWidth
                    InputProps={{
                        readOnly: isDisabled,
                    }}
                />
            </Grid>
            <Grid size={6}>
                <TextFieldWrapper
                    inputProps={{ tabIndex: '25' }}
                    label="Bank Swift number"
                    onChange={(evt) => handleInputChange(evt)}
                    name="bankSwiftNumber"
                    type="text"
                    fullWidth
                    InputProps={{
                        readOnly: isDisabled,
                    }}
                    value={rhDataModel.mainDetailsInptValues.bankSwiftNumber || ''}
                />
            </Grid>
            <Grid size={6}>
                <TextFieldWrapper
                    inputProps={{ tabIndex: '24' }}
                    label="Bank IBAN number"
                    onChange={(evt) => handleInputChange(evt)}
                    name="iban"
                    type="text"
                    fullWidth
                    value={rhDataModel.mainDetailsInptValues.iban || ''}
                    InputProps={{
                        readOnly: isDisabled,
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default ClientBillingContent;
