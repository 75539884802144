import React, { useEffect, useState } from 'react';

import { changeStateByKey, changeStateByNestedKey } from '@actions/fugaActions';
import FilterChip from '@common/Filters/FilterChip';
import { FilterMenuDialogWithAutocompleteChips } from '@common/TableFilters/FilterMenuDialogWithAutocompleteChips';
import { FilterMenuDialogWithCheckboxes } from '@common/TableFilters/FilterMenuDialogWithCheckboxes';
import { FilterMenuDialogWithDatepicker } from '@common/TableFilters/FilterMenuDialogWithDatepicker';
import FilterMenuDialogWithInput from '@common/TableFilters/FilterMenuDialogWithInput';
import { Tabs, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FilterMenuTypes } from '@utils/enum';
import Helpers from '@utils/helper';
import { useDispatch, useSelector } from 'react-redux';

const ISRCS_SIZE_LIMIT = 5000;

const filterMenuComponents = {
    [FilterMenuTypes.INPUT]: FilterMenuDialogWithInput,
    [FilterMenuTypes.AUTOCOMPLETE]: FilterMenuDialogWithAutocompleteChips,
    [FilterMenuTypes.DEFAULT]: FilterMenuDialogWithCheckboxes,
    [FilterMenuTypes.DATEPICKER]: FilterMenuDialogWithDatepicker,
};

const useStyles = makeStyles(() => ({
    titile: {
        padding: '19px 16px',
    },
    content: {
        padding: '0 8px',
        width: '91%',
        height: 'auto',
    },
    flexHor: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    labels: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    clearAll: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    rootTabs: {
        '&.MuiTabs-root': {
            position: 'relative',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        '& .MuiTabScrollButton-root': {
            opacity: 'unset',
        },
        '& .MuiTabs-scrollButtons': {
            position: 'absolute',
            width: '32px',
            height: '32px',
            background: '#ffffff',
            borderRadius: '50%',
            boxShadow: '0px 3px 5px -1px',
        },
        '& .MuiTabs-scrollButtons:first-child': {
            left: '8px',
            zIndex: '1',
        },
        '& .MuiTabs-scrollButtons:nth-child(4)': {
            right: '8px',
        },
    },
}));

export function TableFilters({ tableReloadCb, menuItems, clearAllFilters }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [value, setValue] = useState(0);
    const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
    const [anchor, setAnchor] = useState(null);
    const [dataItemForMenu, setDataItemForMenu] = useState({});
    const filtersModel = useSelector((state) => state.fugaReducers.filters);

    const defaultMenuItems = [
        {
            label: 'ISRC',
            inputLabel: 'ISRCs',
            filterField: 'isrcs',
            filterNameInRequest: 'isrcs',
            placeholder: 'Enter or paste ISRCs separated by commas',
            type: FilterMenuTypes.INPUT,
            value: filtersModel?.isrcs?.valuesToShow || [],
            validation: (value) => {
                let reason = '';
                let isValid = true;
                let invalidSize = 0;

                if (value !== '') {
                    const isrcs = value.split(',');

                    if (isrcs.length > ISRCS_SIZE_LIMIT) {
                        reason = `Exceeds the limit of ${ISRCS_SIZE_LIMIT} ISRCs with ${isrcs.length} ISRCs`;
                    } else {
                        invalidSize = isrcs
                            .map((isrc) => isrc.trim())
                            .filter((isrc) => Helpers.validateISRC(isrc) === false).length;

                        isValid = invalidSize === 0;
                        if (!isValid) {
                            reason = `${invalidSize} ISRC(s) with invalid format`;
                        }
                    }
                }

                return reason;
            },
        },
    ];

    useEffect(() => {
        dispatch(changeStateByKey('filters', {}));
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleFilterClick = (event, dataItem) => {
        setAnchor(event.currentTarget);
        setDataItemForMenu(dataItem);
        setIsFilterMenuOpen(true);
    };

    const handleFilterCancel = (dataItem) => {
        setDataItemForMenu({});
        delete filtersModel[dataItem.filterNameInRequest];
        dispatch(changeStateByKey('filters', filtersModel));
        tableReloadCb();
        setIsFilterMenuOpen(false);
    };

    const onFilterConfirm = (value) => {
        let filterValue;
        if (value.valuesToShow) {
            filterValue = value;
        } else {
            filterValue = {
                valuesToShow: value,
                valuesToSend: value,
            };
        }
        dispatch(
            changeStateByNestedKey('filters', dataItemForMenu.filterNameInRequest, filterValue),
        );
        dispatch(changeStateByNestedKey('queryParams', 'page', 0));
        tableReloadCb();
    };

    const handleClearAll = () => {
        dispatch(changeStateByKey('filters', {}));
        clearAllFilters();
    };

    const FilterMenuComponent =
        filterMenuComponents[dataItemForMenu.type] || filterMenuComponents.default;

    const handleFilterChip = (filterItem) => {
        if (filterItem.type === FilterMenuTypes.STATIC) {
            return (e) => filterItem.handleFilterClick(e, filterItem);
        } else {
            return (e) => handleFilterClick(e, filterItem);
        }
    };

    // NOTE: the data format should be followed in order to set it in redux store correctly
    return (
        <div className={`${classes.content} ${classes.flexHor}`}>
            <div className={classes.flexHor}>
                <Tabs
                    className={classes.rootTabs}
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    TabIndicatorProps={{
                        style: {
                            display: 'none',
                        },
                    }}
                >
                    {(menuItems || defaultMenuItems).map((filterItem) => {
                        return (
                            <FilterChip
                                key={`filter-item-${filterItem.filterField}`}
                                onClick={handleFilterChip(filterItem)}
                                dataItem={filterItem}
                                handleFilterCancelCb={() => handleFilterCancel(filterItem)}
                            />
                        );
                    })}
                </Tabs>
                <Typography
                    color="primary"
                    variant="body2"
                    className={`${classes.labels} ${classes.clearAll}`}
                    onClick={handleClearAll}
                >
                    Clear all
                </Typography>
            </div>
            {dataItemForMenu.type !== FilterMenuTypes.STATIC && (
                <FilterMenuComponent
                    isOpen={isFilterMenuOpen}
                    closeCb={setIsFilterMenuOpen}
                    anchorEl={anchor}
                    dataItem={dataItemForMenu}
                    onConfirm={onFilterConfirm}
                    itemsList={dataItemForMenu.chackboxItemsList}
                    itemFieldName="name"
                    filtersModel={filtersModel}
                />
            )}
        </div>
    );
}
