import { format } from 'date-fns';
import { toast } from 'react-toastify';

export const NOTIFICATION_TYPES = {
    INFO: 'info',
    SUCCESS: 'success',
    ERROR: 'error',
    WARNING: 'warning',
};

export const NOTIFICATION_LIFETIME = {
    SHORT: 3000,
    MEDIUM: 5000,
    LONG: 8000,
};

export const NOTIFICATION_STATUS = {
    IN_PROGRESS: 'IN_PROGRESS',
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED',
    WARNING: 'WARNING',
};

export const NOTIFICATION_BACKEND_TYPE = {
    BASIC: 'BASIC',
    POPUP: 'POP_UP',
};

/**
 * Creates a notification message with consistent styling and behavior
 * @param {Object} params - Notification parameters
 * @param {string} params.message - Message to display
 * @param {string} params.type - Type of notification (info, success, error, warning)
 * @param {number} params.autoClose - Time in ms before auto-closing
 * @param {boolean} params.isLoading - Whether to show loading spinner
 * @returns {string|number} Toast ID for future updates
 */
export const createNotification = ({
    message,
    type = NOTIFICATION_TYPES.INFO,
    autoClose = NOTIFICATION_LIFETIME.MEDIUM,
    isLoading = false,
}) => {
    const toastConfig = {
        position: 'top-right',
        type,
        isLoading,
        autoClose: isLoading ? false : autoClose,
        hideProgressBar: isLoading,
        closeOnClick: !isLoading,
        pauseOnHover: true,
        draggable: !isLoading,
        className: `notification-toast-${type}`,
        closeButton: !isLoading,
    };

    return toast(message, toastConfig);
};

/**
 * Updates an existing notification
 * @param {string|number} toastId - ID of toast to update
 * @param {Object} params - Update parameters
 */
export const updateNotification = (
    toastId,
    {
        message,
        type = NOTIFICATION_TYPES.INFO,
        autoClose = NOTIFICATION_LIFETIME.MEDIUM,
        isLoading = false,
    },
) => {
    if (!toast.isActive(toastId)) return;

    toast.update(toastId, {
        render: message,
        type,
        isLoading,
        autoClose: isLoading ? false : autoClose,
        hideProgressBar: isLoading,
        closeOnClick: !isLoading,
        draggable: !isLoading,
        className: `notification-toast-${type}`,
        closeButton: !isLoading,
    });
};

/**
 * Formats notification timestamp
 * @param {string|Date} timestamp - Timestamp to format
 * @returns {string} Formatted timestamp
 */
export const formatNotificationTime = (timestamp) => {
    try {
        return format(new Date(timestamp), 'MMM d, yyyy HH:mm');
    } catch (error) {
        console.error('Error formatting notification timestamp:', error);
        return '';
    }
};
