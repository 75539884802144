import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';

import {
    Popover,
    Paper,
    Button,
    ClickAwayListener,
    List,
    ListSubheader,
    ListItemButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import TextFieldWrapper from '@MuiWrappers/TextFieldWrapper';
import Utils from '@utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

const StyledPopover = styled(Popover)(() => ({
    '& .lookup-content': {
        padding: '8px 0 8px 16px',
    },
    '& .input-container': {
        display: 'flex',
        flexDirection: 'column',
        width: '334px',
        height: '383px',
    },
    '& .suggestions-list': {
        minHeight: 80,
        maxHeight: 300,
        overflowY: 'auto',
    },
    '& .list-item': {
        paddingTop: '20px',
        paddingBottom: '18px',
    },
    '& .button-container': {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '8px',
    },
}));

const Lookup = ({
    open,
    onClose,
    searchAction,
    suggestionSelector,
    dataSelector,
    navigateTo,
    createNewItemData,
    setIsInSaveNewModeAction,
    setSelectedIndexAction,
    setDataAction,
    staticSearch = false,
    itemName,
    anchorPosition = { top: 260, left: window.innerWidth - 200 },
}) => {
    const dispatch = useDispatch();
    const inputRef = useRef(null);
    const [areSuggestedItemsShown, setAreSuggestedItemsShown] = useState(false);
    const [redirectToPage, setRedirectToPage] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [abortController, setAbortController] = useState(null);

    const suggestions = useSelector(suggestionSelector);
    const dataModel = useSelector(dataSelector);

    useEffect(() => {
        if (!searchTerm) {
            setAreSuggestedItemsShown(false);
            return;
        }

        if (abortController) {
            abortController.abort();
        }

        const controller = new AbortController();
        setAbortController(controller);

        const debouncedSearch = Utils.debounce(async (term) => {
            try {
                Utils.handleAction(searchAction, dispatch, term, controller.signal);
            } catch (err) {
                if (err.name !== 'AbortError') {
                    console.error('Error during search:', err);
                }
            }
        }, 300);

        debouncedSearch(searchTerm);

        return () => {
            controller.abort();
        };
    }, [searchTerm, dispatch, searchAction]);

    useEffect(() => {
        if (staticSearch) {
            staticSearch();
        }
    }, []);

    useEffect(() => {
        if (suggestions && suggestions.length) {
            setAreSuggestedItemsShown(true);
        } else {
            setAreSuggestedItemsShown(false);
        }
    }, [suggestions]);

    useLayoutEffect(() => {
        if (open) {
            setTimeout(() => {
                inputRef.current && inputRef.current.focus();
            }, 50);
        }
    }, [open]);

    const handleAddNew = () => {
        dispatch(setIsInSaveNewModeAction(true));
        if ((!suggestions || suggestions.length === 0) && searchTerm) {
            const newItemData = createNewItemData(searchTerm);
            dispatch(setDataAction(newItemData));
            setRedirectToPage(true);
        }
        if (dataModel.selectedIndex >= 0) {
            setRedirectToPage(true);
        }
    };

    const onSelectOption = (item, index) => {
        if (!open) return;
        dispatch(setSelectedIndexAction(index));
        dispatch(setDataAction(item));
        handleAddNew();
    };

    return (
        <StyledPopover
            open={open}
            anchorReference="anchorPosition"
            anchorPosition={anchorPosition}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <ClickAwayListener onClickAway={onClose}>
                <Paper className="lookup-content">
                    <div className="input-container">
                        <TextFieldWrapper
                            placeholder="Type to search ..."
                            inputProps={{ autoComplete: 'off' }}
                            inputRef={inputRef}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            style={{ marginRight: '16px', minHeight: 0 }}
                        />
                        <List className="suggestions-list">
                            {areSuggestedItemsShown && (
                                <ListSubheader disableSticky>Suggested</ListSubheader>
                            )}
                            {areSuggestedItemsShown &&
                                suggestions.map((item, index) => (
                                    <ListItemButton
                                        className="list-item"
                                        key={item.id}
                                        selected={dataModel.selectedIndex === index}
                                        onClick={() => onSelectOption(item, index)}
                                    >
                                        {item[itemName]}
                                    </ListItemButton>
                                ))}
                            {!areSuggestedItemsShown &&
                                searchTerm.length > 0 &&
                                suggestions.length === 0 && (
                                    <ListSubheader>Not found</ListSubheader>
                                )}
                        </List>
                    </div>
                    <div className="button-container">
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handleAddNew}
                            disableElevation
                        >
                            ADD AS NEW
                        </Button>
                        {redirectToPage && <Redirect to={navigateTo} />}
                        <Button color="primary" onClick={onClose} disableElevation>
                            CANCEL
                        </Button>
                    </div>
                </Paper>
            </ClickAwayListener>
        </StyledPopover>
    );
};

export default Lookup;
