import React from 'react';

import { downloadExportFileAPI } from '@api/asyncEventsController';
import { renderExportChip } from '@common/customChips';
import OverflowTip from '@common/OverflowTip';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { CircularProgress, IconButton, Typography, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { format, parseISO } from 'date-fns';

const FlexCenter = styled('div')({
    display: 'flex',
    justifyContent: 'center',
});

const ExportRow = ({ headerItem, dataItem }) => {
    const columnTitle = headerItem.title.toLowerCase();

    const downloadExportFile = async () => {
        await downloadExportFileAPI(dataItem.fileId);
    };

    if (columnTitle.includes('description')) {
        return (
            <Typography variant="body2" component="span" noWrap>
                <strong>{dataItem.eventSummary?.numberOfProcessedAssets || 0}</strong> tracks to{' '}
                <strong>{dataItem.exportType}</strong> for{' '}
                <strong>
                    {dataItem.eventSettings.exportSettings.cmoName || dataItem.eventSettings.cmoId}
                </strong>
            </Typography>
        );
    } else if (columnTitle === 'event') {
        return (
            <span style={{ display: 'flex', alignItems: 'center' }}>
                <DownloadForOfflineOutlinedIcon />
                <Typography variant="body2" component="span" noWrap sx={{ marginLeft: '9px' }}>
                    {dataItem[headerItem.dataIndex]}
                </Typography>
            </span>
        );
    } else if (columnTitle === 'date') {
        return (
            <Typography variant="body2" component="span" noWrap>
                {format(parseISO(dataItem[headerItem.dataIndex]), 'dd/MM/yyyy HH:mm')}
            </Typography>
        );
    } else if (headerItem.dataIndex === 'eventStatus') {
        return renderExportChip(dataItem[headerItem.dataIndex]);
    } else if (columnTitle === '') {
        const status = dataItem.eventStatus;

        switch (status) {
            case 'IN_PROGRESS':
                return (
                    <FlexCenter>
                        <IconButton>
                            <CircularProgress
                                size="20px"
                                sx={{
                                    color: '#666',
                                }}
                            />
                        </IconButton>
                    </FlexCenter>
                );
            case 'SUCCESS':
                return (
                    <FlexCenter>
                        <IconButton onClick={downloadExportFile}>
                            <GetAppOutlinedIcon size="20px" />
                        </IconButton>
                    </FlexCenter>
                );
            default:
                return (
                    <FlexCenter>
                        <Tooltip title={dataItem.errorCode || 'error'}>
                            <IconButton>
                                <InfoOutlinedIcon size="20px" />
                            </IconButton>
                        </Tooltip>
                    </FlexCenter>
                );
        }
    } else {
        return <OverflowTip>{dataItem[headerItem.dataIndex] || ''}</OverflowTip>;
    }
};

export default ExportRow;
