import React, { useEffect } from 'react';

import { getEventsData } from '@actions/eventsActions';
import { changeStateByNestedKey } from '@actions/fugaActions';
import { BreadcrumbNavigation } from '@common/BreadcrumbNavigation';
import CommonSearchInput from '@common/CommonSearchInput';
import CommonTableGrid from '@common/CommonTable/CommonTableGrid';
import WarningDialog from '@common/WarningDialog';
import useTableData from '@hooks/useTableData';
import Grid from '@mui/material/Grid2';
import EventsPageFiltersWrapper from '@pages/EventsPage/EventsTableFilters';
import EventsTableRow from '@pages/EventsPage/EventsTableRow';
import { PageContainer } from '@styling/sharedComponents';
import { APP_ROUTES, BREADCRUMB_TEXTS } from '@utils/navigation';
import { useDispatch, useSelector } from 'react-redux';

const getHeaders = () => [
    {
        title: 'Event',
        dataIndex: 'eventType',
    },
    {
        title: 'Description',
        dataIndex: 'eventSummary',
        customStyle: {
            minWidth: '315px',
        },
        disableSorting: true,
    },
    {
        title: 'User',
        dataIndex: 'adminUsername',
    },
    {
        title: 'Date',
        dataIndex: 'eventDateTime',
    },
    {
        title: '',
        dataIndex: 'eventStatus',
        customStyle: {
            minWidth: '200px',
        },
    },
    {
        title: '',
        dataIndex: '',
    },
];

export default function EventsPage() {
    const queryParams = useSelector((state) => state.fugaReducers.queryParams);
    const filtersModel = useSelector((state) => state.fugaReducers.filters);
    const errorDialogIsOpen = useSelector(
        (state) => state.fugaReducers.repertoires.errorDialogIsOpen,
    );
    const dispatch = useDispatch();

    const pageModelName = 'eventsPage';

    const { fetchTableData, handlePaginationAndSearch, handleSearch, clearSearch, pageModel } =
        useTableData({
            fetchFunction: getEventsData,
            pageModelName,
        });

    useEffect(() => {
        dispatch(
            changeStateByNestedKey('queryParams', 'sort', {
                value: 'eventDateTime',
                direction: 'DESC',
            }),
        );

        fetchTableData(false, {
            value: 'eventDateTime',
            direction: 'DESC',
        });
    }, []);

    useEffect(() => {
        fetchTableData();
    }, [filtersModel?.asyncEventType]);

    return (
        <PageContainer container>
            <Grid size={12}>
                <BreadcrumbNavigation
                    items={[{ href: APP_ROUTES.EVENTS, text: BREADCRUMB_TEXTS.EVENTS }]}
                    pageName="Events"
                />
            </Grid>
            <Grid
                size={12}
                sx={{
                    textAlign: 'left',
                }}
                mb={2}
            >
                <CommonSearchInput
                    placeHolderText="Search Events"
                    onSearch={handleSearch}
                    term={''}
                    onClearCb={clearSearch}
                />
            </Grid>
            <Grid size={12}>
                <EventsPageFiltersWrapper tableReloadCb={fetchTableData} />
            </Grid>
            <Grid size={12}>
                <CommonTableGrid
                    dataList={pageModel.dataFromBE}
                    headerList={getHeaders()}
                    loading={pageModel.tableIsLoading}
                    sortPrefs={{
                        storeName: 'queryParams',
                        storeSubName: 'sort',
                        value: queryParams.sort,
                    }}
                    cellDataCb={(headerItem, dataItem) => (
                        <EventsTableRow
                            headerItem={headerItem}
                            dataItem={dataItem}
                            pageModelName={pageModelName}
                        />
                    )}
                    preferences={{
                        loadDataByPageCb: {
                            dispatchFn: dispatch,
                            callbackFn: handlePaginationAndSearch,
                            argumentsList: [{}],
                            noDispatch: true,
                        },
                    }}
                    isInSubModal={false}
                    paginationBE
                    trackPagination={{
                        isPaginationTrackingOn: true,
                        paginationTrackingCB: () => {},
                    }}
                    searchTerm={pageModel.searchTerm}
                    selection={{
                        isSelectable: false,
                    }}
                />
            </Grid>
            {errorDialogIsOpen && <WarningDialog isOpen={errorDialogIsOpen} />}
        </PageContainer>
    );
}
