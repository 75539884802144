import React, { useEffect } from 'react';

import { getRhClientInfoById } from '@actions/rightHolders';
import { BreadcrumbNavigation } from '@common/BreadcrumbNavigation';
import { items } from '@data/constants';
import { APP_ROUTES, BREADCRUMB_TEXTS } from '@utils/navigation';
import utils from '@utils/utils';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import ClientEditButtonGroup from './ClientEditButtonGroup';
import ClientInfoNavigation from './ClientInfoNavigation';

function ClientPage() {
    const dispatch = useDispatch();
    const clientPageModel = useSelector((state) => state.fugaReducers.clientPage);
    const rhDataModel = useSelector((state) => state.fugaReducers.rhData);
    const params = useParams();
    const {
        detailsData: { legalCompanyName },
    } = rhDataModel;

    useEffect(() => {
        if (params.id !== '-1') {
            dispatch(getRhClientInfoById(params.id));
        }
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="mainComponent">
                <BreadcrumbNavigation
                    items={[
                        {
                            href: APP_ROUTES.CLIENTS,
                            text: BREADCRUMB_TEXTS.CLIENTS,
                            disabled: true,
                        },
                        { href: APP_ROUTES.CLIENTS, text: BREADCRUMB_TEXTS.RIGHTS_HOLDERS },
                        { href: '', text: legalCompanyName },
                    ]}
                    rightElement={
                        utils.checkItemRolesAndPermissions(items.btns.editMainDetailsBtnGroup) &&
                        clientPageModel.isEditBtnVisible ? (
                            <ClientEditButtonGroup />
                        ) : (
                            <></>
                        )
                    }
                    pageName={legalCompanyName}
                />
                <ClientInfoNavigation />
            </div>
        </>
    );
}

export default connect((r) => r)(ClientPage);
