const NotificationStatus = {
    IN_PROGRESS: 'IN_PROGRESS',
    COMPLETED: 'COMPLETED',
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED',
    SERVER_ERROR: 'SERVER_ERROR',
};

const NotificationTypes = {
    INGESTION: 'INGESTION',
    EXPORT: 'EXPORT',
    SPOTIFY: 'SPOTIFY',
};

const SSENotificationTypes = {
    BASIC: 'NOTIFICATION_UPDATE',
    POPUP: 'POP_UP_NOTIFICATION',
};

export { NotificationStatus, NotificationTypes, SSENotificationTypes };
