import React, { useEffect } from 'react';

import { getAssetClaimByAssetClaimId } from '@actions/assetsClaimEvent';
import { changeStateByNestedKey } from '@actions/fugaActions';
import { getStatusHistoryByCmoRegIdAPI } from '@api/cmoController';
import { ArrowForward, ErrorOutline, WarningAmber } from '@mui/icons-material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Popover, Typography, Paper, Box } from '@mui/material';
import { format } from 'date-fns';
import { useDispatch } from 'react-redux';

export default function StatusHistoryModal({
    anchorEl,
    onClose,
    isOpen,
    selectedRowDataItem,
    storeName,
    contentData = [],
    isAsset = false,
}) {
    const dispatch = useDispatch();

    const handleOpen = async () => {
        if (isOpen) {
            if (isAsset) {
                dispatch(getAssetClaimByAssetClaimId(selectedRowDataItem.assetClaimId, storeName));
            } else {
                const resp = await getStatusHistoryByCmoRegIdAPI(selectedRowDataItem.cmoRegId);
                dispatch(
                    changeStateByNestedKey(storeName, 'registrationHistory', resp?.data || []),
                );
            }
        }
    };

    useEffect(() => {
        handleOpen();
    }, [isOpen]);

    const getBeforeValue = (data) => {
        if (data.name) return data.oldValue;
        if (data.category) return data.status.split('_').join(' ');
        return data.previousStatus.split('_').join(' ');
    };

    const getAfterValue = (data) => {
        if (data.name) return data.newValue;
        else if (data.category) return data.status.split('_').join(' ');
        else return data.currentStatus.split('_').join(' ');
    };

    const dealTypesMapping = [
        { beKey: 'END_DATE_UPDATED', textToShow: 'End date' },
        { beKey: 'CLIENT_DEAL_END_DATE_UPDATED', textToShow: 'Deal end date' },
        { beKey: 'CLIENT_DEAL_TERRITORY_UPDATED', textToShow: 'a country of residence' },
    ];

    const getStatusActionMsg = (data) => {
        if (data.category) return '';
        const beActionName = data.name;
        return dealTypesMapping.find((el) => el.beKey === beActionName)?.textToShow || 'the status';
    };

    const categoriesList = [
        {
            title: 'Internal action',
            beValue: 'INTERNAL',
            icon: <ErrorOutline sx={{ fill: 'rgba(0, 0, 0, 0.60)' }} />,
        },
        {
            title: 'Client action',
            beValue: 'CLIENT',
            icon: <WarningAmber styles={{ '& svg': { fill: 'rgba(0, 0, 0, 0.60)' } }} />,
        },
        {
            title: 'CMO action',
            beValue: 'CMO',
            icon: <InfoOutlinedIcon styles={{ '& svg': { fill: 'rgba(0, 0, 0, 0.60)' } }} />,
        },
        {
            title: 'Dispute action',
            beValue: 'DISPUTE',
            icon: <ErrorOutline />,
            color: '#F44139',
        },
        {
            title: 'Evidence action',
            beValue: 'EVIDENCE_REQUIRED',
            icon: <WarningAmber styles={{ '& svg': { fill: 'rgba(0, 0, 0, 0.60)' } }} />,
            color: '#FF8800',
        },
        {
            title: 'Action',
            beValue: 'ACTION_REQUIRED',
            icon: <InfoOutlinedIcon styles={{ '& svg': { fill: 'rgba(0, 0, 0, 0.60)' } }} />,
            color: '#45A2DD',
        },
        {
            title: 'CMO Validation',
            beValue: 'CMO_VALIDATION',
            icon: <WarningAmber styles={{ '& svg': { fill: 'rgba(0, 0, 0, 0.60)' } }} />,
            color: '#FF8800',
        },
    ];

    return (
        <Popover
            open={isOpen}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            sx={{
                maxHeight: '488px',
            }}
        >
            <Typography
                variant="h6"
                sx={{
                    backgroundColor: '#FFFFFF',
                    position: 'sticky',
                    top: 0,
                    zIndex: 100,
                    p: 2,
                }}
            >
                Status history
            </Typography>
            <Paper>
                {contentData?.map((data) => (
                    <Box key={data.date} sx={{ width: '444px', p: '16px 0' }}>
                        <Box sx={{ p: '4px 16px', display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body2">
                                <b>{data.user}</b> changed <b>{getStatusActionMsg(data)}</b>
                            </Typography>
                            <Typography
                                variant="caption"
                                sx={{ ml: '8px', color: 'rgba(0, 0, 0, 0.87)' }}
                            >
                                {`${format(new Date(data.date), 'd/M/yyyy HH:mm')}`}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                p: '4px 16px',
                                display: data.category ? 'flex' : 'block',
                                flexDirection: data.category ? 'column' : 'unset',
                                alignItems: data.category ? 'flex-start' : 'unset',
                            }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant="body1" color="rgba(0, 0, 0, 0.6)">
                                    {getBeforeValue(data)}
                                </Typography>
                                <ArrowForward
                                    sx={{
                                        color: 'rgba(0, 0, 0, 0.60)',
                                        marginX: 1,
                                    }}
                                />
                                {data.category ? (
                                    <Box sx={{ display: 'flex' }}>
                                        <Box
                                            sx={{
                                                mr: 1,
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            {
                                                categoriesList.find(
                                                    (el) => el.beValue === data.category,
                                                )?.icon
                                            }
                                        </Box>
                                        <Typography
                                            variant="subtitle2"
                                            sx={{ color: 'rgba(0, 0, 0, 0.60)' }}
                                        >
                                            {`${
                                                categoriesList.find(
                                                    (el) => el.beValue === data.category,
                                                )?.title || ''
                                            } ${data.state === 'RAISED' ? 'added' : 'resolved'}`}
                                        </Typography>
                                    </Box>
                                ) : (
                                    <Typography variant="body1" color="rgba(0, 0, 0, 0.6)">
                                        {getAfterValue(data)}
                                    </Typography>
                                )}
                            </Box>
                            {data.category && (
                                <Typography
                                    variant="subtitle2"
                                    sx={{ mt: '9px', color: 'rgba(0, 0, 0, 0.60)' }}
                                >
                                    {data.message}
                                </Typography>
                            )}
                        </Box>
                    </Box>
                ))}
            </Paper>
        </Popover>
    );
}
