import React, { useEffect } from 'react';

import { changeStateByNestedKey } from '@actions/fugaActions';
import { getReportById } from '@actions/reports';
import {
    Check as CheckIcon,
    ReportProblemOutlined as WarningIcon,
    ErrorOutlineOutlined as ErrorIcon,
} from '@mui/icons-material';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
    titile: {
        padding: '19px 16px',
    },
    content: {
        padding: '10px 16px',
    },
    leftFlex: {
        justifyContent: 'start',
        flexDirection: 'row',
    },
    icons: {
        height: '20px',
        width: '20px',
        marginRight: '8px',
    },
    warnIcon: {
        color: '#FF8800',
    },
    checkIcon: {
        color: '#00A542',
    },
    errIcon: {
        color: '#F44139',
    },
    infoRow: {
        padding: '8px 16px',
        marginLeft: '13px',
    },
    infoSection: {
        marginBottom: '19px',
    },
    downloadIcon: {
        marginRight: '10px',
    },
}));

export default function WarningDialog() {
    const dispatch = useDispatch();
    const dialogContent = useSelector((state) => state.fugaReducers.repertoires.uploadErrors);
    const errorDialogIsOpen = useSelector(
        (state) => state.fugaReducers.repertoires.errorDialogIsOpen,
    );
    const reportId = useSelector((state) => state.fugaReducers.repertoires.reportId);
    const classes = useStyles();

    useEffect(() => {
        dispatch(getReportById(reportId));
    }, []);

    const handleClose = () => {
        dispatch(changeStateByNestedKey('repertoires', 'errorDialogIsOpen', false));
    };

    const getMsgCount = (msgList, isSuccess) => {
        let count = 0;
        if (msgList) {
            msgList.forEach((el) => {
                if (isSuccess && el.messageText.toLowerCase().includes('Assets')) {
                    count += el.messageRows.split(' ').length;
                } else {
                    count += el.messageRows.split(' ').length;
                }
            });
        } else {
            count = 0;
        }
        return count;
    };

    const isPlural = (msgList) => {
        return getMsgCount(msgList) === 1 ? '' : 's';
    };

    return (
        <Dialog onClose={handleClose} open={errorDialogIsOpen} maxWidth="md">
            <DialogTitle onClose={handleClose} className={`${classes.titile}`}>
                {`${getMsgCount(dialogContent.errors)} error${isPlural(dialogContent.errors)}, 
                    ${getMsgCount(dialogContent.warnings)} general data warning${isPlural(
                        dialogContent.warnings,
                    )}, 
                    ${getMsgCount(
                        dialogContent.cmoValidationWarnings,
                    )} CMO validation warning${isPlural(dialogContent.cmoValidationWarnings)}, 
                    ${getMsgCount(dialogContent.success, true)} successful ingestion${isPlural(
                        dialogContent.success,
                    )}`}
            </DialogTitle>
            <DialogContent className={`${classes.content}`}>
                <div className={`${classes.infoSection}`}>
                    <div className={`flex-left ${classes.leftFlex}`}>
                        <ErrorIcon className={`${classes.icons} ${classes.errIcon}`} />
                        <Typography variant="subtitle2">
                            Errors ({dialogContent.errors ? dialogContent.errors.length : 0})
                        </Typography>
                    </div>
                    {dialogContent.errors &&
                        dialogContent.errors.map((err, ind) => {
                            return (
                                <div
                                    key={`${ind}-${
                                        err.messageText ? err.messageText.slice(0, 5) : ''
                                    }`}
                                    className={`${classes.infoRow}`}
                                >
                                    <Typography variant="body2">{err.messageText}</Typography>
                                    <Typography variant="caption">
                                        Rows: {err.messageRows}
                                    </Typography>
                                </div>
                            );
                        })}
                </div>
                <div className={`${classes.infoSection}`}>
                    <div className={`flex-left ${classes.leftFlex}`}>
                        <WarningIcon className={`${classes.icons} ${classes.warnIcon}`} />
                        <Typography variant="subtitle2">
                            General Data warnings (
                            {dialogContent.warnings ? dialogContent.warnings.length : 0})
                        </Typography>
                    </div>
                    {dialogContent.warnings &&
                        dialogContent.warnings.map((warn) => {
                            return (
                                <div
                                    key={warn.messageText.slice(0, 5)}
                                    className={`${classes.infoRow}`}
                                >
                                    <Typography variant="body2">{warn.messageText}</Typography>
                                    <Typography variant="caption">
                                        Rows: {warn.messageRows}
                                    </Typography>
                                </div>
                            );
                        })}
                </div>
                <div className={`${classes.infoSection}`}>
                    <div className={`flex-left ${classes.leftFlex}`}>
                        <WarningIcon className={`${classes.icons} ${classes.warnIcon}`} />
                        <Typography variant="subtitle2">
                            CMO validation warnings (
                            {dialogContent.cmoValidationWarnings
                                ? dialogContent.cmoValidationWarnings.length
                                : 0}
                            )
                        </Typography>
                    </div>
                    {dialogContent.cmoValidationWarnings &&
                        dialogContent.cmoValidationWarnings.map((CmoWarn) => {
                            return (
                                <div
                                    key={CmoWarn.messageText.slice(0, 5)}
                                    className={`${classes.infoRow}`}
                                >
                                    <Typography variant="body2">{CmoWarn.messageText}</Typography>
                                    <Typography variant="caption">
                                        Rows: {CmoWarn.messageRows}
                                    </Typography>
                                </div>
                            );
                        })}
                </div>
                <div className={`${classes.infoSection}`}>
                    <div className={`flex-left ${classes.leftFlex}`}>
                        <CheckIcon className={`${classes.icons} ${classes.checkIcon}`} />
                        <Typography variant="subtitle2">
                            Successfully ingested (
                            {dialogContent.success ? dialogContent.success.length : 0})
                        </Typography>
                    </div>
                    {dialogContent.success &&
                        dialogContent.success.map((success) => {
                            return (
                                <div
                                    key={success.messageText.slice(0, 5)}
                                    className={`${classes.infoRow}`}
                                >
                                    <Typography variant="body2">{success.messageText}</Typography>
                                    <Typography variant="caption">
                                        Rows: {success.messageRows}
                                    </Typography>
                                </div>
                            );
                        })}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="secondary" color="primary" disableElevation>
                    CLOSE
                </Button>
            </DialogActions>
        </Dialog>
    );
}
