import React, { useEffect, useState } from 'react';

import {
    getAllPerformerNamesForMetadata,
    getAllRolesAndInstrumentsForMetadata,
    getAssetMetadataById,
} from '@actions/apiActions';
import { changeStateByNestedKey } from '@actions/fugaActions';
import { AssetMetaDataArtistsSection } from '@common/AssetMetaDataEdit/AssetMetaDataArtistsSection';
import { AssetMetaDataCopyrightSection } from '@common/AssetMetaDataEdit/AssetMetaDataCopyrightSection';
import { AssetMetaDataRecordingDetailsSection } from '@common/AssetMetaDataEdit/AssetMetaDataRecordingDetailsSection';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import TextFieldWrapper from '@MuiWrappers/TextFieldWrapper';
import { ASSET_METADATA_FIELDS } from '@utils/constants/AssetMetadataEditModal';
import { assetValidationScheme } from '@utils/validations/assetValidation';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import AssetMetaDataContributorsSection from './AssetMetaDataContributorsSection';
import AssetMetaDataProductsSection from './AssetMetaDataProductsSection';
import AssetMetaDataRightsHoldersSection from './AssetMetaDataRightsHoldersSection';

const defaultValues = {
    assetTrackTitle: '',
    assetTrackVersion: '',
    assetIsrc: '',
    assetDisplayArtists: '',
    assetFugaId: '',
    numberOfFeaturedContr: '',
    numberOfNonFeaturedContr: '',
    commissioningCountry: '',
    lineUpComplete: '',
    duration: '',
    contentType: '',
    recordingCountry: '',
    recordingDate: null,
    recordingYear: '',
    isRemastered: false,
    medley: false,
    containsSample: false,
    genre: '',
    language: '',
    pname: '',
    pyear: '',
};

export default function AssetMetaDataEditModal({
    isOpen,
    setOpen,
    dataItem,
    onSuccessCb,
    isPerformer,
}) {
    const dispatch = useDispatch();
    const [mainDetailsInptValues, setMainDetailsInptValues] = useState({});

    const assetMetadataModel = useSelector((state) => state.fugaReducers.assetMetadata);
    const {
        dataObjFromBE,
        rhOwnershipDataFromBE,
        rhOwnershipSaveResult,
        contributorsDataFromBE,
        contributionSaveResult,
        productsDataFromBE,
    } = assetMetadataModel;
    const artists = useSelector((state) => state.fugaReducers.assetMetadata.artistsDataFromBE);

    const methods = useForm({
        mode: 'onChange',
        resolver: yupResolver(assetValidationScheme),
        defaultValues,
    });
    const { reset, trigger } = methods;

    useEffect(() => {
        if (isOpen) {
            dispatch(getAssetMetadataById(dataItem.assetId));
            dispatch(changeStateByNestedKey('assetMetadata', 'validations', {}));
            dispatch(getAllRolesAndInstrumentsForMetadata());
            dispatch(getAllPerformerNamesForMetadata());
        }
    }, [isOpen]);

    useEffect(() => {
        if (dataObjFromBE.assetId) {
            reset({
                [ASSET_METADATA_FIELDS.assetTrackTitle]: dataObjFromBE?.assetTrackTitle ?? '',
                [ASSET_METADATA_FIELDS.assetTrackVersion]: dataObjFromBE?.assetTrackVersion ?? '',
                [ASSET_METADATA_FIELDS.assetIsrc]: dataObjFromBE?.assetIsrc ?? '',
                [ASSET_METADATA_FIELDS.assetFugaId]: dataObjFromBE?.assetFugaId ?? null,
                [ASSET_METADATA_FIELDS.assetNRPId]: dataObjFromBE?.assetNrpId ?? '',
                [ASSET_METADATA_FIELDS.assetDisplayArtists]:
                    dataObjFromBE?.assetDisplayArtists ?? '',
                [ASSET_METADATA_FIELDS.numberOfFeaturedContr]:
                    dataObjFromBE?.numberOfFeaturedContr ?? '',
                [ASSET_METADATA_FIELDS.numberOfNonFeaturedContr]:
                    dataObjFromBE?.numberOfNonFeaturedContr ?? '',
                [ASSET_METADATA_FIELDS.lineUpComplete]:
                    dataObjFromBE?.lineUpComplete?.toString() ?? '',
                [ASSET_METADATA_FIELDS.pyear]: dataObjFromBE?.pyear ?? '',
                [ASSET_METADATA_FIELDS.pname]: dataObjFromBE?.pname ?? '',
                [ASSET_METADATA_FIELDS.duration]: dataObjFromBE?.duration ?? '',
                [ASSET_METADATA_FIELDS.contentType]: dataObjFromBE?.contentType ?? '',
                [ASSET_METADATA_FIELDS.recordingCountry]: dataObjFromBE?.recordingCountry ?? '',
                [ASSET_METADATA_FIELDS.commissioningCountry]:
                    dataObjFromBE?.commissioningCountry ?? '',
                [ASSET_METADATA_FIELDS.recordingDate]: dataObjFromBE?.recordingDate ?? null,
                [ASSET_METADATA_FIELDS.recordingYear]: dataObjFromBE?.recordingYear ?? '',
                [ASSET_METADATA_FIELDS.isRemastered]: dataObjFromBE?.isRemastered ?? false,
                [ASSET_METADATA_FIELDS.medley]: dataObjFromBE?.medley ?? false,
                [ASSET_METADATA_FIELDS.containsSample]: dataObjFromBE?.containsSample ?? false,
                [ASSET_METADATA_FIELDS.genre]: dataObjFromBE?.genre?.toLowerCase() ?? '',
                [ASSET_METADATA_FIELDS.language]: dataObjFromBE?.language ?? '',
            });
            trigger();
        }
    }, [dataObjFromBE, trigger, reset]);

    const tableReloadOnSuccess = () => {
        return dispatch(getAssetMetadataById(dataItem.assetId));
    };

    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
            reset(defaultValues);
            dispatch(
                changeStateByNestedKey('assetMetadata', 'rhOwnershipDataFromBE', { content: [] }),
            );
            dispatch(
                changeStateByNestedKey('assetMetadata', 'contributorsDataFromBE', { content: [] }),
            );
            dispatch(
                changeStateByNestedKey('assetMetadata', 'rhOwnershipSaveResult', {
                    isSuccessful: false,
                    hasWarning: false,
                    isDeleted: false,
                }),
            );
            dispatch(
                changeStateByNestedKey('assetMetadata', 'contributionSaveResult', {
                    isSuccessful: false,
                    hasWarning: false,
                    isDeleted: false,
                }),
            );
            dispatch(changeStateByNestedKey('assetMetadata', 'dataObjFromBE', {}));
        }
    };

    const handleSave = () => {
        if (onSuccessCb) {
            const formValues = methods.getValues();

            dispatch(
                onSuccessCb(dataItem.assetId, formValues, {
                    isUpdateble: true,
                    arrayName: 'content',
                    idFieldName: 'assetId',
                    id: dataItem.assetId,
                    fieldNamesListToChange: [],
                    closeCb: handleClose,
                    isPerformer,
                }),
            );
        }
    };

    const castValueByitsType = (value, valueType) => {
        let finalValue;
        if (valueType.isNumber) {
            finalValue = parseInt(value);
        } else if (valueType.isBool) {
            const checkedValue = value === 'false' ? '' : value;
            finalValue = Boolean(checkedValue);
        } else {
            finalValue = value;
        }
        return finalValue;
    };

    const handleInputChange = (event, valueType = { isNumber: false, isBool: false }) => {
        const value = event.currentTarget.value;
        const name = event.target.name;
        const valueToSet = castValueByitsType(value, valueType);
        setMainDetailsInptValues({
            ...mainDetailsInptValues,
            [name]: valueToSet,
        });
    };

    return (
        <Dialog
            onClose={handleClose}
            open={isOpen}
            maxWidth="md"
            sx={{ '& .MuiDialog-paperWidthMd': { maxWidth: '964px' } }}
        >
            <FormProvider {...methods}>
                <DialogTitle onClose={handleClose} sx={{ p: '32px 32px 16px', mb: 2 }}>
                    <Typography variant="h5">Asset: {dataItem.trackTitle}</Typography>
                </DialogTitle>
                <DialogContent sx={{ p: 4 }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                        Main details
                    </Typography>
                    {/* Main Details Section */}
                    <Grid container spacing={3}>
                        <Grid size={{ xs: 12, sm: 8 }}>
                            <Controller
                                name={ASSET_METADATA_FIELDS.assetTrackTitle}
                                control={methods.control}
                                render={({ field }) => (
                                    <TextFieldWrapper
                                        {...field}
                                        label="Track Title"
                                        required
                                        multiline
                                        maxRows={10}
                                        fullWidth
                                        error={!!methods.formState.errors.assetTrackTitle}
                                        helperText={
                                            methods.formState.errors.assetTrackTitle?.message
                                        }
                                        sx={{
                                            pb: 0,
                                            '& .MuiInputBase-input': {
                                                fontSize: '14px',
                                                lineHeight: '18px',
                                            },
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid size={{ xs: 12, sm: 4 }}>
                            <Controller
                                name={ASSET_METADATA_FIELDS.assetTrackVersion}
                                control={methods.control}
                                render={({ field }) => (
                                    <TextFieldWrapper
                                        {...field}
                                        label="Track Version"
                                        multiline
                                        maxRows={10}
                                        fullWidth
                                        required
                                        error={!!methods.formState.errors.assetTrackVersion}
                                        helperText={
                                            methods.formState.errors.assetTrackVersion?.message
                                        }
                                        sx={{
                                            pb: 0,
                                            '& .MuiInputBase-input': {
                                                fontSize: '14px',
                                                lineHeight: '18px',
                                            },
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ width: '100%' }}>
                        <Grid size={{ xs: 12, sm: 2 }}>
                            <Controller
                                name={ASSET_METADATA_FIELDS.assetIsrc}
                                control={methods.control}
                                render={({ field }) => (
                                    <TextFieldWrapper
                                        {...field}
                                        label="ISRC"
                                        required
                                        multiline
                                        maxRows={10}
                                        fullWidth
                                        error={!!methods.formState.errors.assetIsrc}
                                        helperText={methods.formState.errors.assetIsrc?.message}
                                        sx={{
                                            pb: 0,
                                            width: '121px',
                                            '& .MuiInputBase-input': {
                                                fontSize: '14px',
                                                lineHeight: '18px',
                                            },
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid size={{ xs: 12, sm: 2 }}>
                            <Controller
                                name={ASSET_METADATA_FIELDS.assetNRPId}
                                control={methods.control}
                                render={({ field }) => (
                                    <TextFieldWrapper
                                        {...field}
                                        label="DNR ID"
                                        required
                                        fullWidth
                                        InputProps={{ readOnly: true }}
                                        error={!!methods.formState.errors.assetNrpId}
                                        helperText={methods.formState.errors.assetNrpId?.message}
                                        sx={{
                                            pb: 0,
                                            width: '121px',
                                            '& .MuiInputBase-input': {
                                                fontSize: '14px',
                                                lineHeight: '18px',
                                            },
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid size={{ xs: 12, sm: 2 }}>
                            <Controller
                                name={ASSET_METADATA_FIELDS.assetFugaId}
                                control={methods.control}
                                render={({ field }) => (
                                    <TextFieldWrapper
                                        {...field}
                                        label="FUGA ID"
                                        fullWidth
                                        type="number"
                                        error={!!methods.formState.errors.assetFugaId}
                                        helperText={methods.formState.errors.assetFugaId?.message}
                                        sx={{
                                            pb: 0,
                                            height: '74px',
                                            '& .MuiInputBase-input': {
                                                fontSize: '14px',
                                                lineHeight: '18px',
                                            },
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>

                    {/* Other Sections */}
                    <Grid xs={12}>
                        <Typography variant="h6" sx={{ mb: 2 }}>
                            Artists
                        </Typography>
                        <AssetMetaDataArtistsSection
                            dataItem={dataItem}
                            tableReloadOnSuccess={tableReloadOnSuccess}
                            artists={artists}
                            displayArtistsFieldName={ASSET_METADATA_FIELDS.assetDisplayArtists}
                        />
                    </Grid>
                    <Grid xs={12}>
                        <Typography variant="h6" sx={{ mb: 2 }}>
                            Recording Details
                        </Typography>
                        <AssetMetaDataRecordingDetailsSection />
                    </Grid>
                    <Grid xs={12}>
                        <Typography variant="h6" sx={{ mb: 2 }}>
                            Copyright
                        </Typography>
                        <AssetMetaDataCopyrightSection />
                    </Grid>
                    <Grid xs={12}>
                        <AssetMetaDataContributorsSection
                            dataItem={dataItem}
                            contributorsDataFromBE={contributorsDataFromBE}
                            inputOperations={{
                                handleInputChange,
                                mainDetailsInptValues,
                            }}
                            tableReloadOnSuccess={tableReloadOnSuccess}
                            contributionSaveResult={contributionSaveResult}
                        />
                    </Grid>
                    <Grid xs={12}>
                        <AssetMetaDataProductsSection
                            dataItem={dataItem}
                            productsDataFromBE={productsDataFromBE}
                            tableReloadOnSuccess={tableReloadOnSuccess}
                        />
                    </Grid>
                    <Grid xs={12}>
                        <AssetMetaDataRightsHoldersSection
                            dataItem={dataItem}
                            rhOwnershipDataFromBE={rhOwnershipDataFromBE}
                            rhOwnershipSaveResult={rhOwnershipSaveResult}
                        />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined" color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSave}
                        variant="contained"
                        color="primary"
                        disabled={!methods.formState.isValid || !methods.formState.isDirty}
                    >
                        Save
                    </Button>
                </DialogActions>
            </FormProvider>
        </Dialog>
    );
}
