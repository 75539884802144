import React, { useEffect, useState } from 'react';

import { changeStateByKey } from '@actions/fugaActions';
import { createCommentByCmoRegIdAPI, getCommentsByCmoRegIdAPI } from '@api/commentController';
import { isSymbolsCountValid } from '@common/modals/common';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormHelperText,
    Tooltip,
    Typography,
    Box,
} from '@mui/material';
import TextFieldWrapper from '@MuiWrappers/TextFieldWrapper';
import { useDispatch, useSelector } from 'react-redux';

export default function CmoRegCommentsModal({ isOpen, setOpen, dataItem, onSuccessCb }) {
    const dispatch = useDispatch();
    const cmoCommentsModel = useSelector((state) => state.fugaReducers.cmoComments);
    const [commentInput, setCommentInput] = useState('');
    const [commentSymCount, setCommentSymCount] = useState(0);
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const handleTooltipClose = () => {
        setTooltipOpen(false);
    };

    const handleTooltipOpen = () => {
        setTooltipOpen(true);
    };

    useEffect(() => {
        if (cmoCommentsModel) {
            isSymbolsCountValid(
                cmoCommentsModel,
                handleTooltipOpen,
                handleTooltipClose,
                setCommentSymCount,
            );
        } else {
            setCommentSymCount(0);
        }
        setCommentInput(cmoCommentsModel || '');
    }, [cmoCommentsModel]);

    const handleOpen = async () => {
        if (isOpen) {
            try {
                const resp = await getCommentsByCmoRegIdAPI(dataItem.cmoRegId);
                dispatch(changeStateByKey('cmoComments', resp.data.commentMsg || ''));
                setCommentInput(cmoCommentsModel || '');
            } catch {
                dispatch(changeStateByKey('cmoComments', ''));
            }
        }
    };

    useEffect(() => {
        handleOpen();
    }, [isOpen]);

    const handleClose = () => {
        setOpen(false);
        setCommentInput('');
    };

    const handleSave = async () => {
        await createCommentByCmoRegIdAPI({
            commentMsg: commentInput,
            clientCmoRegistrationId: dataItem.cmoRegId,
        });
        if (onSuccessCb) onSuccessCb();
        handleClose();
    };

    const handleInputChange = (e) => {
        const value = e.currentTarget.value;
        if (isSymbolsCountValid(value, handleTooltipOpen, handleTooltipClose, setCommentSymCount)) {
            setCommentInput(value);
        }
    };

    return (
        <Dialog onClose={handleClose} open={isOpen} maxWidth="md">
            <DialogTitle sx={{ padding: '19px 16px' }}>
                <Typography variant="h6">Edit Comment</Typography>
            </DialogTitle>
            <DialogContent sx={{ padding: '10px 16px' }}>
                <Tooltip
                    title="Input text should be less than 5000 characters"
                    disableHoverListener
                    open={tooltipOpen}
                >
                    <TextFieldWrapper
                        label="Comment"
                        onChange={handleInputChange}
                        name="comment"
                        type="text"
                        multiline
                        maxRows={10}
                        value={commentInput || ''}
                        sx={{ width: '552px', paddingBottom: 0 }}
                        onBlur={handleTooltipClose}
                    />
                </Tooltip>
                <Box sx={{ display: 'flex', pb: '21px', justifyContent: 'flex-end' }}>
                    <FormHelperText>{commentSymCount}/5000</FormHelperText>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined" color="primary" disableElevation>
                    cancel
                </Button>
                <Button onClick={handleSave} variant="contained" color="primary" disableElevation>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
