import React, { useState } from 'react';

import { changeStateByNestedKey } from '@actions/fugaActions';
import StatusIconsWithTooltip from '@common/AlertsRow';
import { renderIngestionChip } from '@common/customChips';
import OverflowTip from '@common/OverflowTip';
import { MoreVertOutlined, Visibility, Download } from '@mui/icons-material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { CircularProgress, IconButton, Tooltip, Menu, MenuItem } from '@mui/material';
import { Typography } from '@mui/material';
import Icon from '@mui/material/Icon';
import { styled } from '@mui/material/styles';
import { format, parseISO } from 'date-fns';
import { useDispatch } from 'react-redux';

const FlexCenter = styled('div')({
    display: 'flex',
    justifyContent: 'center',
});

const IngestionRow = ({ headerItem, dataItem }) => {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const columnTitle = headerItem.title.toLowerCase();

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleViewResults = (id) => {
        dispatch(changeStateByNestedKey('repertoires', 'reportId', id));
        dispatch(changeStateByNestedKey('repertoires', 'errorDialogIsOpen', true));
        handleMenuClose();
    };

    if (columnTitle.includes('description')) {
        return (
            <Typography variant="body2" component="span" noWrap>
                <strong>{dataItem.fileName}</strong>
            </Typography>
        );
    } else if (columnTitle === 'event') {
        return (
            <span style={{ display: 'flex', alignItems: 'center' }}>
                <Icon
                    sx={{
                        fontSize: 18,
                    }}
                >
                    database_upload
                </Icon>
                <Typography variant="body2" component="span" noWrap sx={{ marginLeft: '9px' }}>
                    {dataItem[headerItem.dataIndex]}
                </Typography>
            </span>
        );
    } else if (columnTitle === 'date') {
        return (
            <Typography variant="body2" component="span" noWrap>
                {format(parseISO(dataItem[headerItem.dataIndex]), 'dd/MM/yyyy HH:mm')}
            </Typography>
        );
    } else if (headerItem.dataIndex === 'eventStatus') {
        return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                }}
            >
                {renderIngestionChip(dataItem[headerItem.dataIndex])}
                <StatusIconsWithTooltip eventSummary={dataItem.eventSummary} />
            </div>
        );
    } else if (columnTitle === '') {
        const status = dataItem.eventStatus;

        switch (status) {
            case 'IN_PROGRESS':
                return (
                    <IconButton>
                        <CircularProgress
                            size="20px"
                            sx={{
                                color: '#666',
                            }}
                        />
                    </IconButton>
                );
            case 'SUCCESS':
                return (
                    <FlexCenter>
                        <IconButton onClick={handleMenuOpen}>
                            <MoreVertOutlined />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            <MenuItem onClick={() => handleViewResults(dataItem.asyncEventId)}>
                                <Visibility sx={{ marginRight: 1 }} />
                                View ingestions result
                            </MenuItem>
                            <MenuItem onClick={handleMenuClose} disabled>
                                <Download sx={{ marginRight: 1 }} />
                                Download results
                            </MenuItem>
                        </Menu>
                    </FlexCenter>
                );
            default:
                return (
                    <FlexCenter>
                        <Tooltip title={dataItem.errorCode || 'error'}>
                            <IconButton>
                                <InfoOutlinedIcon size="20px" />
                            </IconButton>
                        </Tooltip>
                    </FlexCenter>
                );
        }
    } else {
        return <OverflowTip>{dataItem[headerItem.dataIndex] || ''}</OverflowTip>;
    }
};

export default IngestionRow;
