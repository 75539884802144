import React, { useCallback, useEffect, useState } from 'react';

import {
    getAlertMsgByCmoRegId,
    getCmoConnectionsByClientId,
    updateNestedField,
} from '@actions/apiActions';
import { changeStateByNestedKey } from '@actions/fugaActions';
import { formatTerritoryCodes, getAllTerritories } from '@actions/territories';
import { changeRegistrationStatusAPI, deleteCmoRegistrationAPI } from '@api/cmoController';
import CommonConfirmationDialog from '@common/CommonConfirmationDialog';
import CommonDropDownChips, { STATUSES_LIST, chipThemes } from '@common/CommonDropDownChips';
import CommonTableGrid from '@common/CommonTable/CommonTableGrid';
import { ChipLabel } from '@common/customChips';
import CmoRegAlertsModal from '@common/modals/CmoRegAlertsModal';
import CmoRegCommentsModal from '@common/modals/CmoRegCommentsModal';
import NewCmoConnectionModal from '@common/modals/NewCmoConnectionModal/NewCmoConnectionModal';
import StatusHistoryModal from '@common/modals/StatusHistoryModal';
import MoreDropDownButton from '@common/MoreDropDownButton';
import OverflowTip from '@common/OverflowTip';
import ChatBubbleUnread from '@images/svgComponents/ChatBubbleUnreadSvg';
import PendingIconSvg from '@images/svgComponents/PendingIconSvg';
import {
    ChatBubbleOutline,
    Close,
    Done,
    ErrorOutline,
    ScheduleOutlined,
} from '@mui/icons-material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { IconButton, Tooltip, Box, styled } from '@mui/material';
import moment from 'moment';
import { connect, useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

const FlexBox = styled(Box)({
    display: 'flex',
});

const SpaceBetweenBox = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});

const IconButtonNoHover = styled(IconButton)({
    padding: '8px',
    '&:hover': {
        backgroundColor: 'unset',
    },
});

const headers = [
    { title: 'CMO', dataIndex: 'cmoName' },
    { title: 'Territories', dataIndex: 'territoriesToShow' },
    { title: 'Start Date', dataIndex: 'startDate' },
    { title: 'End Date', dataIndex: 'endDate' },
    { title: 'Registration Status', dataIndex: 'registrationStatus' },
    { title: 'Last Modified', dataIndex: 'statusDate' },
    { title: '', dataIndex: 'commentPresented', hasIcon: true },
];

function CmoConnectionTable(props) {
    const dispatch = useDispatch();
    const cmoConnectionModel = useSelector((state) => state.fugaReducers.cmoConnection);
    const performersModel = useSelector((state) => state.fugaReducers.performers);
    const [showTerritoryModal, setShowTerritoryModal] = useState(false);
    const [territories, setTerritories] = useState([]);
    const territoriesModel = useSelector((state) => state.fugaReducers.territories);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isStatusDialogOpen, setIsStatusDialogOpen] = useState(false);
    const [cmoRegId, setCmoRegId] = useState(null);
    const [statusChangeOptions, setStatusChangeOptions] = useState([]);
    const [anchorEl, setAnchorEl] = useState(false);
    const [openCommentsModal, setOpenCommentsModal] = useState(false);
    const [openAlertsModal, setOpenAlertsModal] = useState(false);
    const [clickedDataItem, setClickedDataItem] = useState({});

    useEffect(() => {
        if (!cmoConnectionModel.inUpdateMode) {
            dispatch(getCmoConnectionsByClientId(props.match.params.id));
        }
    }, []);

    useEffect(() => {
        dispatch(getAllTerritories());
        setTerritories(territoriesModel.checkedTerritories);
    }, [territoriesModel.checkedTerritories, dispatch]);

    const openModal = () => {
        setShowTerritoryModal(true);
    };

    const deleteConnection = async () => {
        await deleteCmoRegistrationAPI(cmoRegId);
        await dispatch(getCmoConnectionsByClientId(props.match.params.id));
    };

    const handleHistoryClick = (evt, dataItem) => {
        dispatch(changeStateByNestedKey('performers', 'selectedRowDataItem', dataItem));
        setAnchorEl(evt.currentTarget);
    };

    const handleCommentsClick = (e, dataItem) => {
        setClickedDataItem(dataItem);
        setOpenCommentsModal(true);
    };

    const handleAlertClick = async (dataItem) => {
        setClickedDataItem(dataItem);
        await dispatch(getAlertMsgByCmoRegId(dataItem.cmoRegId));
        setOpenAlertsModal(true);
    };

    const openHistoryInfo = Boolean(anchorEl);

    const handleHistoryClose = () => {
        dispatch(changeStateByNestedKey('performers', 'registrationHistory', []));
        setAnchorEl(null);
    };

    const updateStatusInRow = (response) => {
        const dataItemFromResp = response.data[0].id;
        dispatch(
            updateNestedField('cmoConnection', 'dataFromBE', dataItemFromResp.statusDate, {
                arrayName: 'content',
                idFieldName: 'cmoRegId',
                id: dataItemFromResp.clientCmoRegistrationId,
                fieldNameToChange: 'statusDate',
            }),
        );
        dispatch(
            updateNestedField('cmoConnection', 'dataFromBE', dataItemFromResp.newStatus, {
                arrayName: 'content',
                idFieldName: 'cmoRegId',
                id: dataItemFromResp.clientCmoRegistrationId,
                fieldNameToChange: 'registrationStatus',
            }),
        );
        dispatch(
            getCmoConnectionsByClientId(props.match.params.id, {
                isUpdateble: true,
                arrayName: 'content',
                idFieldName: 'cmoRegId',
                id: dataItemFromResp.clientCmoRegistrationId,
                fieldNameToChange: 'acceptableRegistrationStatuses',
            }),
        );
    };

    const statusChangeCb = async (status, dataItem, fromModal = false) => {
        if (cmoConnectionModel.isFirstToSubmit && !fromModal) {
            setStatusChangeOptions([status, dataItem]);
            setIsStatusDialogOpen(true);
        } else {
            const response = await changeRegistrationStatusAPI({
                clientCmoRegistrationId: dataItem.cmoRegId,
                newStatus: status,
            });
            updateStatusInRow(response);
        }
    };

    const moreOptionsSpecificData = useCallback(
        (headerItem, dataItem) => {
            const coulumnTitle = headerItem.title.toLowerCase();
            if (coulumnTitle === '') {
                const onEdit = () => {
                    dispatch(formatTerritoryCodes(dataItem.territoriesCodes));
                    dispatch(changeStateByNestedKey('performers', 'selectedRowDataItem', dataItem));
                    dispatch(changeStateByNestedKey('performers', 'selectedCmoId', dataItem.cmoId));
                    openModal();
                };
                const onDelete = () => {
                    setIsDialogOpen(true);
                    setCmoRegId(dataItem.cmoRegId);
                };
                return (
                    <>
                        <IconButtonNoHover
                            onClick={(e) => handleHistoryClick(e, dataItem)}
                            disableRipple
                        >
                            <ScheduleOutlined />
                        </IconButtonNoHover>
                        <IconButtonNoHover
                            onClick={(e) => handleCommentsClick(e, dataItem)}
                            disableRipple
                        >
                            {dataItem.commentPresented ? (
                                <ChatBubbleUnread />
                            ) : (
                                <ChatBubbleOutline />
                            )}
                        </IconButtonNoHover>
                        <MoreDropDownButton
                            callbacks={{
                                onEditCB: onEdit,
                                onDeleteCB: onDelete,
                                onAlertCB: handleAlertClick,
                            }}
                            dataItem={dataItem}
                        />
                    </>
                );
            } else if (coulumnTitle.includes('start date')) {
                const status = dataItem.status.toLowerCase();
                const StatusIcon = () => {
                    if (status === 'active') {
                        return (
                            <Done
                                sx={{
                                    marginRight: 1,
                                    color: '#00A542',
                                }}
                            />
                        );
                    } else if (status === 'inactive') {
                        return (
                            <Close
                                sx={{
                                    marginRight: 1,
                                    color: '#5F5F60',
                                }}
                            />
                        );
                    } else {
                        return (
                            <PendingIconSvg
                                sx={{
                                    marginRight: 1,
                                    color: '#FF8800',
                                }}
                            />
                        );
                    }
                };
                return (
                    <OverflowTip>
                        <SpaceBetweenBox>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <StatusIcon />
                                {dataItem[headerItem.dataIndex]}
                            </Box>
                        </SpaceBetweenBox>
                    </OverflowTip>
                );
            } else if (
                coulumnTitle.includes('registration status') &&
                dataItem.registrationStatus
            ) {
                const menuItemsList = (dataItem.acceptableRegistrationStatuses || [])
                    .filter((status) => Object.keys(STATUSES_LIST).includes(status))
                    .map((status) => ({
                        title: status.split('_').join(' '),
                        onClickClb: statusChangeCb,
                        CbArgs: [status, dataItem],
                        color: STATUSES_LIST[status].color,
                    }));

                return (
                    <FlexBox>
                        {dataItem[headerItem.dataIndex] === 'EXCLUSIVE_LICENSE_DEAL' ? (
                            <ChipLabel label="EXCLUSIVE LICENSE" style={chipThemes.greenChip} />
                        ) : (
                            <CommonDropDownChips
                                chipTitle={dataItem[headerItem.dataIndex].split('_').join(' ')}
                                menuItemsList={menuItemsList}
                                hasAlerts={dataItem?.alertCategories}
                            />
                        )}
                        <FlexBox
                            sx={{ marginLeft: 1, alignItems: 'center' }}
                            onClick={() => handleAlertClick(dataItem)}
                        >
                            {(dataItem.alertCategories || []).includes('INTERNAL') && (
                                <Tooltip title="Internal action required">
                                    <ErrorOutline sx={{ fill: '#F44139' }} />
                                </Tooltip>
                            )}
                            {(dataItem.alertCategories || []).includes('CLIENT') && (
                                <Tooltip title="Client action required">
                                    <WarningAmberIcon
                                        sx={{
                                            fill: '#FF8800',
                                        }}
                                    />
                                </Tooltip>
                            )}
                            {(dataItem.alertCategories || []).includes('CMO') && (
                                <Tooltip title="Awaiting CMO action">
                                    <InfoOutlinedIcon
                                        sx={{
                                            fill: '#45A2DD',
                                        }}
                                    />
                                </Tooltip>
                            )}
                        </FlexBox>
                    </FlexBox>
                );
            } else if (coulumnTitle.includes('last modified')) {
                return (
                    <OverflowTip>
                        {moment(dataItem[headerItem.dataIndex]).format('YYYY-MM-DD')}
                    </OverflowTip>
                );
            } else {
                return <OverflowTip>{dataItem[headerItem.dataIndex]}</OverflowTip>;
            }
        },
        [dispatch, handleAlertClick, statusChangeCb],
    );

    return (
        <Box>
            <CommonTableGrid
                dataList={cmoConnectionModel.dataFromBE}
                headerList={headers}
                cellDataCb={moreOptionsSpecificData}
                loading={cmoConnectionModel.tableIsLoading}
                preferences={{
                    loadDataByPageCb: {
                        dispatchFn: dispatch,
                        callbackFn: getCmoConnectionsByClientId,
                        argumentsList: [props.match.params.id],
                    },
                }}
                initialRowsPerPage={50}
                selection={{
                    isSelectable: false,
                }}
            />
            {showTerritoryModal && (
                <NewCmoConnectionModal
                    showTerritoryModal={showTerritoryModal}
                    setShowTerritoryModal={setShowTerritoryModal}
                    territories={territories}
                    setTerritories={setTerritories}
                    groupedTerritories={territoriesModel.groupedTerritories}
                    clientId={props.match.params.id}
                    isEditing
                    customData={{
                        dealsContainerName: 'clientDeals',
                        selectedCmoStoreName: 'performers',
                        dataModel: performersModel.byClietnId,
                        selectedCmoId: performersModel.selectedCmoId,
                        modalValidations: performersModel.modalValidations,
                        selectedRowDataItem: performersModel.selectedRowDataItem,
                        isPerformer: { isPerformer: true },
                        onTableReload: {
                            reloadCb: getCmoConnectionsByClientId,
                            params: [props.match.params.id],
                        },
                        cmoIssuedIds: performersModel.cmoIssuedIds,
                        hasIssuedId: performersModel.hasIssuedId,
                    }}
                />
            )}
            <CommonConfirmationDialog
                isOpen={isDialogOpen}
                closeCb={setIsDialogOpen}
                title="Delete CMO for this client?"
                description="Are you sure you want to delete this CMO row for this client?"
                onConfirmCb={deleteConnection}
            />
            <CommonConfirmationDialog
                isOpen={isStatusDialogOpen}
                closeCb={setIsStatusDialogOpen}
                title="Begin Client CMO Registration?"
                description="Once this registration is 'Submitted' then you will not be able to edit the Client Deal, do you want to proceed?"
                onConfirmCb={statusChangeCb}
                confirmCbArgs={[...statusChangeOptions, true]}
            />
            <StatusHistoryModal
                anchorEl={anchorEl}
                onClose={handleHistoryClose}
                isOpen={openHistoryInfo}
                selectedRowDataItem={performersModel.selectedRowDataItem}
                storeName="performers"
                contentData={performersModel.registrationHistory}
            />
            <CmoRegCommentsModal
                isOpen={openCommentsModal}
                setOpen={setOpenCommentsModal}
                dataItem={clickedDataItem}
                onSuccessCb={() => {
                    dispatch(
                        getCmoConnectionsByClientId(props.match.params.id, {
                            isUpdateble: true,
                            arrayName: 'content',
                            idFieldName: 'cmoRegId',
                            id: clickedDataItem.cmoRegId,
                            fieldNameToChange: 'commentPresented',
                        }),
                    );
                }}
            />
            <CmoRegAlertsModal
                isOpen={openAlertsModal}
                setOpen={setOpenAlertsModal}
                dataItem={clickedDataItem}
            />
        </Box>
    );
}

const withUrlDataCmoConnectionTable = withRouter(CmoConnectionTable);
export default connect((r) => r)(withUrlDataCmoConnectionTable);
