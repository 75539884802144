import React, { useRef, useState } from 'react';

import { importCSVAPI } from '@api/assetController';
import { cleanupSSE } from '@api/SSENotificationController';
import { items } from '@data/constants';
import LogoutSvg from '@images/svgComponents/LogoutSvg';
import { NotificationsNoneOutlined, AccountCircle } from '@mui/icons-material';
import { Badge, Icon, IconButton } from '@mui/material';
import cerberusUtils from '@utils/cerberusUtils';
import utils from '@utils/utils';
import { useSelector } from 'react-redux';

import CommonIconDropDownButton from './CommonIconDropDownButton';
import Notification from './modals/Notifications/NotificationsModal';
const { deleteCerberusToken, deleteClientId, deleteUserRole } = cerberusUtils;

export default function HeaderIconsPanel() {
    const [notificationOpen, setNotificationOpen] = useState(false);
    const [inputClicked, setInputClicked] = useState(false);
    const inputRef = useRef(null);
    const hasUnreadNotifications = useSelector(
        (state) => state.fugaReducers.notifications.hasUnreadNotifications,
    );

    const doLogout = async () => {
        try {
            await cleanupSSE();
        } catch (error) {
            console.warn('Error during SSE cleanup:', error);
        } finally {
            deleteUserRole();
            deleteCerberusToken();
            deleteClientId();
            window.location.assign('/login');
        }
    };

    const menuItemsList = [
        {
            title: 'Log out',
            onClickClb: doLogout,
            icon: <LogoutSvg style={{ height: 24, width: 24, marginRight: 16 }} />,
        },
    ];

    const CustomIconButton = ({
        icon: IconComponent,
        onClick,
        children,
        iconProps = {},
        buttonProps = {},
    }) => (
        <IconButton
            onClick={onClick}
            size="large"
            sx={{ padding: 0, margin: '0 20px 0 0' }}
            {...buttonProps}
        >
            {IconComponent ? (
                <IconComponent sx={{ width: 24, height: 24, color: '#FFFFFF', ...iconProps }} />
            ) : (
                children
            )}
        </IconButton>
    );

    const uploadFile = async ({ currentTarget }) => {
        if (currentTarget.value) {
            await importCSVAPI(currentTarget.files);
        }
        setInputClicked(false);
    };

    return (
        <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
            {utils.checkItemRolesAndPermissions(items.btns.notificationsHerader) && (
                <CustomIconButton
                    onClick={() => {
                        if (!inputClicked) {
                            setInputClicked(true);
                            inputRef.current.click();
                            setInputClicked(false);
                        }
                    }}
                >
                    <Icon
                        sx={{
                            fontSize: 24,
                            color: '#FFFFFF',
                        }}
                    >
                        database_upload
                    </Icon>
                </CustomIconButton>
            )}
            {utils.checkItemRolesAndPermissions(items.btns.notificationsHerader) && (
                <CustomIconButton onClick={() => setNotificationOpen(true)}>
                    <Badge color="secondary" variant="dot" invisible={!hasUnreadNotifications}>
                        <NotificationsNoneOutlined
                            sx={{ width: 24, height: 24, color: '#FFFFFF' }}
                        />
                    </Badge>
                </CustomIconButton>
            )}
            <CommonIconDropDownButton
                triggerButton={<CustomIconButton icon={AccountCircle} />}
                menuItemsList={menuItemsList}
            />
            {notificationOpen && (
                <Notification onClose={() => setNotificationOpen(false)} open={notificationOpen} />
            )}
            <input
                ref={inputRef}
                type="file"
                style={{ position: 'fixed', top: '-100%' }}
                onChange={(e) => uploadFile(e)}
            />
        </div>
    );
}
