import React from 'react';

import { ListItem } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { NotificationTypes } from '@utils/constants/notifications';

import ExportNotificationRow from './ExportNotificationRow';
import IngestionNotificationRow from './IngestionNotificationRow';
import SpotifyNotificationRow from './SpotifyNotificationRow';

const NotificationRow = ({ notification }) => {
    const renderNotificationContent = () => {
        switch (notification.notificationType) {
            case NotificationTypes.EXPORT:
                return <ExportNotificationRow notification={notification} />;
            case NotificationTypes.INGESTION:
                return <IngestionNotificationRow notification={notification} />;
            case NotificationTypes.SPOTIFY:
                return <SpotifyNotificationRow notification={notification} />;
            default:
                return null;
        }
    };

    return (
        <ListItem
            key={notification.asyncEventId}
            sx={{
                display: 'flex',
                alignItems: 'center',
                padding: '8px 16px',
            }}
        >
            <Grid container sx={{ width: '100%' }}>
                {renderNotificationContent()}
            </Grid>
        </ListItem>
    );
};

export default NotificationRow;
