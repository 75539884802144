import React, { useEffect, useState } from 'react';

import { getAssetMetadataById } from '@actions/apiActions';
import {
    createProductAPI,
    editProductAPI,
    getProductByProductIdAPI,
    validateProductAPI,
} from '@api/metadataController';
import { AssetMetaDataArtistsSection } from '@common/AssetMetaDataEdit/AssetMetaDataArtistsSection';
import { RecordLabelAutocomplete } from '@common/AssetMetaDataEdit/RecordLabelAutocomplete';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormLabel,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import Select from '@mui/material/Select';
import { makeStyles } from '@mui/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextFieldWrapper from '@MuiWrappers/TextFieldWrapper';
import { validationSchema } from '@utils/validations/productValidations';
import { format } from 'date-fns';
import { useForm, Controller, useWatch, FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
    },
    collapsed: {
        transform: 'rotate(180deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expanded: {
        transform: 'rotate(0deg)',
    },
    listItem: {
        borderBottom: '1px solid #DEDEE0',
    },
    errorBanner: {
        backgroundColor: '#FFEBEE',
        marginBottom: '32px',
        '& .MuiAlert-message': {
            textAlign: 'left',
        },
    },
    warningBanner: {
        backgroundColor: '#FFF3E0',
        color: '#EA4B04',
        border: '1px solid #FFB44C',
        marginBottom: '32px',
        '& .MuiAlert-message': {
            textAlign: 'left',
        },
    },
    categorySection: {
        marginTop: '16px',
    },
    submodalSection: {
        marginTop: '32px',
    },
    iconBtn: {
        padding: '0',
        margin: '0',
        '&:hover': {
            background: 'none',
        },
    },
    inputsRow: {
        display: 'flex',
        alignItems: 'end',
        marginBottom: '32px',
        '& > *': {
            marginRight: '32px',
        },
    },
    actionBtn: {
        '&:hover': {
            cursor: 'pointer',
        },
        width: '18px',
        height: '18px',
        color: '#5F5F60',
    },
    mb2: {
        marginBottom: theme.spacing(2),
    },
    mb4: {
        marginBottom: theme.spacing(4),
    },
}));

const defaultFormValues = {
    title: '',
    upc: '',
    catalogueNumber: '',
    releaseDate: null,
    recordLabel: '',
    scppLabelId: null,
    displayArtist: '',
    formatDetails: '',
    compilation: null,
    volumeNumber: '',
    trackNumber: '',
    artists: [],
    pyear: null,
    pname: '',
    cyear: null,
    cname: '',
};

export function NewProductModal({ handleClose, showModal, dataItem, editMode, selectedProduct }) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [recordLabelModalOpen, setRecordLabelModalOpen] = useState(false);

    const methods = useForm({
        defaultValues: defaultFormValues,
        resolver: yupResolver(validationSchema),
        mode: 'onChange',
    });

    const { control, handleSubmit, getValues, formState, setValue, reset, register, trigger } =
        methods;

    const { errors, isValid } = formState;

    // Register the 'artists' field
    useEffect(() => {
        register('artists');
    }, [register]);

    // Use 'useWatch' to observe 'artists'
    const artists = useWatch({
        control,
        name: 'artists',
    });

    const getProductData = async () => {
        const productData = await getProductByProductIdAPI(
            dataItem.assetId,
            selectedProduct.productId,
        );
        reset({
            ...defaultFormValues,
            ...productData.data,
            releaseDate: productData.data.releaseDate
                ? new Date(productData.data.releaseDate)
                : null,
        });
        trigger();
    };

    useEffect(() => {
        if (editMode && selectedProduct) {
            getProductData();
        } else {
            reset(defaultFormValues);
        }
    }, [editMode, selectedProduct]);

    const handleRecordLabelModalClose = () => {
        setRecordLabelModalOpen(false);
    };

    const onSubmit = async (data) => {
        const payload = {
            ...data,
            releaseDate: format(new Date(data.releaseDate), 'yyyy-MM-dd'),
        };

        const response = await validateProductAPI(payload);

        if (response.data.valid) {
            if (editMode) {
                await editProductAPI(dataItem.assetId, selectedProduct.productId, payload);
            } else {
                await createProductAPI(dataItem.assetId, payload);
            }
            dispatch(getAssetMetadataById(dataItem.assetId));
            handleClose();
        }
    };

    return (
        <Dialog onClose={handleClose} open={showModal} fullWidth maxWidth="sm">
            <DialogTitle onClose={handleClose}>
                <Typography variant="subtitle1">Product details</Typography>
            </DialogTitle>
            <DialogContent>
                <FormProvider {...methods}>
                    <Grid spacing={3} container>
                        {/* Title Field */}
                        <Grid size={12}>
                            <Controller
                                name="title"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldWrapper
                                        {...field}
                                        label="Title"
                                        fullWidth
                                        required
                                        error={!!errors.title}
                                        helperText={errors.title ? errors.title.message : ''}
                                    />
                                )}
                            />
                        </Grid>

                        {/* UPC and Catalog Number Fields */}
                        <Grid
                            size={{
                                xs: 12,
                                sm: 4,
                            }}
                        >
                            <Controller
                                name="upc"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldWrapper
                                        {...field}
                                        label="UPC/EAN/Barcode"
                                        fullWidth
                                        required
                                        error={!!errors.upc}
                                        helperText={errors.upc ? errors.upc.message : ''}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid
                            size={{
                                xs: 12,
                                sm: 4,
                            }}
                        >
                            <Controller
                                name="catalogueNumber"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldWrapper
                                        {...field}
                                        label="Catalog number"
                                        fullWidth
                                        required
                                        error={!!errors.catalogueNumber}
                                        helperText={
                                            errors.catalogueNumber
                                                ? errors.catalogueNumber.message
                                                : ''
                                        }
                                    />
                                )}
                            />
                        </Grid>

                        {/* Release Date Field */}
                        <Grid
                            size={{
                                xs: 12,
                                sm: 4,
                            }}
                        >
                            <Controller
                                name="releaseDate"
                                control={control}
                                render={({ field }) => (
                                    <DatePicker
                                        {...field}
                                        label="Release date"
                                        format="dd/MM/yyyy"
                                        value={field.value ? new Date(field.value) : null}
                                        onChange={(date) => {
                                            field.onChange(date ? format(date, 'yyyy-MM-dd') : '');
                                            trigger('releaseDate');
                                        }}
                                        renderInput={(params) => (
                                            <TextFieldWrapper
                                                {...params}
                                                fullWidth
                                                error={!!errors.releaseDate}
                                                helperText={
                                                    errors.releaseDate
                                                        ? errors.releaseDate.message
                                                        : ''
                                                }
                                            />
                                        )}
                                    />
                                )}
                            />
                        </Grid>

                        {/* Record Label and SCPP Label ID Fields */}
                        <Grid
                            size={{
                                xs: 12,
                                sm: 6,
                            }}
                        >
                            <FormControl className={classes.formControl} fullWidth required>
                                <Controller
                                    name="recordLabel"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldWrapper
                                            {...field}
                                            label="Record label"
                                            onClick={(event) =>
                                                setRecordLabelModalOpen(event.currentTarget)
                                            }
                                            readOnly
                                            error={!!errors.recordLabel}
                                            helperText={
                                                errors.recordLabel ? errors.recordLabel.message : ''
                                            }
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>

                        <Grid
                            size={{
                                xs: 12,
                                sm: 6,
                            }}
                        >
                            <Controller
                                name="scppLabelId"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldWrapper
                                        {...field}
                                        label="SCPP label ID"
                                        fullWidth
                                        required
                                        error={!!errors.scppLabelId}
                                        helperText={
                                            errors.scppLabelId ? errors.scppLabelId.message : ''
                                        }
                                    />
                                )}
                            />
                        </Grid>

                        {/* Display Artist Field */}
                        <Grid size={12}>
                            <Typography variant="subtitle1">Artists</Typography>
                            {/* Artists Section */}
                            <AssetMetaDataArtistsSection
                                dataItem={dataItem}
                                tableReloadOnSuccess={editMode ? getProductData : () => {}}
                                productMode
                                artists={artists}
                                setArtists={(artists) => setValue('artists', artists)}
                            />
                            {errors.artists && (
                                <Typography color="error" variant="caption">
                                    {errors.artists.message}
                                </Typography>
                            )}
                        </Grid>

                        {/* Format Details */}
                        <Grid size={12}>
                            <Typography variant="subtitle1" className={classes.mb2}>
                                Format Details
                            </Typography>
                            <FormControl
                                component="fieldset"
                                error={!!errors.formatDetails}
                                required
                            >
                                <FormLabel component="legend">Type</FormLabel>
                                <Controller
                                    name="formatDetails"
                                    control={control}
                                    render={({ field }) => (
                                        <RadioGroup {...field} row className={classes.mb2}>
                                            <FormControlLabel
                                                value="Album"
                                                control={<Radio size="small" color="primary" />}
                                                label="Album"
                                            />
                                            <FormControlLabel
                                                value="EP"
                                                control={<Radio size="small" color="primary" />}
                                                label="EP"
                                            />
                                            <FormControlLabel
                                                value="Single"
                                                control={<Radio size="small" color="primary" />}
                                                label="Single"
                                            />
                                            <FormControlLabel
                                                value="Box-set"
                                                control={<Radio size="small" color="primary" />}
                                                label="Box-set"
                                            />
                                            <FormControlLabel
                                                value="Music video"
                                                control={<Radio size="small" color="primary" />}
                                                label="Music video"
                                            />
                                        </RadioGroup>
                                    )}
                                />
                                {errors.formatDetails && (
                                    <Typography color="error" variant="caption">
                                        {errors.formatDetails.message}
                                    </Typography>
                                )}
                            </FormControl>

                            <FormControl component="fieldset">
                                <FormLabel component="legend" required>
                                    Compilation
                                </FormLabel>
                                <Controller
                                    name="compilation"
                                    control={control}
                                    render={({ field }) => (
                                        <RadioGroup
                                            {...field}
                                            row
                                            className={classes.mb2}
                                            onChange={(e) =>
                                                field.onChange(e.target.value === 'true')
                                            }
                                        >
                                            <FormControlLabel
                                                value={true}
                                                control={<Radio size="small" color="primary" />}
                                                label="Yes"
                                            />
                                            <FormControlLabel
                                                value={false}
                                                control={<Radio size="small" color="primary" />}
                                                label="No"
                                            />
                                        </RadioGroup>
                                    )}
                                />
                            </FormControl>
                        </Grid>

                        {/* Recording Details */}
                        <Grid size={12}>
                            <Typography variant="subtitle1" className={classes.mb2}>
                                Recording Details
                            </Typography>
                            <Grid direction="row" spacing={2} container>
                                {/* Volume Number */}
                                <Grid size={4}>
                                    <FormControl
                                        className={classes.formControl}
                                        fullWidth
                                        required
                                        error={!!errors.volumeNumber}
                                        variant={'standard'}
                                    >
                                        <InputLabel id="volume">Volume</InputLabel>
                                        <Controller
                                            name="volumeNumber"
                                            control={control}
                                            render={({ field }) => (
                                                <Select {...field} labelId="volume">
                                                    {Array.from({ length: 99 }, (_, i) => (
                                                        <MenuItem key={i + 1} value={i + 1}>
                                                            {i + 1}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            )}
                                        />
                                        {errors.volumeNumber && (
                                            <Typography color="error" variant="caption">
                                                {errors.volumeNumber.message}
                                            </Typography>
                                        )}
                                    </FormControl>
                                </Grid>

                                {/* Track Number */}
                                <Grid size={4}>
                                    <FormControl
                                        className={classes.formControl}
                                        fullWidth
                                        required
                                        error={!!errors.trackNumber}
                                        variant={'standard'}
                                    >
                                        <InputLabel id="trackNumber">Track Number</InputLabel>
                                        <Controller
                                            name="trackNumber"
                                            control={control}
                                            render={({ field }) => (
                                                <Select {...field} labelId="trackNumber">
                                                    {Array.from({ length: 99 }, (_, i) => (
                                                        <MenuItem key={i + 1} value={i + 1}>
                                                            {i + 1}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            )}
                                        />
                                        {errors.trackNumber && (
                                            <Typography color="error" variant="caption">
                                                {errors.trackNumber.message}
                                            </Typography>
                                        )}
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Copyrights */}
                        <Grid size={12}>
                            <Typography variant="subtitle1">Copyrights</Typography>
                            <Grid spacing={2} container>
                                {/* PYear and PName */}
                                <Grid size={6}>
                                    <Controller
                                        name="pyear"
                                        control={control}
                                        render={({ field }) => (
                                            <DatePicker
                                                {...field}
                                                views={['year']}
                                                label="Product copyright year (PYear)"
                                                slotProps={{
                                                    textField: {
                                                        variant: 'standard',
                                                        fullWidth: true,
                                                        required: true,
                                                        error: !!errors.pyear,
                                                        helperText: errors.pyear
                                                            ? errors.pyear.message
                                                            : '',
                                                    },
                                                }}
                                                value={field.value ? new Date(field.value) : null}
                                                onChange={(date) => {
                                                    field.onChange(
                                                        date ? format(date, 'yyyy') : '',
                                                    );
                                                    trigger('cyear');
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid size={6}>
                                    <Controller
                                        name="pname"
                                        control={control}
                                        render={({ field }) => (
                                            <TextFieldWrapper
                                                {...field}
                                                label="Product copyright text (PName)"
                                                fullWidth
                                                required
                                                error={!!errors.pname}
                                                helperText={
                                                    errors.pname ? errors.pname.message : ''
                                                }
                                            />
                                        )}
                                    />
                                </Grid>

                                {/* CYear and CName */}
                                <Grid size={6}>
                                    <Controller
                                        name="cyear"
                                        control={control}
                                        render={({ field }) => (
                                            <DatePicker
                                                {...field}
                                                views={['year']}
                                                label="Product copyright year (CYear)"
                                                slotProps={{
                                                    textField: {
                                                        variant: 'standard',
                                                        fullWidth: true,
                                                        error: !!errors.cyear,
                                                        helperText: errors.cyear
                                                            ? errors.cyear.message
                                                            : '',
                                                    },
                                                }}
                                                value={new Date(field.value)}
                                                onChange={(date) => {
                                                    field.onChange(
                                                        date ? format(date, 'yyyy') : '',
                                                    );
                                                    trigger('cyear');
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid size={6}>
                                    <Controller
                                        name="cname"
                                        control={control}
                                        render={({ field }) => (
                                            <TextFieldWrapper
                                                {...field}
                                                label="Product copyright text (CName)"
                                                fullWidth
                                                error={!!errors.cname}
                                                helperText={
                                                    errors.cname ? errors.cname.message : ''
                                                }
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </FormProvider>
            </DialogContent>
            <DialogActions>
                <Button variant="light" onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    onClick={handleSubmit(onSubmit)}
                    color="primary"
                    disabled={!isValid}
                >
                    Save
                </Button>
            </DialogActions>
            {/* Record Label Modal */}
            {recordLabelModalOpen && (
                <RecordLabelAutocomplete
                    open={recordLabelModalOpen}
                    onClose={handleRecordLabelModalClose}
                    anchorEl={recordLabelModalOpen}
                    setRecordLabel={(recordLabel) => {
                        setValue('recordLabel', recordLabel);
                    }}
                    setScppLabelId={(scppLabelId) => setValue('scppLabelId', scppLabelId)}
                />
            )}
        </Dialog>
    );
}
